@charset "UTF-8";

////
/// @module slider: 跑马灯
/// @tag Slider
/// @category component
/// @family data-display
/// @varPrefix $slick-
/// @classPrefix {prefix}-slick
/// @order {"size/dots":10,"size/arrow":11,"size/track":12,"statement/normal":10,"statement/normal/dot":100,"statement/normal/arrow":101,"statement/hover":11,"statement/hover/dot":110,"statement/hover/arrow":111,"statement/selected":12,"statement/selected/dot":120,"statement/disabled":13,"statement/disabled/arrow":130}
////

// slider variables
// --------------------------------------------------

$slick-prefix: '.' + $css-prefix + 'slick';

// size
// ---------

/// offset bottom
/// @namespace size/dots
$slick-dots-position-bottom: $S12 !default;

/// offset right
/// @namespace size/dots
$slick-dots-position-right: $S20 !default;

/// dot width
/// @namespace size/dots
$slick-dots-width: $S8 !default;

/// dot height
/// @namespace size/dots
$slick-dots-height: $S8 !default;

/// dot margin (l, r)
/// @namespace size/dots
$slick-dots-margin-lr: $S4 !default;

/// dot margin (t, b)
/// @namespace size/dots
$slick-dots-margin-tb: $S0 !default;

/// width
/// @namespace size/arrow
$slick-arrow-width-m: $S28 !default;

/// width
/// @namespace size/arrow
$slick-arrow-width-l: $S40 !default;

/// height
/// @namespace size/arrow
$slick-arrow-height-m: $S40 !default;

/// height
/// @namespace size/arrow
$slick-arrow-height-l: $S80 !default;

/// width
/// @namespace size/arrow
$slick-ver-arrow-width-m: $S40 !default;

/// width
/// @namespace size/arrow
$slick-ver-arrow-width-l: $S80 !default;

/// height
/// @namespace size/arrow
$slick-ver-arrow-height-m: $S28 !default;

/// height
/// @namespace size/arrow
$slick-ver-arrow-height-l: $S40 !default;

/// icon
/// @namespace size/arrow
$slick-arrow-icon-m: $icon-m !default;

/// icon
/// @namespace size/arrow
$slick-arrow-icon-l: $icon-xl !default;

/// arrow positon (l,r)
/// @namespace size/arrow
$slick-arrow-position-lr-m: $S4 !default;

/// arrow positon (l,r)
/// @namespace size/arrow
$slick-arrow-position-lr-l: $S8 !default;

/// arrow position (t,b)
/// @namespace size/arrow
$slick-ver-arrow-position-tb-m: $S4 !default;

/// arrow position (t,b)
/// @namespace size/arrow
$slick-ver-arrow-position-tb-l: $S16 !default;

/// padding (l,r)
/// @namespace size/track
$slick-track-padding-lr: $S24 !default;

/// padding (t,b)
/// @namespace size/track
$slick-ver-track-padding-tb: $S24 !default;

// statement
// ---------

/// background
/// @namespace statement/normal/dot
$slick-dots-background-color-normal: $shadow-color !default;

/// background
/// @namespace statement/hover/dot
$slick-dots-background-color-hover: $shadow-color !default;

/// background
/// @namespace statement/selected/dot
$slick-dots-background-color-selected: $primary-brand !default;

/// background opacity
/// @type alpha
/// @namespace statement/normal/dot
$slick-dots-background-opacity-normal: .32 !default;

/// background opacity
/// @type alpha
/// @namespace statement/hover/dot
$slick-dots-background-opacity-hover: .32 !default;

/// background opacity
/// @type alpha
/// @namespace statement/selected/dot
$slick-dots-background-opacity-selected: 1 !default;

/// border width
/// @type width
/// @namespace size/dots
$slick-dots-border-width: 0 !default;

/// border radius
/// @namespace size/dots
$slick-dots-border-radius: $shape-circular !default;

/// border
/// @namespace statement/normal/dot
$slick-dots-border-color-normal: $snow !default;

/// border
/// @namespace statement/hover/dot
$slick-dots-border-color-hover: $snow !default;

/// border
/// @namespace statement/selected/dot
$slick-dots-border-color-selected: $snow !default;

/// border opacity
/// @type alpha
/// @namespace statement/normal/dot
$slick-dots-border-opacity-normal: 1 !default;

/// border opacity
/// @type alpha
/// @namespace statement/hover/dot
$slick-dots-border-opacity-hover: 1 !default;

/// border opacity
/// @type alpha
/// @namespace statement/selected/dot
$slick-dots-border-opacity-selected: 1 !default;

/// icon
/// @namespace statement/normal/arrow
$slick-arrow-icon-color-normal: $snow !default;

/// icon
/// @namespace statement/normal/arrow
$slick-arrow-icon-color-normal-outer: $gray-07 !default;

/// icon
/// @namespace statement/hover/arrow
$slick-arrow-icon-color-hover: $snow !default;

/// icon
/// @namespace statement/hover/arrow
$slick-arrow-icon-color-hover-outer: $gray-08 !default;

/// icon
/// @namespace statement/disabled/arrow
$slick-arrow-icon-color-disabled: $gray-05 !default;

/// icon
/// @namespace statement/disabled/arrow
$slick-arrow-icon-color-disabled-outer: $gray-05 !default;

/// background
/// @namespace statement/normal/arrow
$slick-arrow-bg-color-normal: $shadow-color !default;

/// background
/// @namespace statement/hover/arrow
$slick-arrow-bg-color-hover: $shadow-color !default;

/// background
/// @namespace statement/disabled/arrow
$slick-arrow-bg-color-disabled: $gray-01 !default;

/// background opacity
/// @type alpha
/// @namespace statement/normal/arrow
$slick-arrow-bg-opacity-normal: .2 !default;

/// background opacity
/// @type alpha
/// @namespace statement/hover/arrow
$slick-arrow-bg-opacity-hover: .4 !default;

/// background opacity
/// @type alpha
/// @namespace statement/disabled/arrow
$slick-arrow-bg-opacity-disabled: .5 !default;

/// background
/// @namespace statement/normal/arrow
$slick-arrow-bg-color-normal-outer: transparent !default;

/// background
/// @namespace statement/hover/arrow
$slick-arrow-bg-color-hover-outer: transparent !default;

/// background
/// @namespace statement/disabled/arrow
$slick-arrow-bg-color-disabled-outer: transparent !default;

/// background opacity
/// @type alpha
/// @namespace statement/normal/arrow
$slick-arrow-bg-opacity-normal-outer: .32 !default;

/// background opacity
/// @type alpha
/// @namespace statement/hover/arrow
$slick-arrow-bg-opacity-hover-outer: .32 !default;

/// background opacity
/// @type alpha
/// @namespace statement/disabled/arrow
$slick-arrow-bg-opacity-disabled-outer: .32 !default;

/// radius
/// @namespace size/arrow
$slick-arrow-corner-radius-outer: $S0 !default;
