@import "../core/index-noreset.scss";
@import "scss/variable";

#{$cascader-select-prefix} {
  @include box-sizing;

  &-dropdown {
    @include box-sizing;

    border: $popup-local-border-width $popup-local-border-style $popup-local-border-color;
    border-radius: $popup-local-corner;
    box-shadow: $popup-local-shadow;

    .#{$css-prefix}cascader {
      display: block;
      border: none;
      box-shadow: none;
    }
  }

  &-not-found {
    padding: 0;
    border: none;
    box-shadow: none;
    overflow: auto;
    color: $gray-06;

    .#{$css-prefix}menu-item:hover {
      color: $gray-06;
      /* TODO */
      background: $snow;
      cursor: default;
    }
  }
}
