@charset "UTF-8";

////
/// @module select: 选择器
/// @tag Select
/// @category component
/// @family data-entry
/// @varPrefix $select-
/// @classPrefix {prefix}-select
/// @order {"statement/normal":10,"statement/normal/text":100,"statement/normal/hint":101,"statement/normal/label":102,"statement/normal/icon":103,"size/bounding":10,"size/text":11}
////

// select variables
// --------------------------------------------------

$select-prefix: '.#{$css-prefix}select';
$tag-prefix: '.#{$css-prefix}tag';

/// color
/// @namespace statement/normal/text
$select-color: $gray-08 !default;
/// color
/// @namespace statement/normal/hint
$select-hint-color: $gray-06 !default;

/// color
/// @namespace statement/normal/highlight
$select-highlight-color: $primary-brand !default;

// font
/// size
/// @namespace size/highlight
$select-highlight-font: $font-size-body !default;

// medium

/// line-height
/// @namespace size/bounding
$select-m-lineheight: $S20 !default;

/// line-height
/// @namespace size/bounding
$select-s-lineheight: 14px !default;

/// line-height
/// @namespace size/bounding
$select-xs-lineheight: 12px !default;

$select-tag-spacing-lr: $S4;
$select-tag-spacing-tb: 3px;
$select-tag-padding-lr: $S4;
$select-menu-item-height: $S20;
$select-menu-icon-color: $primary-brand;
