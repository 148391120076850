@charset "UTF-8";

////
/// @module switch: 开关
/// @tag Switch
/// @category component
/// @family data-entry
/// @varPrefix $switch-
/// @classPrefix {prefix}-switch
/// @order {"size/bounding":10,"size/handle":11,"size/content":12,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/handle":101,"statement/normal/content":102,"statement/hover":11,"statement/hover/bounding":110,"statement/hover/handle":111,"statement/hover/content":112,"statement/disabled":12,"statement/disabled/bounding":120,"statement/disabled/handle":121,"statement/disabled/content":122}
////
// switch variables
// --------------------------------------------------

// Medium
// ----------------------------------------
/// width
/// @namespace size/bounding
$switch-size-m-width: $S36 !default;
/// radius
/// @namespace size/bounding
$switch-size-m-radius-container: $S16 !default;

/// height
/// @namespace size/handle
$switch-size-m-trigger: $S16 !default;
/// radius
/// @namespace size/handle
$switch-size-m-radius-trigger: 14px !default;

// Small
// ----------------------------------------
/// width
/// @namespace size/bounding
$switch-size-s-width: $S28 !default;

/// radius
/// @namespace size/boundingborder-width-container
$switch-size-s-radius-container: $S12 !default;

/// height
/// @namespace size/handle
$switch-size-s-trigger: $S12 !default;
/// radius
/// @namespace size/handle
$switch-size-s-radius-trigger: 30px !default;

/// border
/// @namespace size/bounding
$switch-border-width-container: 2px !default;

/// on(l)
/// @namespace size/content
$switch-text-on-left: $S8 !default;

/// on-small(l)
/// @namespace size/content
$switch-size-s-text-on-left: $S4 !default;

/// off(r)
/// @namespace size/content
$switch-text-off-right: $S8 !default;

/// off-small(r)
/// @namespace size/content
$switch-size-s-text-off-right: $S4 !default;

/// border
/// @namespace size/handle
$switch-border-width-trigger: 0 !default;
// level - on

/// background
/// @level on
/// @namespace statement/normal/bounding
$switch-normal-on-bg-color: $primary-brand !default;
/// background
/// @level on
/// @state hover
/// @namespace statement/hover/bounding
$switch-hover-on-bg-color: $hover-brand !default;
/// background
/// @level on
/// @state active
/// @namespace statement/active/bounding
$switch-active-on-bg-color: $click-brand !default;
/// background
/// @level on
/// @state disabled
/// @namespace statement/disabled/bounding
$switch-disabled-on-bg-color: $bright-brand !default;

/// background
/// @level on
/// @namespace statement/normal/handle
$switch-normal-on-trigger-bg-color: $snow !default;
/// background
/// @level on
/// @state hover
/// @namespace statement/hover/handle
$switch-hover-on-trigger-bg-color: $snow !default;

/// background
/// @level on
/// @state disabled
/// @namespace statement/disabled/handle
$switch-disabled-on-trigger-bg-color: $snow !default;

/// text
/// @level on
/// @namespace statement/normal/content
$switch-normal-on-color-font: $snow !default;

/// text
/// @level on
/// @state disabled
/// @namespace statement/disabled/content
$switch-disabled-on-color-font: $gray-05 !default;

/// border color
/// @level on
/// @state disabled
/// @namespace statement/disabled/bounding
$switch-disabled-on-border-color: $gray-04 !default;

/// border color
/// @state disabled
/// @namespace statement/normal/handle
$switch-handle-on-border-color: transparent !default;

/// shadow
/// @level on
/// @namespace statement/normal/bounding
$switch-on-shadow: none !default;

// level - off

/// background
/// @level off
/// @namespace statement/normal/bounding
$switch-normal-off-bg-color: $gray-05 !default;
/// background
/// @level off
/// @state hover
/// @namespace statement/hover/bounding
$switch-hover-off-bg-color: $gray-06 !default;
/// background
/// @level off
/// @state active
/// @namespace statement/active/bounding
$switch-active-off-bg-color: $gray-07 !default;
/// background
/// @level off
/// @state disabled
/// @namespace statement/disabled/bounding
$switch-disabled-off-bg-color: $gray-03 !default;

/// background
/// @level off
/// @namespace statement/normal/handle
$switch-normal-off-trigger-bg-color: $snow !default;
/// background
/// @level off
/// @state hover
/// @namespace statement/hover/handle
$switch-hover-off-trigger-bg-color: $snow !default;
/// background
/// @level off
/// @state disabled
/// @namespace statement/disabled/handle
$switch-disabled-off-trigger-bg-color: $snow !default;
/// border color
/// @state disabled
/// @namespace statement/disabled/handle
$switch-handle-disabled-border-color: $gray-04 !default;
/// text
/// @level on
/// @namespace statement/normal/content
$switch-normal-off-color-font: $gray-06 !default;

/// text
/// @level on
/// @state disabled
/// @namespace statement/hover/content
$switch-disabled-off-color-font: $gray-04 !default;
/// border
/// @state disabled
/// @namespace statement/normal/handle
$switch-handle-off-border-color: transparent !default;

/// border
/// @level off
/// @namespace statement/normal/bounding
$switch-normal-off-border-color: transparent !default;
/// border
/// @level off
/// @state hover
/// @namespace statement/hover/bounding
$switch-hover-off-border-color: transparent !default;
/// shadow
/// @level off
/// @namespace statement/normal/bounding
$switch-off-shadow: none !default;

/// rtl shadow
$switch-rtl-on-shadow: none !default;
