$placeholder-prefix: ".#{$css-prefix}placeholder";
$placeholder-normal-image-size: 72px !default;
$placeholder-mini-image-size: $S48 !default;
$placeholder-normal-title-color: $gray-08 !default;
$placeholder-normal-title-font-size: $font-size-headline !default;
$placeholder-normal-title-line-height: $line-height-headline !default;
$placeholder-normal-title-font-weight: $font-weight-regular !default;
$placeholder-normal-description-color: $gray-05 !default;
$placeholder-normal-description-font-size: $font-size-body !default;
$placeholder-normal-description-line-height: $line-height-body !default;
$placeholder-normal-description-font-weight: $font-weight-regular !default;
$placeholder-mini-title-color: $gray-08 !default;
$placeholder-mini-title-font-size: $font-size-caption !default;
$placeholder-mini-title-line-height: $line-height-caption !default;
$placeholder-mini-title-font-weight: $font-weight-regular !default;
