@import './scss/variables';

#{$placeholder-prefix} {
  &-image, &-title, &-description, &-children {
    text-align: center;
  }
}

#{$placeholder-prefix} {
  &-normal {
    #{$placeholder-prefix}-image {
      width: $placeholder-normal-image-size;
      height: $placeholder-normal-image-size;
      margin: 0 auto;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    #{$placeholder-prefix}-title {
      color: $placeholder-normal-title-color;
      font-size: $placeholder-normal-title-font-size;
      line-height: $placeholder-normal-title-line-height;
      font-weight: $placeholder-normal-title-font-weight;
      margin-top: $S24;
    }
    #{$placeholder-prefix}-description {
      color: $placeholder-normal-description-color;
      font-size: $placeholder-normal-description-font-size;
      line-height: $placeholder-normal-description-line-height;
      font-weight: $placeholder-normal-description-font-weight;
      margin-top: $S12;
    }
    #{$placeholder-prefix}-children {
      margin: $S32 auto 0;
    }
  }

  &-mini {
    #{$placeholder-prefix}-image {
      width: $placeholder-mini-image-size;
      height: $placeholder-mini-image-size;
      margin: 0 auto;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    #{$placeholder-prefix}-title {
      color: $placeholder-mini-title-color;
      font-size: $placeholder-mini-title-font-size;
      line-height: $placeholder-mini-title-line-height;
      font-weight: $placeholder-mini-title-font-weight;
      margin-top: $S32;
    }
    #{$placeholder-prefix}-children {
      margin: $S16 auto 0;
    }
  }
}

