//
// date-picker 样式
//
#{$range-picker-prefix} {
  display: inline-block;
  width: 264px;

  &-input {
    width: 100%;
  }

  &-trigger {
    // Extend next-input
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg-color;

    // Extend next-input
    &:hover {
      border-color: $input-hover-border-color;
      background-color: $input-hover-bg-color;
    }

    // Extend next-input
    &.#{$css-prefix}error {
      border-color: $input-feedback-error-border-color;
    }

    .#{$css-prefix}input.#{$css-prefix}medium {
      height: 34px;
    }

    &-separator {
      @extend %input-separator;
    }

    &-input#{$date-picker-input-prefix} {
      height: auto;
      width: calc((100% - #{$date-picker-input-separator-width}) / 2);
    }
  }

  &.#{$css-prefix}disabled {
    #{$range-picker-prefix}-trigger {
      cursor: not-allowed;
      @include input-disabled();
    }
  }

  &.#{$css-prefix}medium {
    @include range-picker-input-corner($form-element-medium-corner);
  }

  &.#{$css-prefix}small {
    @include range-picker-input-corner($form-element-small-corner);
  }

  &-body {
    width: 600px;
  }

  &-panel {
    &-input-separator {
      @extend %input-separator;
    }

    &-input-start-date#{$date-picker-input-prefix},
    &-input-end-date#{$date-picker-input-prefix} {
      width: calc((100% - #{$date-picker-input-separator-width}) / 2);
    }
  }
}

#{$range-picker-prefix}-body#{$range-picker-prefix}-body-show-time {
  #{$range-picker-prefix}-panel {
    &-input-start-date,
    &-input-end-date,
    &-input-start-time,
    &-input-end-time {
      width: calc((100% - #{$date-picker-input-separator-width} - #{$S16}) / 4);
    }

    &-input-start-date {
      margin-right: $S8;
    }

    &-input-end-time {
      margin-left: $S8;
    }

    // time-panel
    &-time {
      @include clearfix;
    }

    &-time-start,
    &-time-end {
      width: 50%;
      float: left;
    }

    &-time-start {
      // Extend time-picker border
      border-right: 1px solid $date-picker-panel-time-panel-separator-color;
    }

    &-time-end {
      // Extend time-picker border
      border-left: 1px solid $date-picker-panel-time-panel-separator-color;
    }
  }
}
