@import "../core/index-noreset.scss";

@import "scss/variable";
@import "scss/mixin";

#{$tree-prefix} {
  @include box-sizing;

  &, &-child-tree {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-node {
    white-space: nowrap;
  }

  &-node-inner {
    font-size: 0;
    outline: none;
  }

  &-node-label-wrapper {
    display: inline-block;
    margin: 0 $tree-node-title-margin;
    vertical-align: middle;
  }

  &-node-label {
    height: $tree-node-title-height;
    line-height: $tree-node-title-height;
    // padding: 0 $tree-node-title-padding;
    border-radius: $tree-node-title-border-radius;
    font-size: $tree-node-title-font-size;
    color: $gray-08;

    i {
      color: $gray-06;
    }
  }

  &-node-input.#{$css-prefix}input {
    margin: 0 $tree-node-title-margin;
  }

  &-switcher {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    margin-left: 13px;
  }

  &-switcher.#{$css-prefix}noline, &-switcher.#{$css-prefix}line {
    width: $tree-switch-arrow-size;
    height: $tree-switch-arrow-size;
    line-height: $tree-switch-arrow-size;
    // cursor: pointer;

    #{$tree-prefix}-switcher-icon {
      @include icon-size($tree-switch-arrow-size);
      transition: transform cubic-bezier(.23, 1, .32, 1) 300ms;
      color: $tree-switch-arrow-color;
    }

    &.#{$css-prefix}close #{$tree-prefix}-switcher-icon {
      @include icon-size(
        $size: $tree-switch-arrow-size,
                $transform: rotate(-90deg)
            );
    }

    &:not(.#{$css-prefix}disabled):hover #{$tree-prefix}-switcher-icon {
      // color: $tree-switch-hover-arrow-color;
    }

    &.#{$css-prefix}disabled {
      cursor: not-allowed;

      #{$tree-prefix}-switcher-icon {
        color: $tree-node-disabled-color;
      }
    }
  }

  &-switcher.#{$css-prefix}noop-noline {
    width: $tree-switch-arrow-size;
    height: $tree-switch-arrow-size;
  }

  // &-switcher.#{$css-prefix}line {
  //   width: $tree-switch-arrow-size;
  //   height: $tree-switch-arrow-size;
  //   line-height: $tree-switch-arrow-size;

  //   #{$tree-prefix}-switcher-icon {
  //     @include icon-size($tree-switch-icon-size, ($tree-switch-size - $tree-switch-icon-size - $tree-switch-border-width * 2) / 2);
  //     color: $tree-switch-icon-color;
  //   }

  //   &.#{$css-prefix}close #{$tree-prefix}-switcher-icon {
  //     @include icon-size(
  //       $size: $tree-switch-arrow-size,
  //               $transform: rotate(-90deg)
  //           );
  //   }

  //   &.#{$css-prefix}disabled {
  //     border-color: $gray-04;
  //     background-color: $snow;
  //     cursor: not-allowed;

  //     #{$tree-prefix}-switcher-icon {
  //       color: $tree-node-disabled-color;
  //     }
  //   }
  // }

  &-switcher.#{$css-prefix}noop-line {
    width: $tree-switch-size;
    height: $tree-switch-size;
  }

  &-switcher.#{$css-prefix}noop-line-noroot {
    // height: 0;
    // border-left: $tree-switch-border-width solid $tree-line-color;
    // border-bottom: $tree-line;

    #{$tree-prefix}-right-angle {
      bottom: -$tree-line-width;
    }

    width: 6px;
    height: 6px;
    background-color: $gray-04;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 5px 0 15px;
  }

  &-switcher.#{$css-prefix}loading {
    min-height: auto;
    &.#{$css-prefix}loading-noline {
      width: $tree-switch-arrow-size;
      height: $tree-switch-arrow-size;
      line-height: $tree-switch-arrow-size;
    }

    &.#{$css-prefix}loading-line {
      width: $tree-switch-size;
      height: $tree-switch-size;
      line-height: $tree-switch-size - $tree-switch-border-width * 2;
      border: $tree-switch-border-width solid transparent;
    }

    #{$tree-prefix}-switcher-icon {
      @include icon-size($tree-switch-arrow-size);
      color: $primary-brand;
    }
  }

  &-right-angle {
    position: absolute;
    bottom: ($tree-switch-size - $tree-switch-border-width * 2 - $tree-line-width) / 2;
    left: - ($tree-child-indent-right + $tree-line-width + $tree-switch-border-width);
    display: block;
    width: $tree-child-indent-right + $tree-line-width;
    height: $tree-node-title-height + $tree-node-padding - $tree-line-width * 2;
    border-left: $tree-line;
    border-bottom: $tree-line;
  }

  &.#{$css-prefix}label-block {
    #{$tree-prefix}-node-inner {
      display: flex;
      align-items: center;
      outline: none;
    }

    #{$tree-prefix}-node-label-wrapper {
      flex: 1 1 auto;
    }
  }


  &.#{$css-prefix}node-indent {
    #{$tree-prefix}-node #{$tree-prefix}-node {
      margin-left: $tree-child-indent;
    }

    #{$tree-prefix}-node-inner {
      border-radius: $S4;
      cursor: pointer;
      margin-bottom: $S4;
      transition: color cubic-bezier(.23, 1, .32, 1) 300ms, background-color cubic-bezier(.23, 1, .32, 1) 300ms;
      padding-top: $tree-node-padding / 2;
      padding-bottom: $tree-node-padding / 2;
      &:not(.#{$css-prefix}disabled):hover {
        background: $gray-01;
      }
      &:not(.#{$css-prefix}disabled):active {
        background: $highlight-brand;
      }
    }

    #{$tree-prefix}-node-label-wrapper {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
    }

    #{$tree-prefix}-node-label {
      transition: color cubic-bezier(.23, 1, .32, 1) 300ms, background-color cubic-bezier(.23, 1, .32, 1) 300ms;
      cursor: pointer;
      // @include tree-node-state($tree-node-normal-color, $tree-node-normal-background);
    }

    #{$tree-prefix}-node-label-selectable {
      cursor: pointer;
    }

    // #{$tree-prefix}-node-label:hover {
    //   @include tree-node-state($tree-node-hover-color, $tree-node-hover-bg-color);
    // }

    #{$tree-prefix}-node-inner.#{$css-prefix}selected #{$tree-prefix}-node-label {
      // @include tree-node-state($tree-node-selected-color, $tree-node-selected-background);
    }

    #{$tree-prefix}-node-inner.#{$css-prefix}disabled #{$tree-prefix}-node-label,
    #{$tree-prefix}-node-inner.#{$css-prefix}disabled #{$tree-prefix}-node-label:hover {
      color:$tree-node-disabled-color;
      cursor: not-allowed;
    }

    #{$tree-prefix}-node-inner.#{$css-prefix}drag-over #{$tree-prefix}-node-label {
      background-color: $primary-brand;
      color: $snow;
      opacity: .8;
    }

    #{$tree-prefix}-node-inner.#{$css-prefix}drag-over-gap-top #{$tree-prefix}-node-label-wrapper {
      border-top-color: $primary-brand;
    }

    #{$tree-prefix}-node-inner.#{$css-prefix}drag-over-gap-bottom #{$tree-prefix}-node-label-wrapper {
      border-bottom-color: $primary-brand;
    }
  }

  &.#{$css-prefix}node-block #{$tree-prefix}-node-inner {
    padding-top: $tree-node-padding;
    padding-bottom: $tree-node-padding;
    transition: color cubic-bezier(.23, 1, .32, 1) 300ms, background-color cubic-bezier(.23, 1, .32, 1) 300ms;
    cursor: pointer;
    @include tree-node-state($tree-node-normal-color, $tree-node-normal-background);

    &:hover,
    &:focus {
      @include tree-node-state($tree-node-hover-color, $tree-node-hover-bg-color);
    }

    // &.#{$css-prefix}selected {
    //   @include tree-node-state($tree-node-selected-color, $tree-node-selected-background);
    // }

    &.#{$css-prefix}disabled,
    &.#{$css-prefix}disabled:hover {
      color: $tree-node-disabled-color;
      cursor: not-allowed;
    }
  }

  &.#{$css-prefix}show-line &-node &-node {
    margin-left: $tree-child-indent-left;
    // border-left: $tree-line;
    position: relative;
    &:before{
      content: '';
      width: 2px;
      height: 100%;
      background-color: $tree-line-color;
      position: absolute;
    }
    &:not(:last-child):before{
      height: calc(100% + 4px);
    }
  }

  &-node.#{$css-prefix}filtered > #{$tree-prefix}-node-inner #{$tree-prefix}-node-label {
    color: $primary-brand;

    &:hover {
      color: $primary-brand;
    }
  }
  .next-tree-node-inner.next-selected {
    background-color: $highlight-brand;
  }
}

@import './rtl.scss';
