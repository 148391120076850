@charset "UTF-8";

// message mixins
// --------------------------------------------------

@mixin message-size(
  $borderWidth,
  $padding,
  $titlePadding,
  $titleFontSize,
  $titleLineHeiht,
  $contentPadding,
  $contentMarginTop,
  $contentFontSize,
  $contentLineHeight,
  $iconSize
) {
  display: flex;
  flex-direction: row;
  border-width: $borderWidth;
  padding: $padding;

  .#{$css-prefix}message-symbol {
    @include icon-size($iconSize);
    line-height: $iconSize;
    margin-right: $S8;
  }
  .#{$css-prefix}message-content-wrapper {
    flex: 1 1 auto;
    min-width: 0%;
  }
  .#{$css-prefix}message-title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .#{$css-prefix}message-title {
    flex-shrink: 1;
    word-wrap: normal;

    font-size: $titleFontSize;
    line-height: $titleLineHeiht;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .#{$css-prefix}message-handler {
    flex-shrink: 0;
    max-width: 100%;
    margin: 0 $S8 0 $S8;
  }
  .#{$css-prefix}message-content {
    margin-top: $contentMarginTop;
    font-size: $contentFontSize;
    line-height: $contentLineHeight;
  }
}

@mixin message-shape(
  $shape,
  $titleColor,
  $contentColor,
  $iconColor,
  $bgColor,
  $borderColor,
  $shadow,
  $handlerColor: transparent,
  $handlerHoverColor: transparent
) {
  background-color: $bgColor;
  border-color: $borderColor;
  box-shadow: $shadow;

  .#{$css-prefix}message-close {
    color: $iconColor;
  }
  .#{$css-prefix}message-title {
    color: $titleColor;
  }
  .#{$css-prefix}message-handler {
    color: $handlerColor;
    &:hover {
      color: $handlerHoverColor;
    }
  }
  .#{$css-prefix}message-content {
    color: $contentColor;
  }
  .#{$css-prefix}message-symbol {
    color: $iconColor;
  }

  @if ($shape == 'inline') {
    border-style: $message-border-style;
  }

  @if ($shape == 'toast') {
    border-style: $message-border-style-toast;
  }
}

@mixin vertial-align-middle($fontSize, $iconSize, $className) {
  @if ($fontSize > $iconSize) {
    .#{$css-prefix}message-symbol {
      line-height: $fontSize;
    }
  }

  @if ($fontSize < $iconSize) {
    .#{$css-prefix}message-#{$className} {
      line-height: $iconSize;
    }
  }
}
