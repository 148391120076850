@charset "UTF-8";

// tag animation
// --------------------------------------------------

@keyframes fadeInRightForTag {
    0% {
        opacity: 0;
        transform: rotate(45deg) translateX(20px);
    }
    100% {
        opacity: 1;
        transform: rotate(45deg) translateX((0));
    }
}
