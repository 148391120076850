@charset "UTF-8";

////
/// @module number-picker: 数字输入框
/// @tag NumberPicker
/// @category component
/// @family data-entry
/// @varPrefix $number-picker-
/// @classPrefix {prefix}-number-picker
/// @order {"statement/normal":10,"statement/normal/input":100,"statement/normal/button":101,"statement/hover":11,"statement/hover/button":110,"statement/disabled":12,"statement/disabled/button":120,"size/bounding":10,"size/text":11,"size/input":12,"size/button":13}
////

$number-picker-prefix: '.#{$css-prefix}number-picker';

// Normal
// --------------------------------------------------

/// width
/// @namespace size/button
$number-picker-normal-m-button-width: $S20 !default;

/// icon
/// @namespace size/button
$number-picker-normal-m-button-icon-size: $icon-xs !default;

// inline
// --------------------------------------------------

/// icon
/// @namespace size/button
$number-picker-inline-m-button-icon-size: $icon-xs !default;
/// margin
/// @type number
/// @namespace size/button
$number-picker-inline-m-button-margin: 2px !default;
