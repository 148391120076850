////
/// @module button: 按钮
/// @tag Button
/// @category component
/// @family general
/// @varPrefix $btn-
/// @classPrefix {prefix}-btn
/// @order {"statement/normal":10,"statement/hover":11,"statement/disabled":12,"statement/loading":13,"size/bounding":10,"size/text":11,"size/icon":12,"size/normal":13}
////

$btn-prefix: '.' + $css-prefix + 'btn';

$iconbtn-prefix: '.' + $css-prefix + 'iconbtn';

$btn-icon-prefix: '.' + $css-prefix + 'icon';

// Global
// ----------------------------------------

/// shadow
/// @namespace statement/normal
$btn-shadow: none !default;

/// shadow
/// @namespace statement/hover
$btn-shadow-hover: none !default;

// Small
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-size-xs-height: $S28 !default;

/// padding
/// @namespace size/bounding
$btn-size-xs-padding: $S8 !default;

/// border width
/// @namespace size/bounding
$btn-size-xs-border-width: 1px !default;

/// size
/// @namespace size/text
$btn-size-xs-font: $font-size-body !default;

/// size
/// @namespace size/icon
$btn-size-xs-icon-size: $icon-xs !default;

/// margin
/// @namespace size/icon
$btn-size-xs-icon-margin: $S4 !default;

/// split icon
/// @namespace size/icon
$btn-size-xs-icon-split-size: $icon-xs !default;

/// corner
/// @namespace size/normal
$btn-size-xs-corner: $shape-radius !default;

// Medium
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-size-s-height: $S32 !default;

/// padding
/// @namespace size/bounding
$btn-size-s-padding: 10px !default;

/// border width
/// @namespace size/bounding
$btn-size-s-border-width: 1px !default;

/// size
/// @namespace size/text
$btn-size-s-font: $font-size-body !default;

/// size
/// @namespace size/icon
$btn-size-s-icon-size: $icon-xs !default;

/// margin
/// @namespace size/icon
$btn-size-s-icon-margin: $S4 !default;

/// split icon
/// @namespace size/icon
$btn-size-s-icon-split-size: $icon-xs !default;

/// corner
/// @namespace size/normal
$btn-size-s-corner: $shape-radius !default;

// Large
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-size-m-height: $S36 !default;

/// padding
/// @namespace size/bounding
$btn-size-m-padding: $S12 !default;

/// border width
/// @namespace size/bounding
$btn-size-m-border-width: 1px !default;

/// size
/// @namespace size/text
$btn-size-m-font: $font-size-body !default;

/// size
/// @namespace size/icon
$btn-size-m-icon-size: $icon-s !default;

/// margin
/// @namespace size/icon
$btn-size-m-icon-margin: $S4 !default;

/// split icon
/// @namespace size/icon
$btn-size-m-icon-split-size: $icon-s !default;

/// corner
/// @namespace size/normal
$btn-size-m-corner: $shape-radius !default;

// Pure
// ----------------------------------------

/// text
/// @namespace statement/disabled
$btn-pure-color-disabled: $snow !default;
/// background
/// @namespace statement/disabled
$btn-pure-bg-disabled: $gray-05 !default;
/// border color
/// @namespace statement/disabled
$btn-pure-border-color-disabled: $gray-05 !default;

/// text
/// @namespace statement/normal
$btn-pure-normal-color: $primary-brand !default;
/// text
/// @namespace statement/hover
$btn-pure-normal-color-hover: $hover-brand !default;
/// text
/// @namespace statement/active
$btn-pure-normal-color-active: $click-brand !default;
/// background
/// @namespace statement/normal
$btn-pure-normal-bg: transparent !default;
/// background
/// @namespace statement/hover
$btn-pure-normal-bg-hover: $highlight-brand !default;
/// background
/// @namespace statement/active
$btn-pure-normal-bg-active: $bright-brand !default;
/// border color
/// @namespace statement/normal
$btn-pure-normal-border-color: $bright-brand !default;
/// border color
/// @namespace statement/hover
$btn-pure-normal-border-color-hover: $bright-brand !default;
/// border color
/// @namespace statement/active
$btn-pure-normal-border-color-active: $bright-brand !default;
/// border style
/// @namespace statement/normal
$btn-pure-normal-border-style: solid !default;

// Secondary
// ----------------------------------------
/// text
/// @namespace statement/normal
$btn-pure-secondary-color: $primary-brand !default;
/// text
/// @namespace statement/hover
$btn-pure-secondary-color-hover: $hover-brand !default;
/// text
/// @namespace statement/active
$btn-pure-secondary-color-active: $click-brand !default;
/// background
/// @namespace statement/normal
$btn-pure-secondary-bg: transparent !default;
/// background
/// @namespace statement/hover
$btn-pure-secondary-bg-hover: $highlight-brand !default;
/// background
/// @namespace statement/active
$btn-pure-secondary-bg-active: $bright-brand !default;
/// border color
/// @namespace statement/normal
$btn-pure-secondary-border-color: transparent !default;
/// border color
/// @namespace statement/hover
$btn-pure-secondary-border-color-hover: transparent !default;
/// border color
/// @namespace statement/active
$btn-pure-secondary-border-color-active: transparent !default;
/// border style
/// @namespace statement/normal
$btn-pure-secondary-border-style: solid !default;

// Primary
// ----------------------------------------
/// text
/// @namespace statement/normal
$btn-pure-primary-color: $snow !default;
/// text
/// @namespace statement/hover
$btn-pure-primary-color-hover: $snow !default;
/// text
/// @namespace statement/active
$btn-pure-primary-color-active: $snow !default;
/// background
/// @namespace statement/normal
$btn-pure-primary-bg: $primary-brand !default;
/// background
/// @namespace statement/hover
$btn-pure-primary-bg-hover: $hover-brand !default;
/// background
/// @namespace statement/active
$btn-pure-primary-bg-active: $click-brand !default;
/// border color
/// @namespace statement/normal
$btn-pure-primary-border-color: transparent !default;
/// border color
/// @namespace statement/hover
$btn-pure-primary-border-color-hover: transparent !default;
/// border color
/// @namespace statement/active
$btn-pure-primary-border-color-active: transparent !default;
/// border style
/// @namespace statement/normal
$btn-pure-primary-border-style: solid !default;

// Ghost Dark
// ----------------------------------------

/// border style
/// @namespace statement/normal
$btn-ghost-border-style: solid !default;

/// text
/// @namespace statement/normal
$btn-ghost-dark-color: $snow !default;

/// border color
/// @namespace statement/normal
$btn-ghost-dark-border-color: $snow !default;

/// text
/// @namespace statement/hover
$btn-ghost-dark-color-hover: $snow !default;

/// text
/// @namespace statement/active
$btn-ghost-dark-color-active: $snow !default;

/// background
/// @namespace statement/normal
$btn-ghost-dark-bg: transparent !default;

/// bg opacity
/// @namespace statement/normal
/// @type alpha
$btn-ghost-dark-bg-opacity: 0 !default;
$btn-ghost-dark-bg-normal: rgba($btn-ghost-dark-bg, $btn-ghost-dark-bg-opacity);

/// background
/// @namespace statement/hover
$btn-ghost-dark-bg-hover-rgb: $snow !default;

/// bg opacity
/// @namespace statement/hover
/// @type alpha
$btn-ghost-dark-bg-hover-opacity: .8 !default;
$btn-ghost-dark-bg-hover: rgba($btn-ghost-dark-bg-hover-rgb, $btn-ghost-dark-bg-hover-opacity);

/// @namespace statement/active
$btn-ghost-dark-bg-active: $snow;

/// border
/// @namespace statement/hover
$btn-ghost-dark-border-color-hover: $snow !default;

/// border
/// @namespace statement/active
$btn-ghost-dark-border-color-active: $snow !default;

/// color
/// @namespace statement/disabled
$btn-ghost-dark-color-disabled-rgb: $snow !default;

/// color opacity
/// @namespace statement/disabled
/// @type alpha
$btn-ghost-dark-color-disabled-opacity: .4 !default;
$btn-ghost-dark-color-disabled: rgba($btn-ghost-dark-color-disabled-rgb, $btn-ghost-dark-color-disabled-opacity);

/// background
/// @namespace statement/disabled
$btn-ghost-dark-bg-disabled: transparent !default;

/// border
/// @namespace statement/disabled
$btn-ghost-dark-border-color-disabled-rgb: $snow !default;

/// border opacity
/// @namespace statement/disabled
/// @type alpha
$btn-ghost-dark-border-color-disabled-opacity: .4 !default;
$btn-ghost-dark-border-color-disabled: rgba($btn-ghost-dark-border-color-disabled-rgb, $btn-ghost-dark-border-color-disabled-opacity);

// Ghost Light
// ----------------------------------------

/// text
/// @namespace statement/normal
$btn-ghost-light-color: $gray-08 !default;

/// border
/// @namespace statement/normal
$btn-ghost-light-border-color: $gray-08 !default;

/// background
/// @namespace statement/normal
$btn-ghost-light-bg: transparent !default;

/// bg opacity
/// @namespace statement/normal
/// @type alpha
$btn-ghost-light-bg-opacity: 0 !default;
$btn-ghost-light-bg-normal: rgba($btn-ghost-light-bg, $btn-ghost-light-bg-opacity);

/// text
/// @namespace statement/hover
$btn-ghost-light-color-hover: $gray-06 !default;

/// text
/// @namespace statement/active
$btn-ghost-light-color-active: $gray-04 !default;

/// border
/// @namespace statement/hover
$btn-ghost-light-border-color-hover: $gray-08 !default;

/// border
/// @namespace statement/active
$btn-ghost-light-border-color-active: $gray-06 !default;

/// background
/// @namespace statement/hover
$btn-ghost-light-bg-hover-rgb: $snow !default;

/// bg opacity
/// @namespace statement/hover
/// @type alpha
$btn-ghost-light-bg-hover-opacity: .92 !default;
$btn-ghost-light-bg-hover: rgba($btn-ghost-light-bg-hover-rgb, $btn-ghost-light-bg-hover-opacity);

/// @namespace statement/active
$btn-ghost-light-bg-active: $snow;

/// text
/// @namespace statement/disabled
$btn-ghost-light-color-disabled-rgb: $shadow-color !default;

/// text opacity
/// @namespace statement/disabled
/// @type alpha
$btn-ghost-light-color-disabled-opacity: .1 !default;
$btn-ghost-light-color-disabled: rgba($btn-ghost-light-color-disabled-rgb, $btn-ghost-light-color-disabled-opacity);

/// background
/// @namespace statement/disabled
$btn-ghost-light-bg-disabled: transparent !default;

/// border
/// @namespace statement/disabled
$btn-ghost-light-border-color-disabled-rgb: $shadow-color !default;

/// border opacity
/// @namespace statement/disabled
/// @type alpha
$btn-ghost-light-border-color-disabled-opacity: .1 !default;
$btn-ghost-light-border-color-disabled: rgba($btn-ghost-light-border-color-disabled-rgb, $btn-ghost-light-border-color-disabled-opacity);

// Warning Primary
// ----------------------------------------

/// border style
/// @namespace statement/normal
$btn-warning-border-style: solid !default;

/// text
/// @namespace statement/normal
$btn-warning-primary-color: $snow !default;

/// text
/// @namespace statement/hover
$btn-warning-primary-color-hover: $snow !default;

/// text
/// @namespace statement/active
$btn-warning-primary-color-active: $snow !default;

/// border color
/// @namespace statement/normal
$btn-warning-primary-border-color: $primary-danger !default;

/// border color
/// @namespace statement/hover
$btn-warning-primary-border-color-hover: $hover-danger !default;

/// border color
/// @namespace statement/active
$btn-warning-primary-border-color-active: $click-danger !default;

/// background
/// @namespace statement/normal
$btn-warning-primary-bg: $primary-danger !default;

/// background
/// @namespace statement/hover
$btn-warning-primary-bg-hover: $hover-danger !default;

/// background
/// @namespace statement/active
$btn-warning-primary-bg-active: $click-danger !default;

/// text
/// @namespace statement/disabled
$btn-warning-primary-color-disabled: $snow !default;

/// border
/// @namespace statement/disabled
$btn-warning-primary-border-color-disabled: $gray-05 !default;

/// background
/// @namespace statement/disabled
$btn-warning-primary-bg-disabled: $gray-05 !default;

// Warning Normal
// ----------------------------------------

/// text
/// @namespace statement/normal
$btn-warning-normal-color: $primary-danger !default;

/// text
/// @namespace statement/hover
$btn-warning-normal-color-hover: $hover-danger !default;

/// text
/// @namespace statement/active
$btn-warning-normal-color-active: $click-danger !default;

/// border
/// @namespace statement/normal
$btn-warning-normal-border-color: $bright-danger !default;

/// border
/// @namespace statement/hover
$btn-warning-normal-border-color-hover: $bright-danger !default;

/// border
/// @namespace statement/active
$btn-warning-normal-border-color-active: $bright-danger !default;

/// background
/// @namespace statement/normal
$btn-warning-normal-bg: transparent !default;

/// background
/// @namespace statement/hover
$btn-warning-normal-bg-hover: rgba($bright-danger, 0.1) !default;

/// background
/// @namespace statement/active
$btn-warning-normal-bg-active: rgba($bright-danger, 0.2) !default;

/// text
/// @namespace statement/disabled
$btn-warning-normal-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled
$btn-warning-normal-border-color-disabled: $gray-04 !default;

/// background
/// @namespace statement/disabled
$btn-warning-normal-bg-disabled: transparent !default;

// Text primary & disabled & loading
// ----------------------------------------

/// text
/// @namespace statement/normal
$btn-text-primary-color: $primary-brand !default;

/// text
/// @namespace statement/hover
$btn-text-primary-color-hover: $hover-brand !default;

/// text
/// @namespace statement/active
$btn-text-primary-color-active: $click-brand !default;

/// text
/// @namespace statement/disabled
$btn-text-disabled-color: $gray-05 !default;

/// text
/// @namespace statement/loading
$btn-text-loading-color: $gray-08 !default;

// Text secondary
// ----------------------------------------

/// text
/// @namespace statement/normal
$btn-text-secondary-color: $gray-06 !default;

/// text
/// @namespace statement/hover
$btn-text-secondary-color-hover: $hover-brand !default;

/// text
/// @namespace statement/active
$btn-text-secondary-color-active: $click-brand !default;

// Text normal
// ----------------------------------------

/// text
/// @namespace statement/normal
$btn-text-normal-color: $gray-06 !default;

/// text
/// @namespace statement/hover
$btn-text-normal-color-hover: $hover-brand !default;

/// text
/// @namespace statement/active
$btn-text-normal-color-active: $click-brand !default;

// Text small
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-text-size-xs-height: $S20 !default;

/// text
/// @namespace size/text
$btn-text-size-xs-font: $font-size-body !default;

/// margin
/// @namespace size/icon
$btn-text-icon-xs-margin: $S4 !default;

// Text medium
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-text-size-s-height: $S20 !default;

/// text
/// @namespace size/text
$btn-text-size-s-font: $font-size-body !default;

/// margin
/// @namespace size/icon
$btn-text-icon-s-margin: $S4 !default;

// Text large
// ----------------------------------------

/// height
/// @namespace size/bounding
$btn-text-size-m-height: $S20 !default;

/// text
/// @namespace size/text
$btn-text-size-m-font: $font-size-body !default;

/// icon
/// @namespace size/icon
$btn-text-icon-size-l: $icon-l !default;

/// margin
/// @namespace size/icon
$btn-text-icon-m-margin: $S4 !default;

/**
* IconButton 的变量
*/

// color: default
$iconbtn-default-color: $primary-brand !default;
$iconbtn-default-color-hover: $hover-brand !default;
$iconbtn-default-color-active: $click-brand !default;
$iconbtn-default-bg: transparent !default;
$iconbtn-default-bg-hover: $highlight-brand !default;
$iconbtn-default-bg-active: $bright-brand !default;

// color: gray
$iconbtn-gray-color: $gray-06 !default;
$iconbtn-gray-color-hover: $primary-brand !default;
$iconbtn-gray-color-active: $click-brand !default;
$iconbtn-gray-bg: transparent !default;
$iconbtn-gray-bg-hover: $highlight-brand !default;
$iconbtn-gray-bg-active: $bright-brand !default;

// iconbtn disabled
$iconbtn-disabled-color: $gray-05 !default;
