// shape
#{$calendar-prefix} {
  // shape: fullscreen, card
  // ------------------------
  &-fullscreen,
  &-card {
    #{$calendar-prefix}-header {
      text-align: right;

      #{$calendar-select-prefix} {
        margin-right: $S4;
        vertical-align: top;
      }

      #{$calendar-menu-prefix} {
        text-align: left;
      }
    }
  }

  // shape: fullscreen
  &-fullscreen {
    #{$calendar-prefix}-header {
      margin-bottom: $calendar-fullscreen-header-margin-bottom;
    }
  }

  // shape: card
  &-card {
    #{$calendar-prefix}-header {
      margin-bottom: $calendar-card-header-margin-bottom;
    }
  }

  // shape: panel
  // ------------------------
  &-panel {
    padding: 0 $S16;
    &-header {
      height: $S36;
      display: flex;
      background: $calendar-panel-header-background;
      margin-bottom: $calendar-panel-header-margin-bottom;

      &-left,
      &-right,
      &-full {
        height: $calendar-panel-header-height;
        line-height: $calendar-panel-header-height;

        #{$calendar-prefix}-btn {
          vertical-align: top;
          font-weight: $calendar-btn-date-font-weight;
          margin: 0 $calendar-btn-date-margin-lr;
        }
      }

      &-left,
      &-right {
        display: inline-block;
        width: 50%;
        text-align: center;
      }

      &-full {
        display: inline-block;
        flex: 1;
        text-align: center;
        cursor: pointer;
        &:hover {
          color: $calendar-btn-date-color-hover;
        }
      }
    }
  }

  // panel components
  // ------------------------
  &-btn {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    outline: none;
    height: 100%;
  }

  &-btn-prev-year,
  &-btn-prev-month,
  &-btn-prev-decade,
  &-btn-next-month,
  &-btn-next-year,
  &-btn-next-decade {
    color: $calendar-btn-arrow-color;
    &:hover {
      color: $calendar-btn-arrow-color-hover;
    }
  }

  &-btn-prev-decade,
  &-btn-prev-year {
    left: $calendar-btn-arrow-double-offset-lr;
  }

  &-btn-prev-month {
    left: $calendar-btn-arrow-single-offset-lr;
  }

  &-btn-next-month {
    right: $calendar-btn-arrow-single-offset-lr;
  }

  &-btn-next-year,
  &-btn-next-decade {
    right: $calendar-btn-arrow-double-offset-lr;
  }
}
