////
/// @module card: 卡片
/// @tag Card
/// @category component
/// @family data-display
/// @varPrefix $card-
/// @classPrefix {prefix}-card
/// @order {"size/bounding":10,"size/divider":11,"size/header":12,"size/content":13,"size/footer":14,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/header":101,"statement/normal/footer":102}
////

$card-prefix: '.' + $css-prefix + 'card';

// animation
$ease-out: cubic-bezier(.23, 1, .32, 1);
$ease-in: cubic-bezier(.755, .05, .855, .06);

/// corner
/// @namespace size/bounding
$card-corner: $shape-radius !default;

/// padding (l, r)
/// @namespace size/bounding
$card-padding-lr: $S20 !default;

/// border width
/// @namespace size/bounding
$card-border-width: 1px !default;

/// padding (t)
/// @namespace size/divider
$card-head-padding-bottom: $S12 !default;

/// border width (b)
/// @namespace size/divider
$card-head-bottom-border-width: 1px !default;

/// height
/// @namespace size/header
$card-head-main-height: $S40 !default;

/// margin (t)
/// @namespace size/header
$card-head-main-margin-top: $S16 !default;

/// margin (b)
/// @namespace size/header
$card-head-main-margin-bottom: $S16 !default;

/// title text
/// @namespace size/header
$card-title-font-size: $font-size-subhead !default;

/// sub title text
/// @namespace size/header
$card-sub-title-font-size: $font-size-caption !default;

/// extra text
/// @namespace size/header
$card-title-extra-font-size: $font-size-body !default;

/// extra tab
$card-title-extra-tab-margin-top: $S8 !default;
$card-title-extra-tab-margin-right: $S20 !default;

/// line width
/// @namespace size/header
$card-title-bullet-width: 3px !default;

/// line height
/// @namespace size/header
$card-title-bullet-height: $S16 !default;

/// title padding (l)
/// @namespace size/header
$card-title-padding-left: $S8 !default;

/// sub title padding (l)
/// @namespace size/header
$card-sub-title-padding-left: $S8 !default;

/// padding (t)
/// @namespace size/content
$card-body-show-divider-padding-top: $S12 !default;

/// padding (t)
/// @namespace size/content
$card-body-hide-divider-padding-top: $S0 !default;

/// padding (b)
/// @namespace size/content
$card-body-padding-bottom: $S20 !default;

/// more button height
/// @namespace size/footer
$card-more-btn-height: $S16 !default;

/// padding (t)
/// @namespace size/footer
$card-more-btn-padding-top: $S12 !default;

/// expand button size
/// @namespace size/footer
$card-more-btn-font-size: $font-size-body !default;

/// shadow
/// @namespace statement/normal/bounding
$card-shadow: $shadow-01 !default;

/// border style
/// @namespace statement/normal/bounding
$card-border-style: solid !default;

/// border color
/// @namespace statement/normal/bounding
$card-border-color: $gray-03 !default;

/// background
/// @namespace statement/normal/bounding
$card-background: $snow !default;

/// background
/// @namespace statement/normal/header
$card-header-background: $snow !default;

/// title color
/// @namespace statement/normal/header
$card-title-color: $gray-08 !default;

/// sub title color
/// @namespace statement/normal/header
$card-sub-title-color: $gray-07 !default;

/// extra color
/// @namespace statement/normal/header
$card-title-extra-color: $gray-06 !default;

/// line color
/// @namespace statement/normal/header
$card-title-bullet-color: $primary-brand !default;

/// divider color
/// @namespace statement/normal/header
$card-head-bottom-border-color: $gray-04 !default;

/// expand button color
/// @namespace statement/normal/footer
$card-more-btn-color: $gray-06 !default;

/// background
/// @namespace statement/normal/footer
$card-more-btn-background: $snow !default;
