@import '../core/index-noreset.scss';
@import '../button/scss/variable.scss';
@import 'scss/mixin';
@import 'scss/variable';

#{$menu-btn-prefix} {
  display: inline-block;
  box-shadow: none;

  .#{$css-prefix}icon {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.#{$css-prefix}expand .#{$css-prefix}menu-btn-arrow {
    transform: rotate(180deg);
  }
}
