@import '../core/index-noreset';

@import 'scss/variable';
@import 'scss/normalize';
@import 'scss/mixin';
@import 'scss/animation';

@import 'scss/header';
@import 'scss/table';
@import './rtl.scss';

#{$calendar-prefix} {
  &-table {
    width: 100%;
    table-layout: fixed;
    border: none;
  }

  /* range calendar */
  /* --------------- */
  &-range {
    padding: 0 $S16;
    #{$calendar-prefix}-body {
      &-left,
      &-right {
        float: left;
        width: 50%;
      }

      &-left {
        padding-right: $S8;
      }

      &-right {
        padding-left: $S8;
      }

      @include clearfix;
    }
  }
}
