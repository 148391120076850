@charset "UTF-8";

.#{$css-prefix}switch {
  &:after[dir="rtl"] {
    content: " ";
    transition: transform .4s cubic-bezier(.78, .14, .15, .86), left .4s cubic-bezier(.78, .14, .15, .86);
    transform-origin: right center;
  }

  &-medium[dir="rtl"] {
    &:after {
      right: 100%;
      transform: translateX(100%);
    }
  }

  &-small[dir="rtl"] {
    &:after {
      right: 100%;
      transform: translateX(100%);
    }
  }

  &-on[dir="rtl"] {
    > .#{$css-prefix}switch-children {
      right: $switch-text-on-left + $switch-border-width-container * 2;
      left: auto;
      color: $switch-normal-on-color-font;
    }
  }

  &-on[disabled][dir="rtl"] {
    &:after {
      left: 0;
      right: 100%;
      box-shadow: $switch-on-shadow;
    }
  }

  &-on#{&}-small[dir="rtl"] {
    > .#{$css-prefix}switch-children {
      right: $switch-size-s-text-on-left + $switch-border-width-container * 2;
      left: auto;
    }
  }

  &-off[dir="rtl"] {
    &:after {
      right: 0;
      transform: translateX(0);
      box-shadow: $switch-rtl-on-shadow;
    }
    > .#{$css-prefix}switch-children {
      left: $switch-text-off-right + $switch-border-width-container * 2;
      right: auto;
    }
  }

  &-off#{&}-small[dir="rtl"] {
    > .#{$css-prefix}switch-children {
      left: $switch-size-s-text-off-right + $switch-border-width-container;
      right: auto;
    }
  }
}
