@charset "UTF-8";

// breadcrumb variables
// --------------------------------------------------

////
/// @module breadcrumb: 面包屑
/// @tag Breadcrumb
/// @category component
/// @family navigation
/// @varPrefix $breadcrumb-
/// @classPrefix {prefix}-breadcrumb
/// @order {"size/item":10,"size/ellipsis":11,"size/number":12,"size/keyword":13,"size/seperator":14,"statement/normal":10,"statement/normal/item":100,"statement/normal/item current":101,"statement/normal/ellipsis":102,"statement/normal/number":103,"statement/normal/keyword":104,"statement/normal/seperator":105,"statement/hover":11,"statement/hover/item":110,"statement/hover/item current":111,"statement/hover/number":112,"statement/hover/keyword":113}
////

// Unconfigurable
// ----------------------------------------

// 整体高度，不可配
$breadcrumb-height: $S20 !default;
// text 最小宽度，不可配
$breadcrumb-text-min-width: $S16 !default;

// Size
// ----------------------------------------

/// text
/// @namespace size/item
$breadcrumb-size-m-font-size: 14px;

/// text
/// @namespace size/ellipsis
$breadcrumb-size-ellipsis-font-size: 14px;

/// size
/// @namespace size/seperator
$breadcrumb-size-m-icon-size: $icon-m !default;

/// margin (l, r)
/// @namespace size/seperator
$breadcrumb-size-m-icon-margin: $S4 !default;

// Statement:Normal
// ----------------------------------------

/// text
/// @namespace statement/normal/item
$breadcrumb-text-color: $gray-06 !default;

/// text
/// @namespace statement/normal/item current
$breadcrumb-text-current-color: $gray-08 !default;
/// weight
/// @namespace statement/normal/item current
$breadcrumb-text-current-weight: $font-weight-regular !default;

/// text
/// @namespace statement/normal/ellipsis
$breadcrumb-text-ellipsis-color: $gray-06 !default;

/// text
/// @namespace statement/normal/keyword
$breadcrumb-text-keyword-color: $primary-brand !default;

/// text
/// @namespace statement/normal/seperator
$breadcrumb-icon-color: $gray-06 !default;

// Statement:Hover
// ----------------------------------------

/// text
/// @namespace statement/hover/item
$breadcrumb-text-color-hover: $primary-brand !default;

/// text
/// @namespace statement/hover/item current
$breadcrumb-text-current-color-hover: $primary-brand !default;

/// text
/// @namespace statement/hover/keyword
$breadcrumb-text-keyword-color-hover: $primary-brand !default;

// Statement:Active
// ----------------------------------------

/// text
/// @namespace statement/hover/item
$breadcrumb-text-color-active: $hover-brand !default;

/// text
/// @namespace statement/hover/item current
$breadcrumb-text-current-color-active: $hover-brand !default;

/// text
/// @namespace statement/hover/keyword
$breadcrumb-text-keyword-color-active: $hover-brand !default;