@import '../core/index-noreset.scss';
@import './size.scss';

$icon-prefix: #{$css-prefix}icon;

.#{$icon-prefix} {
  display: inline-block;
  line-height: 0;
  text-align: center;
  vertical-align: -0.25em;
  svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    overflow: hidden;
  }
  &-loading {
    animation: loadingCircle 1s infinite linear;
  }
}
@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }

  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg);
  }
}

.#{$icon-prefix}.#{$css-prefix} {
  &xs {
    @include icon-size($icon-xs);
  }

  &small {
    @include icon-size($icon-s);
  }

  &medium {
    @include icon-size($icon-m);
  }

  &large {
    @include icon-size($icon-l);
  }

  &xl {
    @include icon-size($icon-xl);
  }

  &xxl {
    @include icon-size($icon-xxl);
  }

  &xxxl {
    @include icon-size($icon-xxxl);
  }

  &inherit {
    @include icon-size(inherit);
  }
}

@import './rtl.scss';
