@charset "UTF-8";

@import '../core/index-noreset.scss';
@import 'scss/mixin';
@import 'scss/variable';

#{$number-picker-prefix} {
  @include box-sizing;
  display: inline-block;

  /* stylelint-disable declaration-no-important */
  .#{$css-prefix}btn {
    padding: 0 !important;
    line-height: 0 !important;
    box-shadow: none !important;
  }

  &-normal {
    width: 80px;

    .#{$css-prefix}input {
      width: 100%;

      input {
        padding-right: 2px;
      }

      .#{$css-prefix}input-control {
        padding-right: 0;
      }
    }

    .#{$css-prefix}btn {
      display: block;

      &:hover, &:active {
        z-index: 1;
        background: $gray-01;
      }

      &:first-child {
        border-right: none;
        border-top: none;
        height: calc(50%);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-right: none;
        border-bottom: none;
        margin-top: -1px;
        height: calc(50% + 1px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    #{$number-picker-prefix}-handler {
      opacity: 0;
      transition: opacity 0.24s linear 0.1s;
      height: 100%;
      display: block;
    }

    &:hover #{$number-picker-prefix}-handler {
      opacity: 1;
    }

    .#{$css-prefix}input.#{$css-prefix}disabled #{$number-picker-prefix}-handler {
      opacity: 0;
    }

    &.#{$css-prefix}medium {
      .#{$css-prefix}btn {
        width: $number-picker-normal-m-button-width;
        min-width: auto; // 覆盖Button的最小宽度
      }

      .#{$css-prefix}icon {
        @include icon-size($number-picker-normal-m-button-icon-size);
      }
    }
  }

  &-inline {
    input {
      text-align: center;
    }

    .#{$css-prefix}input input {
      padding: 0;
    }

    &.#{$css-prefix}medium {
      width: 100px;

      .#{$css-prefix}icon {
        @include icon-size($number-picker-inline-m-button-icon-size);
      }

      .#{$css-prefix}btn {
        height: $form-element-medium-height;
      }

      .#{$css-prefix}btn:first-child {
        margin-right: $number-picker-inline-m-button-margin;
      }

      .#{$css-prefix}btn:last-child {
        margin-left: $number-picker-inline-m-button-margin;
      }
    }

    .#{$css-prefix}btn {
      &.#{$css-prefix}medium {
        width: $form-element-medium-height;
      }
    }
  }
}

#{$number-picker-prefix}-normal[dir='rtl'] {
  .#{$css-prefix}btn {
    &:first-child {
      border-right: 1px solid $gray-04;
      border-left: 0;
      border-top-left-radius: $form-element-medium-corner;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-right: 1px solid $gray-04;
      border-left: 0;
      border-bottom-left-radius: $form-element-medium-corner;
      border-bottom-right-radius: 0;
    }
  }
}
#{$number-picker-prefix}-inline[dir='rtl'] {
  .#{$css-prefix}btn {
    border-radius: 0;
  }
  //TODO: remove !important in 2.x
  .#{$css-prefix}before .#{$css-prefix}btn {
    border-top-right-radius: $form-element-medium-corner !important;
    border-bottom-right-radius: $form-element-medium-corner !important;
  }
  .#{$css-prefix}after .#{$css-prefix}btn {
    border-top-left-radius: $form-element-medium-corner !important;
    border-bottom-left-radius: $form-element-medium-corner !important;
  }
}
#{$number-picker-prefix}-normal[dir='rtl'] {
  .#{$css-prefix}input .#{$css-prefix}input-control {
    padding-left: 0;
  }
}
