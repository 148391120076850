@charset "UTF-8";

////
/// @module tag: 标签
/// @tag Tag
/// @category component
/// @family data-display
/// @varPrefix $tag-
/// @classPrefix {prefix}-tag
/// @order {"size/bounding":10,"size/content":11,"size/tail":12,"size/tick":13,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/tail":101,"statement/hover":11,"statement/hover/tail":110,"statement/hover/bounding":111,"statement/disabled":12,"statement/disabled/tail":120,"statement/disabled/bounding":121,"statement/selected":13,"statement/selected/tick":130,"statement/selected-hover":14,"statement/selected-hover/tick":140,"statement/selected-disabled":15,"statement/selected-disabled/bounding":150,"statement/selected-disabled/tick":151}
////

// Size
// -------------------

/// height
/// @namespace size/bounding
$tag-size-l-height: $S40 !default;
/// height
/// @namespace size/bounding
$tag-size-m-height: $S28 !default;
/// height
/// @namespace size/bounding
$tag-size-s-height: $S20 !default;

/// padding (l, r)
/// @namespace size/bounding
$tag-size-l-padding-lr: $S16 !default;

/// padding (l, r)
/// @namespace size/bounding
$tag-size-m-padding-lr: $S12 !default;

/// padding (l, r)
/// @namespace size/bounding
$tag-size-s-padding-lr: $S8 !default;

/// corner
/// @namespace size/bounding
$tag-corner-radius: $shape-radius !default;

/// corner
/// @namespace size/bounding
$tag-checkable-corner-radius: $shape-radius !default;

/// border
/// @namespace size/bounding
$tag-border-width: 1px !default;

/// shadow
/// @namespace size/bounding
$tag-shadow: none !default;

/// text
/// @namespace size/content
$tag-size-l-text-size: $font-size-subhead !default;

/// text
/// @namespace size/content
$tag-size-m-text-size: $font-size-body !default;

/// text
/// @namespace size/content
$tag-size-s-text-size: $font-size-caption !default;

/// min width
/// @namespace size/content
$tag-size-s-content-min-width: $S28 !default;

/// min width
/// @namespace size/content
$tag-size-m-content-min-width: $S40 !default;

/// min width
/// @namespace size/content
$tag-size-l-content-min-width: $S48 !default;

/// icon size
/// @namespace size/tail
$tag-size-l-icon-font: $icon-xs !default;

/// icon size
/// @namespace size/tail
$tag-size-m-icon-font: $icon-xs !default;

/// icon size
/// @namespace size/tail
$tag-size-s-icon-font: $icon-xs !default;

/// icon size
/// @namespace size/tick
$tag-size-l-tick-icon-size: $icon-s !default;

/// icon size
/// @namespace size/tick
$tag-size-m-tick-icon-size: $icon-xs !default;

/// icon size
/// @namespace size/tick
$tag-size-s-tick-icon-size: $icon-xs !default;

/// background size
/// @namespace size/tick
$tag-size-l-tick-icon-bg-size: $S36 !default;

/// background size
/// @namespace size/tick
$tag-size-m-tick-icon-bg-size: $S28 !default;

/// background size
/// @namespace size/tick
$tag-size-s-tick-icon-bg-size: $S20 !default;

// Unconfigurable for size
// -------------------

$tag-size-line-height: $S16 !default;
$tag-size-m-margin: $S8 !default;
$tag-size-s-margin: $S4 !default;

// icon - unconfigurable
$tag-checkable-icon-selected: '\e67f';
$tag-closable-normal-icon: '\e69b';

// Animation
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);

// Statement
// -------------------

/// text
/// @namespace statement/normal/bounding
$tag-normal-text-color: $gray-08 !default;

/// text
/// @namespace statement/normal/bounding
$tag-fill-text-color: $gray-07 !default;

/// text
/// @namespace statement/normal/tail
$tag-closable-normal-icon-color: $snow !default;

/// text
/// @namespace statement/hover/tail
$tag-closable-normal-icon-color-hover: $snow !default;

/// text
/// @namespace statement/disabled/tail
$tag-closable-normal-icon-color-disabled: $snow !default;

/// background
/// @namespace statement/normal/tail
$tag-closable-normal-bg: $gray-04 !default;

/// background
/// @namespace statement/hover/tail
$tag-closable-normal-bg-hover: $gray-07 !default;

/// background
/// @namespace statement/disabled/tail
$tag-closable-normal-bg-disabled: $gray-04 !default;

/// text
/// @namespace statement/normal/bounding
$tag-closable-bordered-text-color: $gray-07 !default;

/// border
/// @namespace statement/normal/bounding
$tag-closable-bordered-border-color: $gray-04 !default;

/// background
/// @namespace statement/normal/bounding
$tag-closable-bordered-bg: transparent !default;

/// text
/// @namespace statement/hover/bounding
$tag-closable-bordered-text-color-hover: $gray-08 !default;

/// border
/// @namespace statement/hover/bounding
$tag-closable-bordered-border-color-hover: $gray-07 !default;

/// background
/// @namespace statement/hover/bounding
$tag-closable-bordered-bg-hover: transparent !default;

/// text
/// @namespace statement/normal/tail
$tag-closable-bordered-tail-color: $gray-07 !default;

/// text
/// @namespace statement/hover/tail
$tag-closable-bordered-tail-color-hover: $gray-08 !default;

/// text
/// @namespace statement/disabled/tail
$tag-closable-bordered-tail-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled/bounding
$tag-closable-bordered-border-color-disabled: $gray-04 !default;

/// text
/// @namespace statement/disabled/bounding
$tag-closable-bordered-text-color-disabled: $gray-05 !default;

/// background
/// @namespace statement/disabled/bounding
$tag-closable-bordered-bg-disabled: transparent !default;

/// text
/// @namespace statement/normal/tail
$tag-fill-tail-color: $gray-07 !default;

/// border
/// @namespace statement/normal/bounding
$tag-fill-border-color: $gray-02 !default;

/// border
/// @namespace statement/hover/bounding
$tag-fill-border-color-hover: $gray-01 !default;

/// text
/// @namespace statement/disabled/bounding
$tag-fill-text-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled/bounding
$tag-fill-border-color-disabled: $gray-01 !default;

/// background
/// @namespace statement/disabled/bounding
$tag-fill-bg-color-disabled: $gray-01 !default;

/// text
/// @namespace statement/disabled/tail
$tag-fill-tail-color-disabled: $gray-05 !default;

/// text
/// @namespace statement/normal/bounding
$tag-bordered-text-color: $gray-07 !default;

/// text
/// @namespace statement/normal/tail
$tag-bordered-tail-color: $gray-07 !default;

/// text
/// @namespace statement/normal/bounding
$tag-secondary-text-color: $primary-brand !default;

/// text
/// @namespace statement/hover/bounding
$tag-normal-text-color-hover: $primary-brand !default;

/// text
/// @namespace statement/hover/bounding
$tag-fill-text-color-hover: $gray-08 !default;

/// text
/// @namespace statement/hover/tail
$tag-fill-tail-color-hover: $gray-08 !default;

/// text
/// @namespace statement/hover/bounding
$tag-bordered-text-color-hover: $gray-08 !default;

/// text
/// @namespace statement/hover/tail
$tag-bordered-tail-color-hover: $gray-08 !default;

/// text
/// @namespace statement/disabled/tail
$tag-bordered-tail-color-disabled: $gray-05 !default;

/// text
/// @namespace statement/disabled/bounding
$tag-bordered-text-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled/bounding
$tag-bordered-border-color-disabled: $gray-04 !default;

/// background
/// @namespace statement/disabled/bounding
$tag-bordered-bg-disabled: $gray-01 !default;

/// text
/// @namespace statement/hover/bounding
$tag-secondary-text-color-hover: $hover-brand !default;

/// border
/// @namespace statement/normal/bounding
$tag-bordered-border-color: $gray-04 !default;

/// border
/// @namespace statement/normal/bounding
$tag-secondary-border-color: $primary-brand !default;

/// background
/// @namespace statement/selected/tick
$tag-checkable-normal-bg-selected: $primary-brand !default;

/// background
/// @namespace statement/selected/tick
$tag-checkable-secondary-bg-selected: $primary-brand !default;

/// border
/// @namespace statement/hover/bounding
$tag-bordered-border-color-hover: $gray-07 !default;

/// border
/// @namespace statement/hover/bounding
$tag-secondary-border-color-hover: $hover-brand !default;

/// background
/// @namespace statement/selected-hover/tick
$tag-checkable-normal-bg-selected-hover: $hover-brand !default;

/// background
/// @namespace statement/selected-hover/tick
$tag-checkable-secondary-bg-selected-hover: $hover-brand !default;

/// background
/// @namespace statement/normal/bounding
$tag-fill-bg-color: $gray-02 !default;

/// background
/// @namespace statement/normal/bounding
$tag-primary-background-color: $primary-brand !default;

/// background
/// @namespace statement/normal/bounding
$tag-bordered-bg: transparent !default;

/// background
/// @namespace statement/normal/bounding
$tag-secondary-bg: transparent !default;

/// background
/// @namespace statement/hover/bounding
$tag-fill-bg-color-hover: $gray-01 !default;

/// background
/// @namespace statement/hover/bounding
$tag-bordered-bg-hover: transparent !default;

/// background
/// @namespace statement/hover/bounding
$tag-secondary-bg-hover: transparent !default;

/// text
/// @namespace statement/disabled/bounding
$tag-text-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled/bounding
$tag-border-color-disabled: $gray-04 !default;

/// text
/// @namespace statement/normal/bounding
$tag-checkable-fill-text-color: $gray-07 !default;

/// background
/// @namespace statement/normal/bounding
$tag-checkable-fill-bg-color: $gray-02 !default;

/// border
/// @namespace statement/normal/bounding
$tag-checkable-fill-border-color: $gray-02 !default;

/// text
/// @namespace statement/hover/bounding
$tag-checkable-fill-text-color-hover: $gray-08 !default;

/// background
/// @namespace statement/hover/bounding
$tag-checkable-fill-bg-color-hover: $gray-01 !default;

/// border
/// @namespace statement/hover/bounding
$tag-checkable-fill-border-color-hover: $gray-01 !default;

/// text
/// @namespace statement/normal/tail
$tag-checkable-fill-tail-color: $gray-07 !default;

/// text
/// @namespace statement/hover/tail
$tag-checkable-fill-tail-color-hover: $gray-08 !default;

/// text
/// @namespace statement/disabled/tail
$tag-checkable-fill-tail-color-disabled: $gray-05 !default;

/// text
/// @namespace statement/disabled/bounding
$tag-checkable-fill-text-color-disabled: $gray-05 !default;

/// border
/// @namespace statement/disabled/bounding
$tag-checkable-fill-border-color-disabled: $gray-01 !default;

/// background
/// @namespace statement/disabled/bounding
$tag-checkable-fill-bg-color-disabled: $gray-01 !default;

/// text
/// @namespace statement/normal/bounding
$tag-checkable-normal-text-color: $gray-07 !default;

/// border
/// @namespace statement/normal/bounding
$tag-checkable-normal-border-color: $gray-04 !default;

/// background
/// @namespace statement/normal/bounding
$tag-checkable-normal-bg: transparent !default;

/// text
/// @namespace statement/hover/bounding
$tag-checkable-normal-text-color-hover: $gray-08 !default;

/// border
/// @namespace statement/hover/bounding
$tag-checkable-normal-border-color-hover: $gray-04 !default;

/// background
/// @namespace statement/hover/bounding
$tag-checkable-normal-bg-hover: transparent !default;

/// text
/// @namespace statement/selected-disabled/bounding
$tag-checkable-normal-text-selected-disabled: $gray-05 !default;

/// border
/// @namespace statement/selected-disabled/bounding
$tag-checkable-normal-border-selected-disabled: $gray-04 !default;

/// background
/// @namespace statement/selected-disabled/bounding
$tag-checkable-normal-bg-selected-disabled: $gray-01 !default;

/// background
/// @namespace statement/selected-disabled/bounding
$tag-checkable-normal-tick-bg-selected-disabled: $gray-04 !default;

/// text
/// @namespace statement/normal/bounding
$tag-checkable-primary-text-color: $snow !default;

/// background
/// @namespace statement/normal/bounding
$tag-checkable-primary-bg-color: $primary-brand !default;

/// border
/// @namespace statement/normal/bounding
$tag-checkable-primary-border-color: $primary-brand !default;

/// text
/// @namespace statement/disabled/bounding
$tag-checkable-primary-text-color-disabled: $gray-05 !default;

/// text
/// @namespace statement/hover/bounding
$tag-checkable-primary-text-color-hover: $snow !default;

/// background
/// @namespace statement/hover/bounding
$tag-checkable-primary-bg-color-hover: $hover-brand !default;

/// border
/// @namespace statement/hover/bounding
$tag-checkable-primary-border-color-hover: $hover-brand !default;

/// border
/// @namespace statement/disabled/bounding
$tag-checkable-primary-border-color-disabled: $gray-01 !default;

/// background
/// @namespace statement/disabled/bounding
$tag-checkable-primary-bg-color-disabled: $gray-01 !default;

/// text
/// @namespace statement/normal/tail
$tag-checkable-primary-tail-color: $snow !default;

/// text
/// @namespace statement/hover/tail
$tag-checkable-primary-tail-color-hover: $snow !default;

/// background
/// @namespace statement/selected-disabled/tick
$tag-checkable-secondary-bg-selected-disabled: $gray-04 !default;

/// text
/// @namespace statement/selected/tick
$tag-checkable-normal-icon-color-selected: $snow !default;

/// text
/// @namespace statement/selected-hover/tick
$tag-checkable-normal-icon-color-selected-hover: $snow !default;

/// text
/// @namespace statement/selected-disabled/tick
$tag-checkable-normal-icon-color-selected-disabled: $snow !default;

/// text
/// @namespace statement/selected/tick
$tag-checkable-secondary-icon-color-selected: $snow !default;

/// text
/// @namespace statement/selected-hover/tick
$tag-checkable-secondary-icon-color-selected-hover: $snow !default;

/// text
/// @namespace statement/selected-disabled/tick
$tag-checkable-secondary-icon-color-selected-disabled: $snow !default;

/// text
/// @namespace statement/normal/bounding
$tag-closable-primary-fill-text-color: $gray-07 !default;
/// border
/// @namespace statement/normal/bounding
$tag-closable-primary-fill-border-color: $gray-02;
/// background
/// @namespace statement/normal/bounding
$tag-closable-primary-fill-bg-color: $gray-02 !default;
/// text
/// @namespace statement/hover/bounding
$tag-closable-primary-fill-text-color-hover: $gray-08 !default;
/// border
/// @namespace statement/hover/bounding
$tag-closable-primary-fill-border-color-hover: $gray-01 !default;
/// background
/// @namespace statement/hover/bounding
$tag-closable-primary-fill-bg-color-hover: $gray-01 !default;
/// text
/// @namespace statement/disabled/bounding
$tag-closable-primary-fill-text-color-disabled: $gray-05 !default;
/// border
/// @namespace statement/disabled/bounding
$tag-closable-primary-fill-border-color-disabled: $gray-01 !default;
/// background
/// @namespace statement/disabled/bounding
$tag-closable-primary-fill-bg-color-disabled: $gray-01 !default;
