@charset "UTF-8";

////
/// @module search: 搜索框
/// @tag Search
/// @category component
/// @family data-entry
/// @varPrefix $search-
/// @classPrefix {prefix}-search
/// @order {"size/bounding":10,"size/btn":11,"size/margin":12,"size/simple":13,"statement/normal":10,"statement/normal/btn":100,"statement/btn":11,"statement/hover":12,"statement/hover/btn":120,"statement/simple":13,"statement/icon":14}
////

$search-prefix: '.#{$css-prefix}search';

// Normal
/// corner
/// @namespace size/bounding
$search-normal-corner: $shape-radius !default;

// Primary
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-normal-primary-color: $primary-brand !default;
/// hover
/// @namespace statement/normal
$search-normal-primary-hover-color: $primary-brand !default;
/// split
/// @namespace statement/normal
$search-normal-primary-split-color: $gray-04 !default;
/// shadow
/// @namespace statement/normal
$search-normal-primary-shadow: none !default;
/// text
/// @namespace statement/btn
$search-normal-primary-btn-text-color: $snow !default;
/// background
/// @namespace statement/hover
$search-normal-primary-btn-hover-bg-color: $hover-brand !default;
/// text
/// @namespace statement/hover
$search-normal-primary-btn-hover-text-color: $snow !default;
/// border
/// @namespace size/bounding
$search-normal-primary-border-width: 2px !default;
/// icon-margin(r)
/// @namespace size/btn
$search-normal-primary-btn-text-padding-left: $S0 !default;


// Primary Large
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-primary-l-height: $S48 !default;
/// icon
/// @namespace size/bounding
$search-normal-primary-l-icon-size: $icon-l !default;
/// text
/// @namespace size/btn
$search-normal-primary-l-btn-text-size: $font-size-subhead !default;
// Primary Medium
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-primary-m-height: $S40 !default;
/// icon
/// @namespace size/bounding
$search-normal-primary-m-icon-size: $icon-s !default;
/// text
/// @namespace size/btn
$search-normal-primary-m-btn-text-size: $font-size-subhead !default;
// Secondary
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-normal-secondary-color: $gray-04 !default;
/// split
/// @namespace statement/normal
$search-normal-secondary-split-color: $gray-04 !default;
/// hover
/// @namespace statement/normal
$search-normal-secondary-hover-color: $primary-brand !default;
/// shadow
/// @namespace statement/normal
$search-normal-secondary-shadow: none !default;
/// background
/// @namespace statement/btn
$search-normal-secondary-btn-bg-color: $primary-brand !default;
/// text
/// @namespace statement/btn
$search-normal-secondary-btn-text-color: $snow !default;
/// background
/// @namespace statement/hover
$search-normal-secondary-btn-hover-bg-color: $hover-brand !default;
/// text
/// @namespace statement/hover
$search-normal-secondary-btn-hover-text-color: $snow !default;
/// border
/// @namespace size/bounding
$search-normal-secondary-border-width: 1px !default;
/// icon-margin(r)
/// @namespace size/btn
$search-normal-secondary-btn-text-padding-left: $S0 !default;
// Secondary Large
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-secondary-l-height: $S48 !default;
/// icon
/// @namespace size/bounding
$search-normal-secondary-l-icon-size: $icon-l !default;
/// text
/// @namespace size/btn
$search-normal-secondary-l-btn-text-size: $font-size-subhead !default;
// Secondary Medium
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-secondary-m-height: $S40 !default;
/// icon
/// @namespace size/bounding
$search-normal-secondary-m-icon-size: $icon-s !default;
/// text
/// @namespace size/btn
$search-normal-secondary-m-btn-text-size: $font-size-subhead !default;

// Normal
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-normal-normal-color: $gray-04 !default;
/// split
/// @namespace statement/normal
$search-normal-normal-split-color: $gray-04 !default;
/// hover
/// @namespace statement/normal
$search-normal-normal-hover-color: $gray-07 !default;
/// shadow
/// @namespace statement/normal
$search-normal-normal-shadow: none !default;
/// background
/// @namespace statement/normal/btn
$search-normal-normal-btn-bg-color: $gray-01 !default;
/// text
/// @namespace statement/normal/btn
$search-normal-normal-btn-text-color: $gray-07 !default;
/// background
/// @namespace statement/hover/btn
$search-normal-normal-btn-hover-bg-color: $gray-02 !default;
/// text
/// @namespace statement/hover/btn
$search-normal-normal-btn-hover-text-color: $gray-08 !default;
/// border
/// @namespace size/bounding
$search-normal-normal-border-width: 1px !default;
/// icon-margin(r)
/// @namespace size/btn
$search-normal-normal-btn-text-padding-left: $S0 !default;

// Normal Large
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-normal-l-height: $S48 !default;
/// icon
/// @namespace size/bounding
$search-normal-normal-l-icon-size: $icon-l !default;
/// text
/// @namespace size/btn
$search-normal-normal-l-btn-text-size: $font-size-subhead !default;
// Normal Medium
// ----------------------------------------
/// height
/// @namespace size/bounding
$search-normal-normal-m-height: $S40 !default;
/// icon
/// @namespace size/bounding
$search-normal-normal-m-icon-size: $icon-s !default;
/// text
/// @namespace size/btn
$search-normal-normal-m-btn-text-size: $font-size-subhead !default;

// Dark
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-normal-dark-color: $primary-brand !default;
/// hover
/// @namespace statement/normal
$search-normal-dark-hover-color: $primary-brand !default;
/// text
/// @namespace statement/normal
$search-normal-dark-text-color: $snow !default;
/// background
/// @namespace statement/normal
$search-normal-dark-bg-color: $snow !default;
/// background-opacity
/// @namespace statement/normal
/// @type alpha
$search-normal-dark-bg-opacity: 0 !default;
/// shadow
/// @namespace statement/normal
$search-normal-dark-shadow: none !default;
/// text
/// @namespace statement/btn
$search-normal-dark-btn-text-color: $snow !default;
/// background
/// @namespace statement/hover
$search-normal-dark-btn-hover-bg-color: $hover-brand !default;
/// text
/// @namespace statement/hover
$search-normal-dark-btn-hover-text-color: $snow !default;
/// border
/// @namespace size/bounding
$search-normal-dark-border-width: 1px !default;
/// icon-margin(r)
/// @namespace size/btn
$search-normal-dark-btn-text-padding-left: $S0 !default;

/// height
/// @namespace size/bounding
$search-normal-dark-l-height: $S48 !default;
/// icon
/// @namespace size/bounding
$search-normal-dark-l-icon-size: $icon-l !default;
/// text
/// @namespace size/btn
$search-normal-dark-l-btn-text-size: $font-size-subhead !default;
/// height
/// @namespace size/bounding
$search-normal-dark-m-height: $S40 !default;
/// icon
/// @namespace size/bounding
$search-normal-dark-m-icon-size: $icon-s !default;
/// text
/// @namespace size/btn
$search-normal-dark-m-btn-text-size: $font-size-subhead !default;
/// text
/// @namespace size/btn
$search-normal-dark-m-btn-text-size: $font-size-subhead !default;

// Simple
/// corner
/// @namespace size/bounding
$search-simple-corner: $shape-radius !default;
/// right
/// @namespace size/margin
$search-simple-l-icon-margin-right: $S12 !default;
/// right
/// @namespace size/margin
$search-simple-m-icon-margin-right: $S8 !default;

// Normal
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-simple-normal-color: $gray-04 !default;
/// hover
/// @namespace statement/simple
$search-simple-normal-hover-color: $gray-07 !default;
/// split
/// @namespace statement/normal
$search-simple-normal-split-color: $gray-04 !default;
/// shadow
/// @namespace statement/normal
$search-simple-normal-shadow: none !default;
/// text
/// @namespace statement/icon
$search-simple-normal-icon-text-color: $gray-06 !default;
/// text
/// @namespace statement/hover
$search-simple-normal-icon-hover-text-color: $gray-07 !default;
/// border
/// @namespace size/simple
$search-simple-normal-border-width: 1px !default;
/// icon
/// @namespace size/simple
$search-simple-normal-l-icon-size: $icon-m !default;
/// icon
/// @namespace size/simple
$search-simple-normal-m-icon-size: $icon-xs !default;
/// icon-margin(r)
/// @namespace size/btn
$search-simple-normal-btn-text-padding-left: $S0 !default;

// Dark
// ----------------------------------------
/// color
/// @namespace statement/normal
$search-simple-dark-color: $gray-04 !default;
/// hover
/// @namespace statement/normal
$search-simple-dark-hover-color: $gray-04 !default;
/// background
/// @namespace statement/normal
$search-simple-dark-bg-color: $snow !default;
/// background-opacity
/// @namespace statement/normal
/// @type alpha
$search-simple-dark-bg-opacity: 0 !default;
/// text
/// @namespace statement/normal
$search-simple-dark-text-color: $snow !default;
/// shadow
/// @namespace statement/normal
$search-simple-dark-shadow: none !default;
/// text
/// @namespace statement/icon
$search-simple-dark-icon-text-color: $gray-06 !default;
/// text
/// @namespace statement/hover
$search-simple-dark-icon-hover-text-color: $gray-07 !default;
/// icon-margin(r)
/// @namespace size/btn
$search-simple-dark-btn-text-padding-left: $S0 !default;

/// split
/// @namespace statement/normal
$search-simple-dark-split-color: $gray-04 !default;

/// border
/// @namespace size/simple
$search-simple-dark-border-width: 1px !default;
/// icon
/// @namespace size/simple
$search-simple-dark-l-icon-size: $icon-m !default;
/// icon
/// @namespace size/simple
$search-simple-dark-m-icon-size: $icon-xs !default;


// new search variable
$default-placeholder-font-size: $font-size-caption;
$search-remove-icon-font-size: $S16;

$search-simple-m-height: $S36;

$search-normal-normal-m-icon-size: $S16;
$search-simple-normal-m-icon-size: $S16;
$search-simple-normal-m-icon-line-height: $font-size-body;
$search-mask-icon-line-height: $font-size-body;

$search-simple-normal-icon-text-color: $gray-05;
$search-simple-normal-icon-hover-text-color: $gray-06;
$search-simple-normal-color:$gray-04;
$search-simple-normal-hover-color: $gray-05;
$search-simple-normal-disabled-color: $gray-03;
$search-simple-active-color: $primary-brand;

$search-simple-input-padding-left: $S4;
$search-simple-m-icon-margin-left: $S8;
$search-simple-btn-text-padding-left: $S4;

$search-nav-search-font-size: $font-size-body;
$search-nav-search-corner: $S16;
$search-nav-search-height: $S32;
$search-nav-search-bg-color: $gray-01;

$search-nav-search-mask-left: $S12;
$search-nav-search-input-left: $search-nav-search-mask-left + $search-simple-normal-m-icon-size + $search-simple-m-icon-margin-left;
$search-nav-search-mask-color: $gray-05;
$search-nav-search-mask-hover-color: $gray-06;
