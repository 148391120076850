@import "../core/index-noreset.scss";

@import "scss/variable";

#{$toast-prefix} {
  @include box-sizing;

  // toast conatiner
  &-container-layout {
    width: 328px;
  }

  &-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 3000;

    &-child {
      margin-bottom: $S16;
    }

    // placement 预留样式
    &-bottomLeft {
      bottom: $S20;
      left: 0;

      #{$toast-prefix}-component {
        justify-content: flex-start;
        margin-left: $S20;
      }
    }

    &-topRight {
      right: 0;
      top: $S20;

      #{$toast-prefix}-component {
        justify-content: flex-start;
        margin-left: $S20;
      }
    }

    &-topLeft {
      left: 0;
      top: $S20;

      #{$toast-prefix}-component {
        justify-content: flex-start;
        margin-left: $S20;
      }
    }

    &-topCenter {
      display: flex;
      align-items: center;
      justify-content: center;

      top: $S20;
      width: 100%;

      pointer-events: none;
    }
  }

  // toast item
  &-component {
    position: relative;
    min-height: $S48;
    width: 296px;
    max-width: 400px;
    padding: $S16 $S12 $S8 $S16;
    line-height: $S20;
    background-color: $snow;
    margin-right: $S24; // 与 box-shadow 等宽
    border-radius: $shape-radius;
    box-sizing: border-box;
    box-shadow: $shadow-04;
    overflow: hidden;
  }

  &-solid-bar {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: $S4;
    margin: 0;
    padding: 0;
    border: 0;
    resize: none;
    outline: 0;
    background-color: transparent;

    &-is-info {
      background-color: $primary-brand;
    }
    &-is-error {
      background-color: $primary-danger;
    }
    &-is-success {
      background-color: $primary-success;
    }
    &-is-warning {
      background-color: $primary-warning;
    }
  }

  &-content {
    min-width: 0%;
  }

  &-title {
    display: flex;
    justify-content: space-between;

    .#{$css-prefix}icon-info-o-fill {
      color: $primary-brand;
    }
    .#{$css-prefix}icon-remove-o-fill {
      color: $primary-danger;
    }
    .#{$css-prefix}icon-tick-o-fill {
      color: $primary-success;
    }
    .#{$css-prefix}icon-warning-o-fill {
      color: $primary-warning;
    }
    &-text {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      padding-left: $S8;
    }
  }

  &-message {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    margin-bottom: $S8;
    color: $gray-08;
    line-height: $S20;
    font-size: $font-size-body;
    font-weight: normal;
  }

  &-description {
    width: 100%;
    margin: 0 0 $S8;
    padding: 0;
    color: $gray-06;
    font-size: $font-size-body;
  }

  &-handlers {
    min-height: $S20;
    height: auto;
    margin-bottom: $S8;
    text-align: left;
  }

  &-close-handler {
    flex-shrink: 0;

    width: $S20;
    height: $S20;
    margin-left: $S12;
    line-height: $S20;
    text-align: center;
    color: $gray-06;
    cursor: pointer;

    transform: color 218ms;

    &:hover {
      color: $gray-07;
    }
  }
}
