#{$loading-prefix}-dot-container[dir=rtl] {
  #{$loading-prefix}-dot {
    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: -160ms;
    }
    &:nth-child(3) {
      animation-delay: -320ms;
    }
  }
}
