@import '../core/index-noreset.scss';
@import 'scss/variable';
@import 'scss/normalize';
@import 'scss/mixin';
@import 'rtl.scss';

#{$card-prefix} {
  @include box-sizing;

  min-width: $S80;
  border-radius: $card-corner;
  box-shadow: $card-shadow;
  background: $card-background;
  overflow: hidden;

  &-head {
    background: $card-header-background;
    padding: 0 $card-padding-lr;
    &-main {
      position: relative;
      margin-top: $card-head-main-margin-top;
      margin-bottom: $card-head-main-margin-bottom;
    }
    &-main-box-tab {
      margin-bottom: 4px !important;
    }
  }

  &-title {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    height: 100%;
    color: $card-title-color;
    font-size: $card-title-font-size;
    font-weight: 400;
  }

  &-subtitle {
    font-size: $card-sub-title-font-size;
    color: $card-sub-title-color;
    padding-left: $card-sub-title-padding-left;
  }
  &-subtitle-box {
    padding-left: 0 !important;
    margin-top: 4px;
  }

  &-extra {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size: $card-title-extra-font-size;
    color: $card-title-extra-color;
    display: flex;
    align-items: center;
  }

  &-body-padding {
    padding-left: $card-padding-lr;
    padding-right: $card-padding-lr;
  }
  &-body {
    padding-bottom: $card-body-padding-bottom;
  }
  &-body-top {
    padding-top: $card-body-padding-bottom;
  }
  &-body-tab {
    padding-top: 0 !important;
  }
  &-show-divider {
    #{$card-prefix}-head {
      border-bottom: $card-head-bottom-border-width $card-border-style
        $card-head-bottom-border-color;
    }

    #{$card-prefix}-body {
      padding-top: $card-body-show-divider-padding-top;
    }
  }

  &-hide-divider {
    #{$card-prefix}-body {
      padding-top: $card-body-hide-divider-padding-top;
    }
  }
  &-content {
    overflow: hidden;
    transition: 0.3s height $ease-out;
    position: relative;
  }

  &-footer {
    cursor: pointer;
    text-align: center;
    padding: $card-more-btn-padding-top 0;
    font-size: $card-more-btn-font-size;
    color: $card-more-btn-color;

    .#{$css-prefix}icon {
      transition: 0.3s transform cubic-bezier(0.645, 0.045, 0.355, 1);

      &.expand {
        transform-origin: 50% 47%;
        transform: rotate(180deg);
      }
    }
  }
}
