@charset "UTF-8";

// balloon mixins
// --------------------------------------------------

@mixin balloon-bounding() {
  position: absolute;
  max-width: $balloon-size-max-width;
  border-radius: $balloon-corner;
}

@mixin balloon-state($contentColor, $colorBg, $shadow) {
  color: $contentColor;
  background-color: $colorBg;
  box-shadow: $shadow;
}

@mixin balloon-size($padding-top, $padding-right, $padding-bottom, $padding-left) {
  padding: $padding-top $padding-right $padding-bottom $padding-left;
}

@mixin balloon-arrow($width, $height, $colorBg, $shadow, $arrorExpose) {
  $shadow-top: $balloon-shadow;
  $shadow-right: $balloon-shadow;
  $shadow-bottom: $balloon-shadow;
  $shadow-left: $balloon-shadow;

  @if $shadow == none {
    $shadow-top: null;
    $shadow-right: null;
    $shadow-bottom: null;
    $shadow-left: null;
  }
  &:after {
    position: absolute;
    width: $height;
    height: $height;
    content: '';
    transform: rotate(45deg);
    box-sizing: content-box !important;
    background-color: $colorBg;
    z-index: -1;
  }
  &.#{$css-prefix}balloon-top:after {
    top: $arrorExpose;
    left: calc(50% + #{$arrorExpose});
    box-shadow: $shadow-top;
  }
  &.#{$css-prefix}balloon-right:after {
    top: calc(50% + #{$arrorExpose});
    right: $arrorExpose;
    box-shadow: $shadow-right;
  }
  &.#{$css-prefix}balloon-bottom:after {
    bottom: $arrorExpose;
    left: calc(50% + #{$arrorExpose});
    box-shadow: $shadow-bottom;
  }
  &.#{$css-prefix}balloon-left:after {
    top: calc(50% + #{$arrorExpose});
    left: $arrorExpose;
    box-shadow: $shadow-left;
  }
  &.#{$css-prefix}balloon-left-top:after {
    top: $balloon-size-arrow-margin;
    left: $arrorExpose;
    box-shadow: $shadow-left;
  }
  &.#{$css-prefix}balloon-left-bottom:after {
    bottom: $balloon-size-arrow-margin;
    left: $arrorExpose;
    box-shadow: $shadow-left;
  }

  &.#{$css-prefix}balloon-right-top:after {
    top: $balloon-size-arrow-margin;
    right: $arrorExpose;
    box-shadow: $shadow-right;
  }

  &.#{$css-prefix}balloon-right-bottom:after {
    right: $arrorExpose;
    bottom: $balloon-size-arrow-margin;
    box-shadow: $shadow-right;
  }

  &.#{$css-prefix}balloon-top-left:after {
    top: $arrorExpose;
    left: $balloon-size-arrow-margin;
    box-shadow: $shadow-top;
  }

  &.#{$css-prefix}balloon-top-right:after {
    top: $arrorExpose;
    right: $balloon-size-arrow-margin;
    box-shadow: $shadow-top;
  }

  &.#{$css-prefix}balloon-bottom-left:after {
    bottom: $arrorExpose;
    left: $balloon-size-arrow-margin;
    box-shadow: $shadow-bottom;
  }

  &.#{$css-prefix}balloon-bottom-right:after {
    right: $balloon-size-arrow-margin;
    bottom: $arrorExpose;
    box-shadow: $shadow-bottom;
  }
}

@mixin balloon-close($top, $right, $iconSize, $colorClose, $colorCloseHover) {
  .#{$css-prefix}balloon-close {
    position: absolute;
    top: $top;
    right: $right;
    font-size: $iconSize;
    .#{$css-prefix}icon {
      @include icon-square-size($iconSize);
    }
    color: $colorClose;
    :hover {
      color: $colorCloseHover;
    }
  }
}

@mixin show-hidden() {
  &.visible {
    display: block;
  }
  &.hidden {
    display: none;
  }
}

@mixin tooltip-state($contentColor, $colorBg, $shadow) {
  color: $contentColor;
  background-color: $colorBg;
  box-shadow: $shadow;
}

@mixin tooltip-arrow($width, $height, $colorBg) {
  $shadow-top: null;
  $shadow-right: null;
  $shadow-bottom: null;
  $shadow-left: null;
  &:after {
    position: absolute;
    width: $width;
    height: $height;
    content: '';
    transform: rotate(45deg);
    box-sizing: content-box !important;
    background-color: $colorBg;
    z-index: -1;
  }
  &-top:after {
    top: $balloon-size-arrow-expose;
    left: calc(50% + #{$balloon-size-arrow-expose});
    box-shadow: $shadow-top;
  }
  &-right:after {
    top: calc(50% + #{$balloon-size-arrow-expose});
    right: $balloon-size-arrow-expose;
    box-shadow: $shadow-right;
  }
  &-bottom:after {
    bottom: $balloon-size-arrow-expose;
    left: calc(50% + #{$balloon-size-arrow-expose});
    box-shadow: $shadow-bottom;
  }
  &-left:after {
    top: calc(50% + #{$balloon-size-arrow-expose});
    left: $balloon-size-arrow-expose;
    box-shadow: $shadow-left;
  }
  &-left-top:after {
    top: $balloon-size-arrow-margin;
    left: $balloon-size-arrow-expose;
    box-shadow: $shadow-left;
  }
  &-left-bottom:after {
    bottom: $balloon-size-arrow-margin;
    left: $balloon-size-arrow-expose;
    box-shadow: $shadow-left;
  }

  &-right-top:after {
    top: $balloon-size-arrow-margin;
    right: $balloon-size-arrow-expose;
    box-shadow: $shadow-right;
  }

  &-right-bottom:after {
    right: $balloon-size-arrow-expose;
    bottom: $balloon-size-arrow-margin;
    box-shadow: $shadow-right;
  }

  &-top-left:after {
    top: $balloon-size-arrow-expose;
    left: $balloon-size-arrow-margin;
    box-shadow: $shadow-top;
  }

  &-top-right:after {
    top: $balloon-size-arrow-expose;
    right: $balloon-size-arrow-margin;
    box-shadow: $shadow-top;
  }

  &-bottom-left:after {
    bottom: $balloon-size-arrow-expose;
    left: $balloon-size-arrow-margin;
    box-shadow: $shadow-bottom;
  }

  &-bottom-right:after {
    right: $balloon-size-arrow-margin;
    bottom: $balloon-size-arrow-expose;
    box-shadow: $shadow-bottom;
  }
}
