@charset "UTF-8";

////
/// @module tab: 选项卡
/// @tag Tab
/// @category component
/// @family navigation
/// @varPrefix $tab-
/// @classPrefix {prefix}-tab
/// @order {"size/nav scroll":10,"size/nav":11,"size/tab":12,"size/content":13,"statement/normal":10,"statement/normal/nav":100,"statement/normal/tab":101,"statement/normal/content":102,"statement/hover":11,"statement/hover/nav":110,"statement/hover/tab":111,"statement/selected":12,"statement/selected/nav":120,"statement/selected/tab":121,"statement/disabled":13,"statement/disabled/nav":130,"statement/disabled/tab":131}
////

// tab variables
// --------------------------------------------------

$tab-prefix: '.' + $css-prefix + 'tabs';

// animation timing function
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

// Size

/// padding (r)
/// @namespace size/nav scroll
$tab-nav-scroll-padding-right-m: $S48 !default;

/// padding (r)
/// @namespace size/nav scroll
$tab-nav-scroll-padding-right-s: $S40 !default;

/// icon
/// @namespace size/tab
$tab-nav-tab-icon-size-m: $icon-s !default;

/// icon
/// @namespace size/tab
$tab-nav-tab-icon-size-s: $icon-xs !default;

/// icon
/// @namespace size/close icon
$tab-nav-close-icon-size-m: $icon-s !default;

/// icon
/// @namespace size/close cion
$tab-nav-close-icon-size-s: $icon-xs !default;

/// padding (l)
/// @namespace size/close icon
$tab-nav-close-icon-padding-l-size-m: $S8 !default;

/// padding (l)
/// @namespace size/close icon
$tab-nav-close-icon-padding-l-size-s: $S8 !default;

/// arrow left offset (r)
/// @namespace size/nav
$tab-nav-arrow-left-positon-right: $S32 !default;

/// margin pure (t, b)
/// @namespace size/tab
$tab-pure-item-margin: $S24 !default;

/// arrow right offset (r)
/// @namespace size/nav
$tab-nav-arrow-right-positon-right: $S8 !default;

/// arrow down offset (r)
/// @namespace size/nav
$tab-nav-arrow-down-positon-right: $S8 !default;

/// padding (t, b)
/// @namespace size/tab
$tab-item-padding-tb-size-m: $S12 !default;

/// padding (t, b)
/// @namespace size/tab
$tab-item-padding-tb-size-s: $S8 !default;

/// padding (l, r)
/// @namespace size/tab
$tab-item-padding-lr-size-m: $S16 !default;

/// padding (l, r)
/// @namespace size/tab
$tab-item-padding-lr-size-s: $S12 !default;

/// text
/// @namespace size/tab
$tab-item-text-size-m: $font-size-body !default;

/// text
/// @namespace size/tab
$tab-item-text-size-s: $font-size-caption !default;

/// icon
/// @namespace statement/normal/close icon
$tab-close-icon-color: $gray-06 !default;

/// icon
/// @namespace statement/hover/close icon
$tab-close-icon-color-hover: $gray-07 !default;

/// icon
/// @namespace statement/selected/close icon
$tab-close-icon-color-selected: $gray-07 !default;

/// icon
/// @namespace statement/disabled/close icon
$tab-close-icon-color-disabled: $gray-05 !default;

/// font weight
/// @namespace statement/selected/tab
$tab-item-font-weight-selected: $font-weight-regular !default;

// Common
// ----------

/// nav icon
/// @namespace statement/normal/nav
$tab-nav-arrow-color-normal: $gray-06 !default;

/// nav icon
/// @namespace statement/hover/nav
$tab-nav-arrow-color-hover: $gray-07 !default;

/// nav icon
/// @namespace statement/active/nav
$tab-nav-arrow-color-active: $gray-08 !default;

/// nav icon
/// @namespace statement/disabled/nav
$tab-nav-arrow-color-disabled: $gray-05 !default;

// Shape: Pure
// -----------------

// Size

/// divider
/// @namespace size/nav
$tab-pure-divider-line-width: 1px !default;

/// active line
/// @namespace size/tab
$tab-pure-ink-bar-width: 2px !default;

// Statement

/// text
/// @namespace statement/normal/tab
$tab-pure-text-color-normal: $gray-06 !default;

/// background
/// @namespace statement/normal/tab
$tab-pure-bg-color: transparent !default;

/// text
/// @namespace statement/hover/tab
$tab-pure-text-color-hover: $gray-07 !default;

/// text
/// @namespace statement/selected/tab
$tab-pure-text-color-selected:  $gray-08 !default;

/// text
/// @namespace statement/disabled/tab
$tab-pure-text-color-disabled: $gray-05 !default;

/// active line
/// @namespace statement/selected/tab
$tab-pure-ink-bar-color: $primary-brand !default;

/// divider color
/// @namespace statement/normal/nav
$tab-pure-divider-border-color: $gray-03 !default;

$tab-pure-divider-border-style: solid !default;

/// divider shadow
/// @namespace statement/normal/nav
$tab-pure-divider-shadow: none !default;

// unconfigurable
// divider line
$tab-pure-divider-line: $tab-pure-divider-border-style $tab-pure-divider-line-width
  $tab-pure-divider-border-color;

// Shape: Wrapped
// -----------------

/// border style
/// @namespace size/tab
$tab-wrapped-border-line-style: solid !default;

/// border width (active)
/// @namespace size/tab
$tab-wrapped-border-line-width: 1px !default;

/// border width (normal)
/// @namespace size/tab
$tab-wrapped-border-side-width: 1px !default;

/// corner
/// @namespace size/tab
$tab-wrapped-tab-corner-radius: $shape-radius !default;

// unconfigurable
$tab-wrapped-tab-top-border-radius: $tab-wrapped-tab-corner-radius $tab-wrapped-tab-corner-radius 0
  0;

// unconfigurable
$tab-wrapped-tab-bottom-border-radius: 0 0 $tab-wrapped-tab-corner-radius
  $tab-wrapped-tab-corner-radius;

// unconfigurable
$tab-wrapped-tab-left-border-radius: $tab-wrapped-tab-corner-radius 0 0
  $tab-wrapped-tab-corner-radius;

// unconfigurable
$tab-wrapped-tab-right-border-radius: 0 $tab-wrapped-tab-corner-radius
  $tab-wrapped-tab-corner-radius 0;

/// margin (r)
/// @namespace size/tab
$tab-wrapped-tab-margin-right: $S4 !default;

/// margin (b)
/// @namespace size/tab
$tab-wrapped-tab-margin-bottom: $S4 !default;

/// active line
/// @namespace size/tab
$tab-wrapped-ink-bar-width: 2px !default;

/// background
/// @namespace statement/normal/nav
$tab-wrapped-bar-bg-color: transparent !default;

/// active line
/// @namespace statement/selected/tab
$tab-wrapped-ink-bar-color: $primary-brand !default;

/// border
/// @namespace statement/selected/tab
$tab-wrapped-nav-item-border-color-active: $primary-brand !default;

/// border
/// @namespace statement/normal/tab
$tab-wrapped-border-line-color: $gray-03 !default;

// unconfigurable
$tab-wrapped-bar-border: $tab-wrapped-border-line-style $tab-wrapped-border-line-width
  $tab-wrapped-border-line-color;

/// border width
/// @namespace size/content
$tab-wrapped-content-border-line-width: 1px !default;

/// border style
/// @namespace statement/normal/content
$tab-wrapped-content-border-line-style: solid !default;

/// border
/// @namespace statement/normal/content
$tab-wrapped-content-border-line-color: $gray-03 !default;

// content border - unconfigurable
$tab-wrapped-content-border: $tab-wrapped-content-border-line-width
  $tab-wrapped-content-border-line-style $tab-wrapped-border-line-color;

/// text
/// @namespace statement/normal/tab
$tab-wrapped-text-color: $gray-07 !default;

/// text
/// @namespace statement/hover/tab
$tab-wrapped-text-color-hover: $gray-08 !default;

/// background
/// @namespace statement/normal/tab
$tab-wrapped-bg-color: $gray-03 !default;

/// background
/// @namespace statement/hover/tab
$tab-wrapped-bg-color-hover: $gray-02 !default;

/// border
/// @namespace statement/hover/tab
$tab-wrapped-border-line-color-hover: $gray-04 !default;

/// border
/// @namespace statement/normal/tab
$tab-wrapped-border-line-color: $gray-03 !default;

/// text
/// @namespace statement/selected/tab
$tab-wrapped-text-color-selected: $primary-brand !default;

/// text
/// @namespace statement/disabled/tab
$tab-wrapped-text-color-disabled: $gray-05 !default;

/// background
/// @namespace statement/selected/tab
$tab-wrapped-bg-color-selected: $snow !default;

/// background
/// @namespace statement/disabled/tab
$tab-wrapped-bg-color-disabled: $gray-01 !default;

// unconfigurable
$tab-wrapped-content-position-top: $tab-wrapped-border-line-width;

// unconfigurable
$tab-wrapped-content-position-right: $tab-wrapped-border-line-width;

// Shape: Capsule
// -----------------

/// text
/// @namespace statement/normal/tab
$tab-capsule-text-color: $gray-08 !default;

/// text
/// @namespace statement/hover/tab
$tab-capsule-text-color-hover: $gray-08 !default;

/// text
/// @namespace statement/selected/tab
$tab-capsule-text-color-selected: $snow !default;

/// text
/// @namespace statement/disabled/tab
$tab-capsule-text-color-disabled: $gray-05 !default;

/// background
/// @namespace statement/normal/tab
$tab-capsule-bg-color: $gray-03 !default;

/// background
/// @namespace statement/hover/tab
$tab-capsule-bg-color-hover: $gray-02 !default;

/// background
/// @namespace statement/selected/tab
$tab-capsule-bg-color-selected: $primary-brand !default;

/// background
/// @namespace statement/disabled/tab
$tab-capsule-bg-color-disabled: $gray-01 !default;

/// border
/// @namespace statement/normal/tab
$tab-capsule-tab-border-line-color: $gray-04 !default;

/// border
/// @namespace statement/hover/tab
$tab-capsule-tab-border-line-color-hover: $primary-brand !default;

/// border
/// @namespace statement/selected/tab
$tab-capsule-tab-border-line-color-active: $primary-brand !default;

/// border
/// @namespace statement/disabled/tab
$tab-capsule-tab-border-line-color-disabled: $gray-04 !default;

/// corner radius
/// @namespace size/nav
$tab-capsule-corner-radius: $shape-radius !default;

/// border
/// @namespace size/nav
$tab-capsule-tab-border-line-width: 1px !default;

/// border style
/// @namespace statement/normal/tab
$tab-capsule-tab-border-line-style: solid !default;

// unconfigurable
$tab-capsule-tab-border: $tab-capsule-tab-border-line-width $tab-capsule-tab-border-line-style
  $tab-capsule-tab-border-line-color;

// Shape: Text
// -----------------

/// separator width
/// @namespace size/tab
$tab-text-item-sep-width: 1px !default;

/// separator height
/// @namespace size/tab
$tab-text-item-sep-height: $S8 !default;

/// separator
/// @namespace statement/normal/tab
$tab-text-item-sep-color: $gray-03 !default;

/// text
/// @namespace statement/normal/tab
$tab-text-text-color-normal: $gray-07 !default;

/// text
/// @namespace statement/hover/tab
$tab-text-text-color-hover: $gray-08 !default;

/// text
/// @namespace statement/selected/tab
$tab-text-text-color-selected: $primary-brand !default;

/// text
/// @namespace statement/disabled/tab
$tab-text-text-color-disabled: $gray-05 !default;
