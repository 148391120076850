@charset "UTF-8";

// breadcrumb mixins
// --------------------------------------------------

@mixin breadcrumb-bounding() {
  margin: 0;
  padding: 0;
  font-size: 0;

  .#{$css-prefix}breadcrumb-item {
    display: inline-block;

    .#{$css-prefix}breadcrumb-text {
      .#{$css-prefix}icon.#{$css-prefix}medium {
        margin-right: $S4;
        &:last-child {
          margin-right: 0;
        }
      }
      display: inline-block;
      text-decoration: none;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: color 0.2s ease-out;
      vertical-align: middle;

      > b {
        font-weight: normal;
      }
    }

    .#{$css-prefix}breadcrumb-separator {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

@mixin breadcrumb-size(
  $height,
  $minWidth,
  $fontSize,
  $ellipsisFontSize,
  $separatorSize,
  $separatorMargin
) {
  height: $height;
  line-height: $height;

  .#{$css-prefix}breadcrumb-text {
    height: $height;
    min-width: $minWidth;
    font-size: $fontSize;
    line-height: $height;
    & span {
      height: $height;
      line-height: $height;
      display: inline-block;
    }
  }

  .#{$css-prefix}breadcrumb-separator {
    height: $height;
    margin: 0 $separatorMargin;
    line-height: $height;

    .#{$css-prefix}icon {
      @include icon-size($separatorSize);
    }
  }

  .#{$css-prefix}breadcrumb-text-ellipsis {
    font-size: $ellipsisFontSize;
  }
}

@mixin breadcrumb-state(
  $textColor,
  $ellipsisTextColor,
  $currentTextColor,
  $currentTextWeight,
  $keywordTextColor,
  $iconColor,
  $textColorHover,
  $currentTextColorHover,
  $keywordTextColorHover,
  $textColorActive,
  $currentTextColorActive,
  $keywordTextColorActive
) {
  .#{$css-prefix}breadcrumb-text {
    color: $textColor;
    > b {
      color: $keywordTextColor;
    }
    > a {
      color: $textColor;
      text-decoration: none;
      text-align: center;
    }
  }

  .#{$css-prefix}breadcrumb-text.activated {
    color: $currentTextColor;
    font-weight: $currentTextWeight;
    > a {
      color: $currentTextColor;
      font-weight: $currentTextWeight;
    }
  }

  .#{$css-prefix}breadcrumb-show-more {
    color: $ellipsisTextColor;
    cursor: pointer;
    > .#{$css-prefix}icon {
      color: $textColor;
      &:hover {
        color: $textColorHover;
      }
      &:active {
        color: $textColorActive;
      }
    }
  }

  .#{$css-prefix}breadcrumb-separator {
    color: $iconColor;
  }

  .#{$css-prefix}breadcrumb-text:not(.next-breadcrumb-text-ellipsis):hover > a {
    color: $textColorHover;
  }
  .#{$css-prefix}breadcrumb-text:not(.next-breadcrumb-text-ellipsis):active > a {
    color: $textColorActive;
  }

  a.#{$css-prefix}breadcrumb-text.activated:hover > a {
    color: $currentTextColorHover;
  }
  a.#{$css-prefix}breadcrumb-text.activated:active > a {
    color: $currentTextColorActive;
  }

  a.#{$css-prefix}breadcrumb-text:not(.next-breadcrumb-text-ellipsis):hover {
    color: $textColorHover;

    > b {
      color: $keywordTextColorHover;
    }
  }
  a.#{$css-prefix}breadcrumb-text:not(.next-breadcrumb-text-ellipsis):active {
    color: $textColorActive;
  }

  a.#{$css-prefix}breadcrumb-text.activated:hover {
    color: $currentTextColorHover;
    font-weight: $currentTextWeight;
  }
  a.#{$css-prefix}breadcrumb-text.activated:active {
    color: $currentTextColorActive;
    font-weight: $currentTextWeight;
  }
}
