////
/// @module dialog: 对话框
/// @tag Dialog
/// @category component
/// @family feedback
/// @varPrefix $dialog-
/// @classPrefix {prefix}-dialog
/// @order {"size/bounding":10,"size/title":11,"size/content":12,"size/footer":13,"size/close":14,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/title":101,"statement/normal/content":102,"statement/normal/footer":103,"statement/normal/close":104,"statement/hover":11,"statement/hover/close":110}
////

// dialog variables
// --------------------------------------------------

$dialog-prefix: ".#{$css-prefix}dialog";

// Size
// ----------------------------------------

/// border width
/// @namespace size/bounding
$dialog-border-width: 1px !default;
/// text
/// @namespace size/title
$dialog-title-font-size: $font-size-title !default;
$dialog-title-line-height: $line-height-title !default;
/// padding (t)
/// @namespace size/title
$dialog-title-padding-top: $S12 !default;
/// padding (b)
/// @namespace size/title
$dialog-title-padding-bottom: $S12 !default;
/// padding (l, r)
/// @namespace size/title
$dialog-title-padding-left-right: $S20 !default;
/// divider
/// @namespace size/title
$dialog-title-border-width: 1px !default;
/// padding (t)
/// @namespace size/content
$dialog-message-content-padding-top: $S20 !default;

/// padding (b)
/// @namespace size/content
$dialog-message-content-padding-bottom: $S20 !default;

/// padding (l, r)
/// @namespace size/content
$dialog-message-content-padding-left-right: $S20 !default;
/// text
/// @namespace size/content
$dialog-content-font-size: $font-size-body !default;
/// padding (t)
/// @namespace size/content
$dialog-content-padding-top: $S20 !default;
/// padding (b)
/// @namespace size/content
$dialog-content-padding-bottom: $S20 !default;
/// padding (l, r)
/// @namespace size/content
$dialog-content-padding-left-right: $S20 !default;
/// divider
/// @namespace size/footer
$dialog-footer-border-width: 1px !default;
/// padding (t)
/// @namespace size/footer
$dialog-footer-padding-top: $S12 !default;
/// padding (b)
/// @namespace size/footer
$dialog-footer-padding-bottom: $S12 !default;
/// padding (l, r)
/// @namespace size/footer
$dialog-footer-padding-left-right: $S20 !default;
/// button spacing
/// @namespace size/footer
$dialog-footer-button-spacing: $S12 !default;
/// position (t)
/// @namespace size/close
$dialog-close-top: 10px !default;
/// position (r)
/// @namespace size/close
$dialog-close-right: $S16 !default;
/// size
/// @namespace size/close
$dialog-close-width: $S24 !default;
/// icon
/// @namespace size/close
$dialog-close-size: $icon-m !default;

$dialog-part-padding-right-closeable: $dialog-close-width + $dialog-close-right + ($dialog-close-width - $dialog-close-size) / 2;

// Statement:Normal
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$dialog-bg: $snow !default;
/// border
/// @namespace statement/normal/bounding
$dialog-border-color: $gray-03 !default;
/// border style
/// @namespace statement/normal/bounding
$dialog-border-style: solid !default;
/// corner
/// @namespace statement/normal/bounding
$dialog-corner: $shape-radius !default;
/// shadow
/// @namespace statement/normal/bounding
$dialog-shadow: $shadow-02 !default;
/// text
/// @namespace statement/normal/title
$dialog-title-color: $gray-08 !default;
/// background
/// @namespace statement/normal/title
$dialog-title-bg-color: transparent !default;
/// divider
/// @namespace statement/normal/title
$dialog-title-border-color: $gray-02 !default;
/// text
/// @namespace statement/normal/content
$dialog-content-color: $gray-07 !default;
/// background
/// @namespace statement/normal/footer
$dialog-footer-bg-color: transparent !default;
/// divider
/// @namespace statement/normal/footer
$dialog-footer-border-color: $gray-02 !default;
/// icon
/// @namespace statement/normal/close
$dialog-close-color: $gray-06 !default;

// Statement:Hover
// ----------------------------------------

/// icon
/// @namespace statement/hover/close
$dialog-close-color-hovered: $gray-08 !default;
/// background
/// @namespace statement/hover/close
$dialog-close-bg-hovered: transparent !default;
