#{$card-prefix} {
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}
