////
/// @module calendar: 日历
/// @tag Calendar
/// @category component
/// @family data-display
/// @varPrefix $calendar-
/// @classPrefix {prefix}-calendar
/// @order {"size/header":10,"size/table head":11,"size/table cell":12,"statement/normal":10,"statement/normal/table head":100,"statement/normal/table cell":101,"statement/normal/header":102,"statement/hover":11,"statement/hover/table cell":110,"statement/hover/header":111,"statement/selected":12,"statement/selected/table cell":120,"statement/current":13,"statement/current/table cell":130,"statement/other month":14,"statement/other month/table cell":140,"statement/disabled":15,"statement/disabled/table cell":150,"statement/last month":16,"statement/last month/table cell":160,"statement/in range":17,"statement/in range/table cell":170}
////

$calendar-prefix: '.' + $css-prefix + 'calendar';

$calendar-select-prefix: '.' + $css-prefix + 'select';

$calendar-radio-prefix: '.' + $css-prefix + 'radio';

$calendar-icon-prefix: '.' + $css-prefix +  'icon';

$calendar-menu-prefix: '.' + $css-prefix + 'menu';

// 全屏模式
// ----------------------------------------

/// margin (b)
/// @namespace size/header
$calendar-fullscreen-header-margin-bottom: $S8 !default;

/// font weight
/// @namespace size/table head
$calendar-fullscreen-table-head-font-weight: $font-weight-medium !default;

/// text
/// @namespace size/table head
$calendar-fullscreen-table-head-font-size: $font-size-subhead !default;

/// padding (r)
/// @namespace size/table head
$calendar-fullscreen-table-head-padding-r: $S12 !default;

/// padding (b)
/// @namespace size/table head
$calendar-fullscreen-table-head-padding-b: $S4 !default;

/// text
/// @namespace size/table cell
$calendar-fullscreen-table-cell-font-size: $font-size-body !default;

/// border (t)
/// @namespace size/table cell
$calendar-fullscreen-table-cell-boder-top-width: 2px !default;

/// margin (t, b)
/// @namespace size/table cell
$calendar-fullscreen-table-cell-margin-tb: $S0 !default;

/// margin (l, r)
/// @namespace size/table cell
$calendar-fullscreen-table-cell-margin-lr: $S4 !default;

/// padding (t, b)
/// @namespace size/table cell
$calendar-fullscreen-table-cell-padding-tb: $S4 !default;

/// padding (l, r)
/// @namespace size/table cell
$calendar-fullscreen-table-cell-padding-lr: $S8 !default;

/// min height
/// @namespace size/table cell
$calendar-fullscreen-table-cell-min-height: $S80 !default;

/// text
/// @namespace statement/normal/table head
$calendar-fullscreen-table-head-color: $gray-08 !default;

/// background
/// @namespace statement/normal/table cell
$calendar-fullscreen-table-cell-normal-background: $snow !default;

/// text
/// @namespace statement/normal/table cell
$calendar-fullscreen-table-cell-normal-color: $gray-08 !default;

/// border color
/// @namespace statement/normal/table cell
$calendar-fullscreen-table-cell-normal-border-color: $gray-03 !default;

/// background
/// @namespace statement/hover/table cell
$calendar-fullscreen-table-cell-hover-background: $highlight-brand !default;

/// text
/// @namespace statement/hover/table cell
$calendar-fullscreen-table-cell-hover-color: $gray-08 !default;

/// border color
/// @namespace statement/hover/table cell
$calendar-fullscreen-table-cell-hover-border-color: $primary-brand !default;

/// background
/// @namespace statement/selected/table cell
$calendar-fullscreen-table-cell-select-background: $highlight-brand !default;

/// text
/// @namespace statement/selected/table cell
$calendar-fullscreen-table-cell-select-color: $primary-brand !default;

/// border color
/// @namespace statement/selected/table cell
$calendar-fullscreen-table-cell-select-border-color: $primary-brand !default;

/// font weight
/// @namespace statement/selected/table cell
$calendar-fullscreen-table-cell-select-font-weight: $font-weight-medium !default;

/// background
/// @namespace statement/current/table cell
$calendar-fullscreen-table-cell-current-background: $snow !default;

/// text
/// @namespace statement/current/table cell
$calendar-fullscreen-table-cell-current-color: $primary-brand !default;

/// border color
/// @namespace statement/current/table cell
$calendar-fullscreen-table-cell-current-border-color: $primary-brand !default;

/// font weight
/// @namespace statement/current/table cell
$calendar-fullscreen-table-cell-current-font-weight: $font-weight-medium !default;

/// background
/// @namespace statement/other month/table cell
$calendar-fullscreen-table-cell-other-background: transparent !default;

/// text
/// @namespace statement/other month/table cell
$calendar-fullscreen-table-cell-other-color: $gray-05 !default;

/// border color
/// @namespace statement/other month/table cell
$calendar-fullscreen-table-cell-other-border-color: transparent !default;

/// background
/// @namespace statement/disabled/table cell
$calendar-fullscreen-table-cell-disabled-background: $gray-01 !default;

/// text
/// @namespace statement/disabled/table cell
$calendar-fullscreen-table-cell-disabled-color: $gray-05 !default;

/// border color
/// @namespace statement/disabled/table cell
$calendar-fullscreen-table-cell-disabled-border-color: $gray-04 !default;

// 卡片模式 cell
// ----------------------------------------

/// margin (b)
/// @namespace size/header
$calendar-card-header-margin-bottom: $S8 !default;

/// text
/// @namespace size/table head
$calendar-card-table-head-font-size: $font-size-body !default;

/// font weight
/// @namespace size/table head
$calendar-card-table-head-font-weight: $font-weight-regular !default;

/// text
/// @namespace size/table cell
$calendar-card-table-cell-font-size: $font-size-body !default;

/// date corner
/// @namespace size/table cell
$calendar-card-table-cell-date-border-radius: $shape-checkbox !default;

/// month corner
/// @namespace size/table cell
$calendar-card-table-cell-month-border-radius: $shape-checkbox !default;

/// year corner
/// @namespace size/table cell
$calendar-card-table-cell-year-border-radius: $shape-checkbox !default;

/// text
/// @namespace statement/normal/table head
$calendar-card-table-head-color: $gray-06 !default;

/// cell corner
/// @namespace statement/normal/table cell
$calendar-card-table-cell-corner: $R0 !default;

/// background
/// @namespace statement/normal/table cell
$calendar-card-table-cell-normal-background: $snow !default;

/// text
/// @namespace statement/normal/table cell
$calendar-card-table-cell-normal-color: $gray-07 !default;

/// border color
/// @namespace statement/normal/table cell
$calendar-card-table-cell-normal-border-color: $snow !default;

/// background
/// @namespace statement/hover/table cell
$calendar-card-table-cell-hover-background: $highlight-brand !default;

/// text
/// @namespace statement/hover/table cell
$calendar-card-table-cell-hover-color: $gray-08 !default;

/// border color
/// @namespace statement/hover/table cell
$calendar-card-table-cell-hover-border-color: $highlight-brand !default;

/// background
/// @namespace statement/selected/table cell
$calendar-card-table-cell-select-background: $primary-brand !default;

/// text
/// @namespace statement/selected/table cell
$calendar-card-table-cell-select-color: $snow !default;

/// border color
/// @namespace statement/selected/table cell
$calendar-card-table-cell-select-border-color: $primary-brand !default;

/// font weight
/// @namespace statement/selected/table cell
$calendar-card-table-cell-select-font-weight: $font-weight-medium !default;

/// background
/// @namespace statement/last month/table cell
$calendar-card-table-cell-other-background: $snow !default;

/// text
/// @namespace statement/last month/table cell
$calendar-card-table-cell-other-color: $gray-05 !default;

/// border color
/// @namespace statement/last month/table cell
$calendar-card-table-cell-other-border-color: $snow !default;

/// background
/// @namespace statement/disabled/table cell
$calendar-card-table-cell-disabled-background: $gray-01 !default;

/// text
/// @namespace statement/disabled/table cell
$calendar-card-table-cell-disabled-color: $gray-05 !default;

/// border color
/// @namespace statement/disabled/table cell
$calendar-card-table-cell-disabled-border-color: $gray-01 !default;

/// background
/// @namespace statement/current/table cell
$calendar-card-table-cell-current-background: $snow !default;

/// text
/// @namespace statement/current/table cell
$calendar-card-table-cell-current-color: $primary-brand !default;

/// border color
/// @namespace statement/current/table cell
$calendar-card-table-cell-current-border-color: transparent !default;

/// font weight
/// @namespace statement/current/table cell
$calendar-card-table-cell-current-font-weight: $font-weight-medium !default;

/// background
/// @namespace statement/in range/table cell
$calendar-card-table-cell-inrange-background: $highlight-brand !default;

/// text
/// @namespace statement/in range/table cell
$calendar-card-table-cell-inrange-color: $primary-brand !default;

/// border color
/// @namespace statement/in range/table cell
$calendar-card-table-cell-inrange-border-color: $highlight-brand !default;

// 面板模式 Panel Header
// ----------------------------------------

/// margin (b)
/// @namespace size/header
$calendar-panel-header-margin-bottom: $S8 !default;

/// height
/// @namespace size/header
$calendar-panel-header-height: $S36 !default;

/// border (b)
/// @namespace size/header
$calendar-panel-header-border-bottom-width: 1px !default;

/// background
/// @namespace statement/normal/header
$calendar-panel-header-background: $background-overlay !default;

/// border (b)
/// @namespace statement/normal/header
$calendar-panel-header-border-bottom-color: transparent !default;

// calendar btn
// --------------------

/// btn font weight
/// @namespace size/header
$calendar-btn-date-font-weight: $font-weight-medium !default;

/// btn margin (lr)
/// @namespace size/header
$calendar-btn-date-margin-lr: $S4 !default;

/// sing arrow offset (l, r)
/// @namespace size/header
$calendar-btn-arrow-single-offset-lr: $S28 !default;

/// double arrow offset (l,r )
/// @namespace size/header
$calendar-btn-arrow-double-offset-lr: $S8 !default;

/// arrow
/// @namespace statement/normal/header
$calendar-btn-arrow-color: $gray-06 !default;

/// arrow
/// @namespace statement/hover/header
$calendar-btn-arrow-color-hover: $hover-brand !default;

/// text
/// @namespace statement/normal/header
$calendar-btn-date-color: $gray-08 !default;

/// text
/// @namespace statement/hover/header
$calendar-btn-date-color-hover: $hover-brand !default;
