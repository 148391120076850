@include keyframes(fadeInLeft) {
    0% {
        opacity: 0;
        @include transform(translateX(-20px));
    }
    100% {
        opacity: 1;
        @include transform(translateX(0));
    }
}

@mixin fadeInLeft($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $ease-out-quint, $fill: $fillDefault, $visibility: $visibilityDefault) {
    @include animation-name(fadeInLeft);
    @include count($count);
    @include duration($duration);
    @include delay($delay);
    @include function($function);
    @include fill-mode($fill);
    @include visibility($visibility);
}
