// pagination mixins
// --------------------------------------------------

@mixin pagination-size(
  $direction,
  $itemSplit,
  $itemPaddingLeftRight,
  $itemBorderWidth,
  $itemCorner,
  $totalFontSize,
  $currentFontSize,
  $otherFontSize,
  $pageHeight,
  $numberPaddingLeftRight,
  $inputWidth
) {
  #{$pagination-prefix}-list {
    margin: 0 $itemSplit;
  }
  #{$pagination-prefix}-total {
    line-height: $pageHeight;
    vertical-align: middle;
  }
  #{$pagination-prefix}-item {
    border-width: $itemBorderWidth;
    border-radius: $itemCorner;
    & + #{$pagination-prefix}-item {
      @if $direction == 'rtl' {
        margin: 0 $itemSplit 0 0;
      } @else {
        margin: 0 0 0 $itemSplit;
      }
    }
  }
  #{$pagination-prefix}-ellipsis {
    height: $pageHeight;
    line-height: $pageHeight;
  }
  #{$pagination-prefix}-display {
    font-size: $totalFontSize;
    em {
      font-size: $currentFontSize;
    }
  }
  #{$pagination-prefix}-jump-text {
    font-size: $otherFontSize;
  }
  #{$pagination-prefix}-jump-input {
    width: $inputWidth;
  }
  #{$pagination-prefix}-size-selector-title {
    height: $pageHeight;
    line-height: $pageHeight;
    font-size: $otherFontSize;
    vertical-align: middle;
  }
  #{$pagination-prefix}-size-selector-btn {
    padding: 0 $numberPaddingLeftRight;
  }
  #{$pagination-prefix}-item {
    &.#{$css-prefix}prev:not([disabled]) i,
    &.#{$css-prefix}next:not([disabled]) i {
      color: $pagination-arrow-color;
      svg {
        height: $S28;
      }
    }
  }
}

@mixin arrow-only-size($prevNextWidth) {
  #{$pagination-prefix}-item {
    &.#{$css-prefix}prev,
    &.#{$css-prefix}next {
      width: $prevNextWidth;
      padding: 0;
      .#{$css-prefix}icon {
        margin: 0 auto;
        svg {
          height: $S28;
        }
      }
    }
  }
}

@mixin arrow-prev-only-size($prevWidth) {
  #{$pagination-prefix}-item.#{$css-prefix}prev {
    width: $prevWidth;
    padding: 0;
    .#{$css-prefix}icon {
      margin: 0 auto;
      svg {
        height: $S28;
      }
    }
  }
}

@mixin no-border-size($displayMarginLeftRight) {
  #{$pagination-prefix}-item {
    &.#{$css-prefix}prev,
    &.#{$css-prefix}next {
      padding: 0;
      border: none;
      background-color: transparent;
      box-shadow: none;
      .#{$css-prefix}icon {
        margin: 0;
        svg {
          height: $S28;
        }
      }
    }
  }

  #{$pagination-prefix}-display {
    margin: 0 $displayMarginLeftRight;
  }
}
