@charset "UTF-8";

////
/// @module table: 表格
/// @tag Table
/// @category component
/// @family data-display
/// @varPrefix $table-
/// @classPrefix {prefix}-table
/// @order {"size/bounding":10,"size/head":11,"size/body":12,"size/body empty":13,"size/cell":14,"size/group list":15,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/head":101,"statement/normal/group header":102,"statement/normal/body":103,"statement/normal/body empty":104,"statement/normal/group footer":105,"statement/hover":11,"statement/hover/body":110,"statement/selected":12,"statement/selected/body":120,"statement/active":13,"statement/active/head":130,"statement/disabled":14,"statement/disabled/body":140}
////

// table variables
// --------------------------------------------------

$table-prefix: '.#{$css-prefix}table' !default;

/// border width
/// @namespace size/bounding
$table-normal-border-width: 1px !default;
/// text
/// @namespace size/head
$table-th-font-size: $font-size-caption !default;
/// text
/// @namespace size/head
$table-th-line-height: $line-height-caption !default;
/// text weight
/// @namespace size/head
$table-th-font-weight: $font-weight-medium !default;
/// sort icon
/// @namespace size/head
$table-sort-icon-size: $icon-xs !default;
/// filter icon
/// @namespace size/head
$table-filter-icon-size: $icon-xs !default;
/// text
/// @namespace size/body
$table-body-font-size: $font-size-body !default;
/// text
/// @namespace line-height/body
$table-body-line-height: $line-height-body !default;
/// padding (t, b)
/// @namespace size/body empty
$table-empty-padding: $S32 !default;
/// expanded icon
/// @namespace size/body
$table-expanded-icon-size: $icon-xs !default;
/// tree icon
/// @namespace size/body
$table-tree-expanded-icon-size: $icon-xs !default;
/// padding (t, b)
/// @namespace size/cell
$table-cell-padding-top: $S8 !default;
/// padding (l, r)
/// @namespace size/cell
$table-cell-padding-left: $S20 !default;
/// padding (t, b)
/// @namespace size/header
$table-header-padding-top: $S12 !default;
/// padding (l, r)
/// @namespace size/header
$table-header-padding-left: $S20 !default;
/// margin (t, b)
/// @namespace size/group list
$table-group-split: $S8 !default;

/// border
/// @namespace statement/normal/bounding
$table-normal-border-color: $gray-03 !default;
/// border style
/// @namespace statement/normal/bounding
$table-normal-border-style: solid !default;
/// background
/// @namespace statement/normal/head
$table-th-bg: $background-section !default;
/// text
/// @namespace statement/normal/head
$table-th-color: $gray-06 !default;
/// icon
/// @namespace statement/normal/head
$table-sort-color: $gray-08 !default;
/// background
/// @namespace statement/normal/group header
$table-group-th-bg: $background-section !default;
/// text
/// @namespace statement/normal/group header
$table-group-th-color: $gray-08 !default;
/// background
/// @namespace statement/normal/body
$table-row-bg: $background-section !default;
/// text
/// @namespace statement/normal/body
$table-row-color: $gray-08 !default;
/// zebra deep color
/// @namespace statement/normal/body
$table-td-gray: $gray-01 !default;
/// zebra thin color
/// @namespace statement/normal/body
$table-td-normal: $background-section !default;
/// text
/// @namespace statement/normal/body empty
$table-empty-color: $gray-07 !default;
/// background
/// @namespace statement/normal/group footer
$table-group-footer-bg: $background-section !default;
/// text
/// @namespace statement/normal/group footer
$table-group-footer-color: $gray-08 !default;

/// background
/// @namespace statement/hover/body
$table-row-hover-bg: $gray-01 !default;
/// text
/// @namespace statement/hover/body
$table-row-hover-color: $gray-08 !default;

/// background
/// @namespace statement/selected/body
$table-row-selected-bg: $highlight-brand !default;
/// text
/// @namespace statement/selected/body
$table-row-selected-color: $gray-08 !default;

/// icon
/// @namespace statement/active/head
$table-sort-color-current: $primary-brand !default;

/// expanded icon
/// @namespace statement/disabled/body
$table-expanded-ctrl-disabled-color: $gray-06 !default;
