#{$step-prefix} {
  width: 100%;
  // overflow: hidden;
  position: relative;
  border: none;

  &-item {
    // flex: 1;
    position: relative;
    vertical-align: middle;
    outline: 0;
    height: 100%;

    &-body {
      outline: 0;
    }

    &-node {
      transition: transform 0.2s ease;
      &.clicked {
        transform: scale3d(0.8, 0.8, 0.8);
      }
    }
  }

  &-horizontal {
    // display: flex;
    overflow: hidden;
    & > #{$step-prefix}-item {
      display: inline-block;
      text-align: left;
    }
  }

  &-vertical {
    & > #{$step-prefix}-item {
      display: block;
      text-align: center;
    }
  }
}
