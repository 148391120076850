@charset "UTF-8";

// message variables
// --------------------------------------------------

////
/// @module message: 信息反馈
/// @tag  message
/// @category component
/// @family feedback
/// @varPrefix $message-
/// @classPrefix {prefix}-message
/// @order {"size/bounding":10,"size/title & content":11,"size/title":12,"size/content":13,"size/close":14,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/title":101,"statement/normal/content":102,"statement/normal/close":103,"statement/hover":11,"statement/hover/close":110}
////

// Size:Large
// ----------------------------------------

/// padding
/// @namespace size/bounding
$message-size-l-padding: $S16 !default;
/// border width
/// @namespace size/bounding
$message-size-l-border-width: 2px !default;
/// padding (l)
/// @namespace size/title & content
$message-size-l-title-content-padding-left: $S12 !default;
/// padding (r)
/// @namespace size/title & content
$message-size-l-title-content-padding-right: $S20 !default;
/// text
/// @namespace size/title
$message-size-l-title-font: $font-size-title !default;
// 新增标题字体行高
$message-size-l-title-line-height: $S20 !default;
/// margin (t)
/// @namespace size/content
$message-size-l-content-margin-top: $S4 !default;
/// text
/// @namespace size/content
$message-size-l-content-font: $font-size-body !default;
/// @namespace size/content
$message-size-l-content-line-height: $line-height-body !default;
/// margin (t)
/// @namespace size/close
$message-size-l-close-top: $S16 !default;
/// margin (l)
/// @namespace size/close
$message-size-l-close-right: $S16 !default;
/// icon
/// @namespace size/content
$message-size-l-icon: $icon-l !default;
// unconfigurable
$message-size-l-title-content-padding: 0 $message-size-l-title-content-padding-right 0 $message-size-l-title-content-padding-left + $message-size-l-icon !default;

// Size:Medium
// ----------------------------------------

/// padding
/// @namespace size/bounding
$message-size-m-padding: $S8 !default;
/// border width
/// @namespace size/bounding
$message-size-m-border-width: 1px !default;
/// padding (l)
/// @namespace size/title & content
$message-size-m-title-content-padding-left: $S8 !default;
/// padding (r)
/// @namespace size/title & content
$message-size-m-title-content-padding-right: $S20 !default;
/// text
/// @namespace size/title
$message-size-m-title-font: $font-size-subhead !default;
// 新增标题字体行高
$message-size-m-title-line-height: $S20 !default;
/// margin (t)
/// @namespace size/content
$message-size-m-content-margin-top: $S4 !default;
/// text
/// @namespace size/content
$message-size-m-content-font: $font-size-body !default;
/// text
/// @namespace line-height/content
$message-size-m-content-line-height: $line-height-body !default;
/// margin (t)
/// @namespace size/close
$message-size-m-close-top: $S12 !default;
/// margin (r)
/// @namespace size/close
$message-size-m-close-right: $S12 !default;
/// icon
/// @namespace size/content
$message-size-m-icon: $icon-m !default;
// unconfigurable
$message-size-m-title-content-padding: 0 $message-size-m-title-content-padding-right 0 $message-size-m-title-content-padding-left + $message-size-m-icon !default;

/// icon
/// @namespace size/close
$message-close-icon-size: $icon-m;

// Statement:Common
// ----------------------------------------

/// corner
/// @namespace size/bounding
$message-size-l-border-radius: $shape-radius !default;
/// corner
/// @namespace size/bounding
$message-size-m-border-radius: $shape-radius !default;
/// corner
/// @namespace size/bounding
$message-size-l-border-radius-toast: $shape-radius !default;
/// corner
/// @namespace size/bounding
$message-size-m-border-radius-toast: $shape-radius !default;
/// border style
/// @namespace statement/normal/bounding
$message-border-style: solid !default;
/// border style
/// @namespace statement/normal/bounding
$message-border-style-toast: solid !default;
/// shadow
/// @namespace statement/normal/bounding
$message-shadow-toast: $shadow-02 !default;

// Statement:Success
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-success-color-bg-inline: $highlight-success !default;
/// background
/// @namespace statement/normal/bounding
$message-success-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-success-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-success-color-border-inline: $bright-success !default;
/// border color
/// @namespace statement/normal/bounding
$message-success-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-success-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-success-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-success-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-success-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-success-color-content-addon: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-success-color-content-toast: $gray-06 !default;
/// icon
/// @namespace statement/normal/content
$message-success-color-icon-inline: $border-success !default;
/// icon
/// @namespace statement/normal/content
$message-success-color-icon-addon: $border-success !default;
/// icon
/// @namespace statement/normal/content
$message-success-color-icon-toast: $border-success !default;

// Statement:Error
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-error-color-bg-inline: $highlight-danger !default;
/// background
/// @namespace statement/normal/bounding
$message-error-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-error-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-error-color-border-inline: $bright-danger !default;
/// border color
/// @namespace statement/normal/bounding
$message-error-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-error-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-error-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-error-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-error-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-error-color-content-addon: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-error-color-content-toast: $gray-06 !default;
/// icon
/// @namespace statement/normal/content
$message-error-color-icon-inline: $primary-danger !default;
/// icon
/// @namespace statement/normal/content
$message-error-color-icon-addon: $primary-danger !default;
/// icon
/// @namespace statement/normal/content
$message-error-color-icon-toast: $primary-danger !default;

// Statement:Warning
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-warning-color-bg-inline: $highlight-warning !default;
/// background
/// @namespace statement/normal/bounding
$message-warning-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-warning-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-warning-color-border-inline: $bright-warning !default;
/// border color
/// @namespace statement/normal/bounding
$message-warning-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-warning-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-warning-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-warning-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-warning-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-warning-color-content-addon: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-warning-color-content-toast: $gray-06 !default;
/// icon
/// @namespace statement/normal/content
$message-warning-color-icon-inline: $border-warning !default;
/// icon
/// @namespace statement/normal/content
$message-warning-color-icon-addon: $border-warning !default;
/// icon
/// @namespace statement/normal/content
$message-warning-color-icon-toast: $border-warning !default;

// Statement:Notice
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-notice-color-bg-inline: $highlight-brand !default;
/// background
/// @namespace statement/normal/bounding
$message-notice-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-notice-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-notice-color-border-inline: $bright-brand !default;
/// border color
/// @namespace statement/normal/bounding
$message-notice-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-notice-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-notice-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-notice-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-notice-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-notice-color-content-addon: $gray-07 !default;
/// text
/// @namespace statement/normal/content
$message-notice-color-content-toast: $gray-07 !default;
/// icon
/// @namespace statement/normal/content
$message-notice-color-icon-inline: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-notice-color-icon-addon: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-notice-color-icon-toast: $primary-brand !default;

// Statement:Help
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-help-color-bg-inline: $highlight-brand !default;
/// background
/// @namespace statement/normal/bounding
$message-help-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-help-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-help-color-border-inline: $bright-brand !default;
/// border color
/// @namespace statement/normal/bounding
$message-help-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-help-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-help-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-help-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-help-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-help-color-content-addon: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-help-color-content-toast: $gray-06 !default;
/// icon
/// @namespace statement/normal/content
$message-help-color-icon-inline: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-help-color-icon-addon: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-help-color-icon-toast: $primary-brand !default;

// Statement:Loading
// ----------------------------------------

/// background
/// @namespace statement/normal/bounding
$message-loading-color-bg-inline: $snow !default;
/// background
/// @namespace statement/normal/bounding
$message-loading-color-bg-addon: transparent !default;
/// background
/// @namespace statement/normal/bounding
$message-loading-color-bg-toast: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-loading-color-border-inline: $snow !default;
/// border color
/// @namespace statement/normal/bounding
$message-loading-color-border-toast: $snow !default;
/// text
/// @namespace statement/normal/title
$message-loading-color-title-inline: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-loading-color-title-addon: $gray-08 !default;
/// text
/// @namespace statement/normal/title
$message-loading-color-title-toast: $gray-08 !default;
/// text
/// @namespace statement/normal/content
$message-loading-color-content-inline: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-loading-color-content-addon: $gray-06 !default;
/// text
/// @namespace statement/normal/content
$message-loading-color-content-toast: $gray-06 !default;
/// icon
/// @namespace statement/normal/content
$message-loading-color-icon-inline: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-loading-color-icon-addon: $primary-brand !default;
/// icon
/// @namespace statement/normal/content
$message-loading-color-icon-toast: $primary-brand !default;

/// icon
/// @namespace statement/normal/close
$message-close-icon-color: $gray-06 !default;
/// icon
/// @namespace statement/hover/close
$message-hover-close-icon-color: $gray-07 !default;
