////
/// @module form: 表单
/// @tag Form
/// @category component
/// @family data-entry
/// @varPrefix $form-
/// @classPrefix {prefix}-form
/// @order {"size/bounding":10,"size/item":11,"size/label":12,"size/help":13,"size/border":14,"statement/help":10,"statement/label":11,"statement/normal":12,"statement/border":13}
////

@charset "UTF-8";

// form variables
// --------------------------------------------------

$form-prefix: ".#{$css-prefix}form";

/// label padding (r)
/// @namespace size/bounding
$form-label-padding-r: $S12 !default;

/// margin (b)
/// @namespace size/item
$form-item-m-margin-b: $S16 !default;

/// margin (b)
/// @namespace size/item
$form-item-l-margin-b: $S20 !default;

/// margin (b)
/// @namespace size/item
$form-item-s-margin-b: $S12 !default;

/// margin (r)
/// @namespace size/item
$form-inline-l-item-margin-r: $S24 !default;

/// margin (r)
/// @namespace size/item
$form-inline-m-item-margin-r: $S20 !default;

/// margin (r)
/// @namespace size/item
$form-inline-s-item-margin-r: $S16 !default;

/// margin (t)
/// @namespace size/help
$form-help-margin-top: $S4 !default;

/// text
/// @namespace size/help
$form-help-font-size: $font-size-body !default;

/// text
/// @namespace statement/help
$form-help-color: $gray-06 !default;

/// error text
/// @namespace statement/error
$form-error-color: $primary-danger !default;

/// success text
/// @namespace statement/success
$form-success-color: $primary-success !default;

/// margin (b)
/// @type length
/// @namespace size/label
$form-top-label-margin-b: 2px !default;

/// text
/// @namespace statement/label
$form-label-color: $gray-07 !default;
