#{$timeline-prefix} {
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    p {
        margin: 0;
    }
}
