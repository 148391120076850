@charset "UTF-8";

@import "../core/index-noreset.scss";
@import "scss/mixin";
@import "scss/variable";

#{$form-prefix} {
  @include box-sizing;

  &-item {
    &.has-help {
      margin-bottom: 0;
    }
    &.has-error {
      margin-bottom: 0;
      #{$form-prefix}-item-help {
        color: $form-error-color;
      }
    }
    &.has-success {
      #{$form-prefix}-item-help {
        color: $form-success-color;
      }
    }

    /* medium */
    // TODO: REMOVE the lastchild's margin-bottom in 2.x version
    margin-bottom: $S20;
    position: relative;
    #{$form-prefix}-item-label, #{$form-prefix}-text-align, p {
      line-height: $form-element-medium-height;
    }
    #{$form-prefix}-text-align, p {
      margin: 0;
    }

    @include form-enhance($form-element-medium-height - 4);

    #{$form-prefix}-item-label {
      font-size: $form-element-medium-font-size;
    }
    &.#{$css-prefix}small {
      margin-bottom: $form-item-s-margin-b;

      #{$form-prefix}-item-label, #{$form-prefix}-text-align, p {
        line-height: $form-element-small-height;
      }

      @include form-enhance($form-element-small-height);

      #{$form-prefix}-item-label {
        font-size: $form-element-small-font-size;
      }
    }
    &.#{$css-prefix}top > #{$form-prefix}-item-label {
      margin-bottom: $form-top-label-margin-b;
    }
    &.#{$css-prefix}inset #{$form-prefix}-item-label {
      padding-right: 0;
      padding-left: 0;
      line-height: inherit;
    }
  }
  &-item-control {
    #{$form-prefix}-text-align {
      margin: 0;
    }
    > .#{$css-prefix}input-group, > .#{$css-prefix}input {
      width: 100%;
    }
  }
  &-item-label {
    display: inline-block;
    vertical-align: top;
    color: $form-label-color;
    text-align: right;
    padding-right: $form-label-padding-r;

    label[required]:before {
      margin-right: 4px;
      content: "*";
      color: $form-error-color;
      font-family: sans-serif;
    }

    &.#{$css-prefix}left {
      text-align: left;

      > label[required]::before {
        display: none;
      }
      > label[required]::after {
        margin-left: 4px;
        content: "*";
        color: $form-error-color;
      }
    }
  }

  &-item-extra {
    margin: $S4 0;
  }

  &-item-help {
    margin: $S4 0;
    font-size: $form-help-font-size;
    line-height: $line-height-body;
    color: $form-help-color;
    display: flex;
    &-text {
      display: inline-block;
      margin-left: $S4;
    }
  }

  &.#{$css-prefix}inline {
    #{$form-prefix}-item {
      display: inline-block;
      vertical-align: top;

      /* label on left */
      &.#{$css-prefix}left #{$form-prefix}-item-control {
        display: inline-block;
        vertical-align: top;
        line-height: 0;
      }

      &:not(:last-child) {
        margin-right: $form-inline-m-item-margin-r;
      }
      &.#{$css-prefix}small:not(:last-child) {
        margin-right: $form-inline-s-item-margin-r;
      }
    }
  }
}

@media screen and (min-width: 0\0) and (min-resolution: .001dpcm) {
  #{$form-prefix}-item.#{$css-prefix}left > #{$form-prefix}-item-label {
    display: table-cell;
  }
  #{$form-prefix}.#{$css-prefix}inline #{$form-prefix}-item.#{$css-prefix}left #{$form-prefix}-item-control {
    display: table-cell;
  }
}

#{$form-prefix}[dir="rtl"] {
  #{$form-prefix}-item-label {
    text-align: left;
    padding-left: $form-label-padding-r;
    padding-right: 0;
  }
  &.#{$css-prefix}inline {
    #{$form-prefix}-item {
      &:not(:last-child) {
        margin-left: $form-inline-m-item-margin-r;
        margin-right: 0;
      }
      &.#{$css-prefix}small:not(:last-child) {
        margin-left: $form-inline-s-item-margin-r;
        margin-right: 0;
      }
    }
  }
}
