*,
*:before,
*:after {
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-left: 0;
}

hr {
  border: 0 solid $gray-03;
  border-top-width: 1px;
}

a {
  text-decoration: none;
  &:link {
    color: $primary-brand;
  }
  &:visited {
    color: $click-brand;
  }
  &:hover {
    color: $hover-brand;
  }
  &:active {
    color: $hover-brand;
  }
}
