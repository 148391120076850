@charset "UTF-8";

// badge variables
// --------------------------------------------------

////
/// @module badge: 徽标数
/// @tag  badge
/// @category component
/// @family data-display
/// @varPrefix $badge-
/// @classPrefix {prefix}-badge
/// @order {"size/bounding":10,"size/list":11,"size/text":12,"statement/normal":10}
////

// Size
// ----------------------------------------

/// size
/// @namespace size/bounding
$badge-size-dot-width: $S8 !default;
/// margin (b)
/// @namespace size/list
$badge-size-list-margin: $S0 !default;
/// height
/// @namespace size/bounding
$badge-size-count-config-height: $S16 !default;
/// padding (lr)
/// @namespace size/bounding
$badge-size-count-padding-lr: $S4 !default;
/// padding (lr)
/// @namespace size/bounding
$badge-size-custom-padding-lr: $S4 !default;
/// size
/// @namespace size/text
$badge-size-count-font: $font-size-caption !default;

// unconfigurable
$badge-size-count-width: auto !default;
$badge-size-count-height: $badge-size-count-config-height !default;
$badge-size-count-padding: 0 $badge-size-count-padding-lr 0 $badge-size-count-padding-lr !default;
$badge-size-count-lineheight: $badge-size-count-config-height !default;
$badge-size-dot-min-width: $badge-size-dot-width !default;
$badge-size-dot-height: $badge-size-dot-width !default;
$badge-size-dot-padding: $S0 !default;

// Statement
// ----------------------------------------

/// background
/// @namespace statement/normal
$badge-color-bg: $primary-brand !default;
/// corner
/// @namespace statement/normal
$badge-size-count-border-radius: $S8 !default;
/// background
/// @namespace statement/normal
$badge-dot-color-bg: $primary-brand !default;
/// corner
/// @namespace statement/normal
$badge-size-dot-border-radius: $S8 !default;
/// corner
/// @namespace statement/normal
$badge-size-custom-border-radius: $shape-radius !default;
// unconfigurable
$badge-color: $snow !default;
$badge-dot-color: $snow !default;
