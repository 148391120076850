////
/// @module tree: 树
/// @tag Tree
/// @category component
/// @family data-display
/// @varPrefix $tree-
/// @classPrefix {prefix}-tree
/// @order {"size/node":10,"size/label":11,"size/arrow":12,"size/switch":13,"size/arrow & switch":14,"size/line":15,"statement/normal":10,"statement/normal/label":100,"statement/normal/arrow":101,"statement/normal/switch":102,"statement/normal/line":103,"statement/hover":11,"statement/hover/label":110,"statement/hover/arrow":111,"statement/hover/switch":112,"statement/selected":12,"statement/selected/label":120,"statement/disabled":13,"statement/disabled/label":130}
////

$tree-prefix: '.' + $css-prefix + 'tree';

// Size
// ----------------------------------------

/// padding (t, b)
/// @namespace size/node
$tree-node-padding: $S4 !default;

/// margin (l, r)
/// @namespace size/label
$tree-node-title-margin: $S4 !default;

/// padding (l, r)
/// @namespace size/label
$tree-node-title-padding: $S4 !default;

/// text
/// @namespace size/label
$tree-node-title-font-size: $font-size-body !default;

/// size
/// @namespace size/arrow
$tree-switch-arrow-size: $icon-xs !default;

/// size
/// @namespace size/switch
$tree-switch-size: $S16 !default;

/// icon
/// @namespace size/switch
$tree-switch-icon-size: $icon-xs !default;

/// border width
/// @namespace size/switch
$tree-switch-border-width: 1px !default;

/// margin (r)
/// @namespace size/arrow & switch
$tree-switch-margint-right: $S8 !default;

/// width
/// @namespace size/line
$tree-line-width: 2px !default;

// Statement:Normal
// ----------------------------------------

/// text
/// @namespace statement/normal/label
$tree-node-normal-color: $gray-08 !default;

/// background
/// @namespace statement/normal/label
$tree-node-normal-background: $snow !default;

/// corner
/// @namespace statement/normal/label
$tree-node-title-border-radius: $shape-radius !default;

/// color
/// @namespace statement/normal/arrow
$tree-switch-arrow-color: $gray-06 !default;

/// icon color
/// @namespace statement/normal/switch
$tree-switch-icon-color: $gray-07 !default;

/// background
/// @namespace statement/normal/switch
$tree-switch-bg-color: $snow !default;

/// border color
/// @namespace statement/normal/switch
$tree-switch-border-color: $gray-02 !default;

/// corner
/// @namespace statement/normal/switch
$tree-switch-corner: $shape-radius !default;

/// color
/// @namespace statement/normal/line
$tree-line-color: $gray-02 !default;

/// style
/// @namespace statement/normal/line
$tree-line-style: solid !default;

// Statement:Hover
// ----------------------------------------

/// text
/// @namespace statement/hover/label
$tree-node-hover-color: $gray-08 !default;

/// background
/// @namespace statement/hover/label
$tree-node-hover-bg-color: $gray-03 !default;

/// color
/// @namespace statement/hover/arrow
$tree-switch-hover-arrow-color: $gray-08 !default;

/// icon color
/// @namespace statement/hover/switch
$tree-switch-hover-icon-color: $gray-08 !default;

/// background
/// @namespace statement/hover/switch
$tree-switch-hover-bg-color: $gray-03 !default;

/// border color
/// @namespace statement/hover/switch
$tree-switch-hover-border-color: $gray-07 !default;

// Statement:Selected
// ----------------------------------------

/// text
/// @namespace statement/selected/label
$tree-node-selected-color: $gray-08 !default;

/// background
/// @namespace statement/selected/label
$tree-node-selected-background: $highlight-brand !default;

// Statement:Disabled
// ----------------------------------------

/// text
/// @namespace statement/disabled/label
$tree-node-disabled-color: $gray-05 !default;

/// background
/// @namespace statement/disabled/label
$tree-node-disabled-background: $snow !default;

$tree-child-indent: $S20 !default;
$tree-node-title-height: $S20 !default;
$tree-child-indent-left: 18px;
$tree-child-indent-right: $tree-child-indent - $tree-line-width - $tree-child-indent-left;
$tree-line: $tree-line-width $tree-line-style $tree-line-color;
