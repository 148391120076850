@charset "UTF-8";

////
/// @module range: 滑块选择
/// @tag Range
/// @category component
/// @family data-entry
/// @varPrefix $range-
/// @classPrefix {prefix}-range
/// @order {"size/bar":10,"size/scale":11,"size/handle":12,"size/mark":13,"statement/normal":10,"statement/normal/bar":100,"statement/normal/scale":101,"statement/normal/handle":102,"statement/normal/mark":103,"statement/moving":11,"statement/moving/handle":110,"statement/hover":12,"statement/hover/bar":120,"statement/hover/handle":121,"statement/hover/mark":122,"statement/disabled":13,"statement/disabled/bar":130,"statement/disabled/handle":131,"statement/disabled/mark":132}
////

// Size

/// height
/// @size medium
/// @namespace size/bar
$range-size-m-track-height: $S4 !default;

/// height
/// @size medium
/// @namespace size/scale
$range-size-m-scale-height: $S8 !default;
/// width
/// @size medium
/// @namespace size/scale
$range-size-m-scale-item-border-width: 2px !default;
/// height
/// @size medium
/// @namespace size/handle
$range-size-m-slider-hw: $S20 !default;
/// border style
/// @size medium
/// @namespace size/handle
$range-size-slider-border-style: solid !default;
/// border width
/// @size medium
/// @namespace size/handle
$range-size-slider-border-width: 2px !default;

/// distance
/// @size medium
/// @namespace size/mark
$range-size-m-mark-top: 30px !default;

/// size
/// @size medium
/// @namespace size/mark
$range-size-m-mark-font-size: $font-size-body !default;
/// weight
/// @size medium
/// @namespace size/mark
$range-size-m-mark-font-weight: $font-weight-regular !default;

/// line height
/// @size medium
/// @namespace size/mark
$range-size-m-mark-font-lineheight: 20px !default;

// Normal

/// radius
/// @level normal
/// @namespace statement/normal/bar
$range-size-m-track-radius: $shape-radius !default;
/// radius
/// @level normal
/// @namespace statement/normal/scale
$range-size-m-scale-radius: 1px !default;
/// shadow
/// @level moving
/// @namespace statement/moving/handle
$range-size-m-slider-shadow-moving: 0 0 0 $S4 $bright-brand !default;
/// unselected
/// @level normal
/// @namespace statement/normal/bar
$range-normal-unselected-color: $gray-03 !default;

/// selected
/// @level normal
/// @namespace statement/normal/bar
$range-normal-selected-color: $border-brand !default;

/// background
/// @level normal
/// @namespace statement/normal/handle
$range-normal-slider-bg-color: $snow !default;

/// unselected
/// @level normal
/// @namespace statement/normal/mark
$range-normal-mark-color: $gray-05 !default;

/// selected
/// @level normal
/// @namespace statement/normal/mark
$range-normal-mark-selected-color: $gray-05 !default;
// Hover

/// unselecter
/// @level normal
/// @namespace statement/hover/bar
$range-normal-unselected-color-hover: $gray-03 !default;

/// selected
/// @level normal
/// @namespace statement/hover/bar
$range-normal-selected-color-hover: $primary-brand !default;

/// background
/// @level normal
/// @namespace statement/hover/handle
$range-normal-slider-bg-color-hover: $snow !default;
/// shadow
/// @level normal
/// @namespace statement/normal/handle
$range-size-m-slider-shadow: $shadow-01 !default;

/// shadow
/// @level normal
/// @namespace statement/hover/handle
$range-size-m-slider-shadow-hover: $shadow-01 !default;

/// unselected
/// @level normal
/// @namespace statement/hover/mark
$range-normal-mark-color-hover: $gray-05 !default;

/// selected
/// @level normal
/// @namespace statement/hover/mark
$range-normal-mark-selected-color-hover: $gray-05 !default;

/// border
/// @size medium
/// @namespace statement/hover/handle
$range-normal-slider-border-color-hover: $primary-brand !default;

/// unselected
/// @level normal
/// @state disabled
/// @namespace statement/disabled/bar
$range-normal-unselected-color-disabled: $gray-03 !default;

/// selected
/// @level normal
/// @state disabled
/// @namespace statement/disabled/bar
$range-normal-selected-color-disabled: $gray-05 !default;

/// background
/// @level normal
/// @state disabled
/// @namespace statement/disabled/handle
$range-normal-slider-bg-color-disabled: $snow !default;
/// border
/// @size medium
/// @namespace statement/normal/handle
$range-normal-slider-border-color: $border-brand !default;
/// border
/// @size medium
/// @namespace statement/disabled/handle
$range-normal-slider-border-color-disabled: $gray-05 !default;
/// color
/// @level normal
/// @state disabled
/// @namespace statement/disabled/mark
$range-normal-mark-color-disabled: $gray-05 !default;

/// unselected
/// @level normal
/// @state disabled
/// @namespace statement/disabled/mark
$range-normal-mark-selected-color-disabled: $gray-05 !default;
