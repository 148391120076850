////
/// @module progress: 进度指示
/// @tag Progress
/// @category component
/// @family data-display
/// @varPrefix $progress-
/// @classPrefix {prefix}-progress
/// @order {"size/bounding":10,"size/text":11,"size/circle":12,"statement/normal":10,"statement/normal/underlay":100,"statement/normal/overlay":101,"statement/normal/text":102,"statement/error":11,"statement/error/overlay":110,"statement/success":12,"statement/success/overlay":120,"statement/started":13,"statement/started/overlay":130,"statement/middle":14,"statement/middle/overlay":140,"statement/finishing":15,"statement/finishing/overlay":150}
////


$progress-prefix: '.' + $css-prefix + 'progress';

$progress-easing: cubic-bezier(.65, .2, .35, 1);

// 条形
// ----------------------------------------

/// height
/// @namespace size/bounding
$progress-line-height-size-l: $S8 !default;

/// height
/// @namespace size/bounding
$progress-line-height-size-m: $S4 !default;

/// height
/// @namespace size/bounding
$progress-line-height-size-s: $S4 !default;

/// border width
/// @namespace size/bounding
$progress-line-underlay-border-width: 1px !default;

/// size
/// @namespace size/text
$progress-line-font-l: $font-size-body !default;
/// size
/// @namespace size/text
$progress-line-font-m: $font-size-caption !default;
/// size
/// @namespace size/text
$progress-line-font-s: $font-size-caption !default;

/// background
/// @namespace statement/normal/underlay
$progress-line-underlay-color: $gray-02 !default;

/// border color
/// @namespace statement/normal/underlay
$progress-line-underlay-border-color: $gray-03 !default;

/// corner
/// @namespace statement/normal/overlay
$progress-line-radius-l: 6px !default;

/// corner
/// @namespace statement/normal/overlay
$progress-line-radius-m: 6px !default;

/// corner
/// @namespace statement/normal/overlay
$progress-line-radius-s: 6px !default;

/// color
/// @namespace statement/normal/text
$progress-line-font-color: $gray-08 !default;

/// background
/// @namespace statement/normal/overlay
$progress-line-normal-color: $border-brand !default;

/// background
/// @namespace statement/error/overlay
$progress-line-error-color: $border-danger !default;

/// background
/// @namespace statement/warning/overlay
$progress-line-warning-color: $border-warning !default;

/// background
/// @namespace statement/success/overlay
$progress-line-success-color: $border-success !default;

/// background
/// @namespace statement/started/overlay
$progress-line-started-color: $border-danger !default;
/// background
/// @namespace statement/middle/overlay
$progress-line-middle-color: $border-warning !default;
/// background
/// @namespace statement/finishing/overlay
$progress-line-finishing-color: $border-success !default;

// 圆形
// ----------------------------------------

/// diameter
/// @namespace size/circle
$progress-circle-size-l: 128px !default;

/// diameter
/// @namespace size/circle
$progress-circle-size-m: 64px !default;

/// diameter
/// @namespace size/circle
$progress-circle-size-s: $S80 !default;

/// underlay width
/// @namespace size/circle
$progress-circle-underlay-width: $S8 !default;

/// overlay width
/// @namespace size/circle
$progress-circle-overlay-width: $S8 !default;

/// size
/// @namespace size/text
$progress-circle-font-l: $font-size-display-3 !default;

/// size
/// @namespace size/text
$progress-circle-font-m: $font-size-subhead !default;

/// size
/// @namespace size/text
$progress-circle-font-s: $font-size-title !default;

/// stroke color
/// @namespace statement/normal/underlay
$progress-circle-underlay-color: $gray-02 !default;

/// color
/// @namespace statement/normal/text
$progress-circle-text-color: $gray-08 !default;

/// stroke corner
/// @type ['butt', 'round', 'square']
/// @namespace statement/normal/overlay
$progress-circle-corner: round !default; // butt|round|square

/// stroke color
/// @namespace statement/normal/overlay
$progress-circle-normal-color: $border-brand !default;

/// stroke color
/// @namespace statement/error/overlay
$progress-circle-error-color: $border-danger !default;

/// stroke color
/// @namespace statement/error/overlay
$progress-circle-warning-color: $border-warning !default;

/// stroke color
/// @namespace statement/success/overlay
$progress-circle-success-color: $border-success !default;

/// stroke color
/// @namespace statement/started/overlay
$progress-circle-started-color: $border-danger !default;

/// stroke color
/// @namespace statement/middle/overlay
$progress-circle-middle-color: $border-warning !default;

/// stroke color
/// @namespace statement/finishing/overlay
$progress-circle-finishing-color: $border-success !default;
