#{$select-prefix}#{$select-prefix}-multiple[dir='rtl'] {
  #{$select-prefix}-compact {
    #{$select-prefix}-tag-compact {
      left: 0;
      right: auto;
      padding: 0 16px 0 4px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0), $input-bg-color 10px);
    }
  }
}
