@mixin next-button-size(
  $padding,
  $height,
  $font-size,
  $border-width,
  $corner,
  $icon-margin,
  $icon-size,
  $icon-split-size
) {
  border-radius: $corner;

  @include button-size($padding, $height, $font-size, $border-width);

  & > #{$btn-prefix}-icon#{$btn-icon-prefix}-first {
    margin-right: $icon-margin;
  }
  & > #{$btn-prefix}-icon#{$btn-icon-prefix}-last {
    margin-left: $icon-margin;
  }

  &#{$btn-prefix}-loading {
    padding-left: $padding + $icon-size + $icon-margin;

    &:after {
      width: $icon-size;
      height: $icon-size;
      font-size: $icon-size;
      line-height: $icon-size;
      left: $padding;
      top: 50%;
      text-align: center;
      margin-top: -$icon-size/2;
      margin-right: $icon-margin;
    }
    > #{$btn-icon-prefix} {
      display: none;
    }
  }
}

/// 设置 iconbutton 外观颜色
///
/// @param {String} $color 字体色
/// @param {String} $color-hover 鼠标悬浮时的字体色
/// @param {String} $color-active 鼠标点击时的字体色
/// @param {String} $bg-color 背景色
/// @param {String} $bg-color-hover 鼠标悬浮时的背景色
/// @param {String} $bg-color-active 鼠标悬浮时的背景色
@mixin next-iconbutton-color(
  $color,
  $color-hover,
  $color-active,
  $bg-color: transparent,
  $bg-color-hover: transparent,
  $bg-color-active: transparent
) {
  background-color: $bg-color;

  &,
  &:link,
  &:visited,
  &.visited {
    color: $color;
  }

  &:focus,
  &:hover,
  &.hover {
    color: $color-hover;
    background-color: $bg-color-hover;
    text-decoration: none;
  }

  &:active,
  &.active {
    color: $color-active;
    background-color: $bg-color-active;
    text-decoration: none;
  }
}

@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg);
  }
}
