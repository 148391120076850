@charset "UTF-8";

////
/// @module nav: 导航
/// @tag Nav
/// @category component
/// @family navigation
/// @varPrefix $nav-
/// @classPrefix {prefix}-nav
/// @order {"size/bounding":10,"size/item":11,"size/sub nav":12,"size/group":13,"size/icon":14,"statement/normal":10,"statement/normal/sub nav":100,"statement/normal/group":101,"statement/hover":11,"statement/hover/sub nav":110,"statement/selected":12,"statement/selected/sub nav":120,"statement/open":13}
////


// nav variables
// --------------------------------------------------

$nav-prefix: '.#{$css-prefix}nav';
$menu-prefix: '.#{$css-prefix}menu';

// Size:Hoz
// ----------------------------------------
/// height
/// @namespace size/bounding
$nav-hoz-height: 44px !default;
/// text
/// @namespace size/bounding
$nav-hoz-font-size: $font-size-body !default;
/// margin (t, b)
/// @namespace size/item
$nav-hoz-item-margin-tb: $S0 !default;
/// margin (l, r)
/// @namespace size/item
$nav-hoz-item-margin-lr: $S0 !default;
/// padding (l, r)
/// @namespace size/item
$nav-hoz-item-padding-lr: $S20 !default;
/// corner
/// @namespace size/item
$nav-hoz-item-corner: $R0 !default;
/// active line width
/// @namespace size/item
$nav-hoz-item-selected-active-line: 2px !default;
/// hover line width
/// @namespace size/item
$nav-hoz-item-hover-active-line: 0 !default;

// Size:Ver
// ----------------------------------------
/// height
/// @namespace size/bounding
$nav-ver-height: 44px !default;
/// text
/// @namespace size/bounding
$nav-ver-font-size: $font-size-body !default;
/// margin (t, b)
/// @namespace size/item
$nav-ver-item-margin-tb: $S0 !default;
/// margin (l, r)
/// @namespace size/item
$nav-ver-item-margin-lr: $S0 !default;
/// padding (l, r)
/// @namespace size/item
$nav-ver-item-padding-lr: $S20 !default;
/// corner
/// @namespace size/item
$nav-ver-item-corner: $R0 !default;
/// active line width
/// @namespace size/item
$nav-ver-item-selected-active-line: 2px !default;
/// hover line width
/// @namespace size/item
$nav-ver-item-hover-active-line: 0 !default;
/// height
/// @namespace size/sub nav
$nav-ver-sub-nav-height: 44px !default;
/// text
/// @namespace size/sub nav
$nav-ver-sub-nav-font-size: $font-size-body !default;
/// height
/// @namespace size/group
$nav-group-height: 44px !default;
/// text
/// @namespace size/group
$nav-group-font-size: $font-size-subhead !default;

// Size:Common
// ----------------------------------------
/// size
/// @namespace size/icon
$nav-icon-self-size: $icon-xs !default;
/// margin (r)
/// @namespace size/icon
$nav-icon-self-margin: $S4 !default;
/// size
/// @namespace size/icon
$nav-icon-only-font-size: $icon-m !default;

// Statement:Primary
// ----------------------------------------
/// background
/// @namespace statement/normal
$nav-primary-bg-color: $gray-08 !default;

/// border
/// @namespace size/normal
$nav-primary-border-width: 0 !default;

/// border
/// @namespace statement/normal
$nav-primary-border-color: $gray-08 !default;
/// text
/// @namespace statement/normal
$nav-primary-text-color: $snow !default;
/// text style
/// @namespace statement/normal
$nav-primary-text-style: $font-weight-regular !default;
/// shadow
/// @namespace statement/normal
$nav-primary-shadow: $shadow-02 !default;
/// background
/// @namespace statement/normal/sub nav
$nav-primary-sub-nav-bg-color: $gray-08 !default;
/// text
/// @namespace statement/normal/sub nav
$nav-primary-sub-nav-text-color: $snow !default;
/// text style
/// @namespace statement/normal/sub nav
$nav-primary-sub-nav-text-style: $font-weight-regular !default;
/// text
/// @namespace statement/normal/group
$nav-primary-group-text-color: $gray-06 !default;
/// text style
/// @namespace statement/normal/group
$nav-primary-group-text-style: $font-weight-regular !default;
/// background
/// @namespace statement/hover
$nav-primary-item-hover-bg-color: $shadow-color !default;
/// text
/// @namespace statement/hover
$nav-primary-item-hover-text-color: $snow !default;
/// text style
/// @namespace statement/hover
$nav-primary-item-hover-text-style: $font-weight-regular !default;
/// hover line color
/// @namespace statement/hover
$nav-primary-item-hover-active-color: $primary-brand !default;
/// background
/// @namespace statement/hover/sub nav
$nav-primary-sub-nav-hover-bg-color: $shadow-color !default;
/// text
/// @namespace statement/hover/sub nav
$nav-primary-sub-nav-hover-text-color: $snow !default;
/// background
/// @namespace statement/selected
$nav-primary-item-selected-bg-color: $shadow-color !default;
/// text color
/// @namespace statement/selected
$nav-primary-item-selected-text-color: $snow !default;
/// text style
/// @namespace statement/selected
$nav-primary-item-selected-text-style: $font-weight-medium !default;
/// active line color
/// @namespace statement/selected
$nav-primary-item-selected-active-color: $primary-brand !default;
/// background
/// @namespace statement/selected/sub nav
$nav-primary-sub-nav-selected-bg-color: $shadow-color !default;
/// text color
/// @namespace statement/selected/sub nav
$nav-primary-sub-nav-selected-text-color: $snow !default;
/// active line color
/// @namespace statement/selected/sub nav
$nav-primary-sub-nav-active-color: $primary-brand !default;
/// background
/// @namespace statement/open
$nav-primary-item-opened-bg-color: $shadow-color !default;
/// text
/// @namespace statement/open
$nav-primary-item-opened-text-color: $snow !default;

// Statement:Secondary
// ----------------------------------------
/// background
/// @namespace statement/normal
$nav-secondary-bg-color: $primary-brand !default;
/// border
/// @namespace size/normal
$nav-secondary-border-width: 0 !default;
/// border
/// @namespace statement/normal
$nav-secondary-border-color: $primary-brand !default;
/// text
/// @namespace statement/normal
$nav-secondary-text-color: $snow !default;
/// text style
/// @namespace statement/normal
$nav-secondary-text-style: $font-weight-regular !default;
/// shadow
/// @namespace statement/normal
$nav-secondary-shadow: $shadow-02 !default;
/// background
/// @namespace statement/normal/sub nav
$nav-secondary-sub-nav-bg-color: $primary-brand !default;
/// text
/// @namespace statement/normal/sub nav
$nav-secondary-sub-nav-text-color: $snow !default;
/// text style
/// @namespace statement/normal/sub nav
$nav-secondary-sub-nav-text-style: $font-weight-regular !default;
/// text
/// @namespace statement/normal/group
$nav-secondary-group-text-color: $snow !default;
/// text style
/// @namespace statement/normal/group
$nav-secondary-group-text-style: $font-weight-regular !default;
/// background
/// @namespace statement/hover
$nav-secondary-item-hover-bg-color: $hover-brand !default;
/// text
/// @namespace statement/hover
$nav-secondary-item-hover-text-color: $snow !default;
/// text style
/// @namespace statement/hover
$nav-secondary-item-hover-text-style: $font-weight-regular !default;
/// hover line color
/// @namespace statement/hover
$nav-secondary-item-hover-active-color: $hover-brand !default;
/// background
/// @namespace statement/hover/sub nav
$nav-secondary-sub-nav-hover-bg-color: $hover-brand !default;
/// text
/// @namespace statement/hover/sub nav
$nav-secondary-sub-nav-hover-text-color: $snow !default;
/// background
/// @namespace statement/selected
$nav-secondary-item-selected-bg-color: $hover-brand !default;
/// text color
/// @namespace statement/selected
$nav-secondary-item-selected-text-color: $snow !default;
/// text style
/// @namespace statement/selected
$nav-secondary-item-selected-text-style: $font-weight-medium !default;
/// active line color
/// @namespace statement/selected
$nav-secondary-item-selected-active-color: $hover-brand !default;
/// background
/// @namespace statement/selected/sub nav
$nav-secondary-sub-nav-selected-bg-color: $hover-brand !default;
/// text color
/// @namespace statement/selected/sub nav
$nav-secondary-sub-nav-selected-text-color: $snow !default;
/// active line color
/// @namespace statement/selected/sub nav
$nav-secondary-sub-nav-active-color: $primary-brand !default;
/// background
/// @namespace statement/open
$nav-secondary-item-opened-bg-color: $hover-brand !default;
/// text
/// @namespace statement/open
$nav-secondary-item-opened-text-color: $snow !default;

// Statement:Normal
// ----------------------------------------
/// background
/// @namespace statement/normal
$nav-normal-bg-color: $snow !default;
/// border
/// @namespace statement/normal
$nav-normal-border-color: $gray-03 !default;
/// text
/// @namespace statement/normal
$nav-normal-text-color: $gray-08 !default;
/// text style
/// @namespace statement/normal
$nav-normal-text-style: $font-weight-regular !default;
/// border
/// @namespace statement/normal
$nav-normal-border-color: $gray-04 !default;
/// border
/// @namespace statement/normal
$nav-normal-border-line: 1px !default;
/// shadow
/// @namespace statement/normal
$nav-normal-shadow: $shadow-02 !default;
/// background
/// @namespace statement/normal/sub nav
$nav-normal-sub-nav-bg-color: $snow !default;
/// text
/// @namespace statement/normal/sub nav
$nav-normal-sub-nav-text-color: $gray-08 !default;
/// text style
/// @namespace statement/normal/sub nav
$nav-normal-sub-nav-text-style: $font-weight-regular !default;
/// text
/// @namespace statement/normal/group
$nav-normal-group-text-color: $gray-06 !default;
/// text style
/// @namespace statement/normal/group
$nav-normal-group-text-style: $font-weight-regular !default;
/// background
/// @namespace statement/hover
$nav-normal-item-hover-bg-color: $snow !default;
/// text
/// @namespace statement/hover
$nav-normal-item-hover-text-color: $primary-brand !default;
/// text style
/// @namespace statement/hover
$nav-normal-item-hover-text-style: $font-weight-regular !default;
/// hover line color
/// @namespace statement/hover
$nav-normal-item-hover-active-color: $primary-brand !default;
/// background
/// @namespace statement/hover/sub nav
$nav-normal-sub-nav-hover-bg-color: $gray-03 !default;
/// text
/// @namespace statement/hover/sub nav
$nav-normal-sub-nav-hover-text-color: $primary-brand !default;
/// background
/// @namespace statement/selected
$nav-normal-item-selected-bg-color: $highlight-brand !default;
/// text color
/// @namespace statement/selected
$nav-normal-item-selected-text-color: $primary-brand !default;
/// text style
/// @namespace statement/selected
$nav-normal-item-selected-text-style: $font-weight-medium !default;
/// active line color
/// @namespace statement/selected
$nav-normal-item-selected-active-color: $primary-brand !default;
/// background
/// @namespace statement/selected/sub nav
$nav-normal-sub-nav-selected-bg-color: $highlight-brand !default;
/// text color
/// @namespace statement/selected/sub nav
$nav-normal-sub-nav-selected-text-color: $primary-brand !default;
/// active line color
/// @namespace statement/selected/sub nav
$nav-normal-sub-nav-active-color: $primary-brand !default;
/// background
/// @namespace statement/open
$nav-normal-item-opened-bg-color: $background-section !default;
/// text
/// @namespace statement/open
$nav-normal-item-opened-text-color: $primary-brand !default;

// Statement:Line
// ----------------------------------------
/// background
/// @namespace statement/normal
$nav-line-bg-color: transparent !default;
/// border
/// @namespace statement/normal
$nav-line-border-color: $gray-03 !default;
/// text
/// @namespace statement/normal
$nav-line-text-color: $gray-08 !default;
/// text style
/// @namespace statement/normal
$nav-line-text-style: $font-weight-regular !default;
/// border
/// @namespace statement/normal
$nav-line-border-color: $gray-03 !default;
/// border
/// @namespace statement/normal
$nav-line-border-line: 1px !default;
/// background
/// @namespace statement/normal/sub nav
$nav-line-sub-nav-bg-color: transparent !default;
/// text
/// @namespace statement/normal/sub nav
$nav-line-sub-nav-text-color: $gray-08 !default;
/// text style
/// @namespace statement/normal/sub nav
$nav-line-sub-nav-text-style: $font-weight-regular !default;
/// background
/// @namespace statement/hover
$nav-line-item-hover-bg-color: transparent !default;
/// text
/// @namespace statement/hover
$nav-line-item-hover-text-color: $primary-brand !default;
/// text style
/// @namespace statement/hover
$nav-line-item-hover-text-style: $font-weight-regular !default;
/// hover line color
/// @namespace statement/hover
$nav-line-item-hover-active-color: $primary-brand !default;
/// background
/// @namespace statement/hover/sub nav
$nav-line-sub-nav-hover-bg-color: transparent !default;
/// text
/// @namespace statement/hover/sub nav
$nav-line-sub-nav-hover-text-color: $primary-brand !default;
/// background
/// @namespace statement/selected
$nav-line-item-selected-bg-color: transparent !default;
/// text color
/// @namespace statement/selected
$nav-line-item-selected-text-color: $primary-brand !default;
/// text style
/// @namespace statement/selected
$nav-line-item-selected-text-style: $font-weight-medium !default;
/// active line color
/// @namespace statement/selected
$nav-line-item-selected-active-color: $primary-brand !default;
/// background
/// @namespace statement/selected/sub nav
$nav-line-sub-nav-selected-bg-color: transparent !default;
/// text color
/// @namespace statement/selected/sub nav
$nav-line-sub-nav-selected-text-color: $primary-brand !default;
/// active line color
/// @namespace statement/selected/sub nav
$nav-line-sub-nav-active-color: $primary-brand !default;
/// background
/// @namespace statement/open
$nav-line-item-opened-bg-color: transparent !default;
/// text
/// @namespace statement/open
$nav-line-item-opened-text-color: $primary-brand !default;
/// text
/// @namespace statement/normal/group
$nav-line-group-text-color: $gray-06 !default;
/// text style
/// @namespace statement/normal/group
$nav-line-group-text-style: $font-weight-regular !default;
