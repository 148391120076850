////
/// @module pagination: 分页器
/// @tag Pagination
/// @category component
/// @family navigation
/// @varPrefix $pagination-
/// @classPrefix {prefix}-pagination
/// @order {"size/item":10,"size/ellipsis":11,"size/display":12,"size/jump":13,"size/size selector":14,"statement/normal":10,"statement/normal/ellipsis":100,"statement/normal/display":101,"statement/normal/jump":102,"statement/normal/size selector":103,"statement/normal/arrow":104,"statement/normal/current item":105,"statement/hover/arrow":110,"statement/hover":11,"statement/hover/current item":110}
////

// pagination variables
// --------------------------------------------------

// unconfigurable
$pagination-prefix: ".#{$css-prefix}pagination";

// Size:Medium
// ----------------------------------------

/// margin (l, r)
/// @namespace size/item
$pagination-item-split: $S16 !default;

/// border width
/// @namespace size/item
$pagination-item-border-width: 0 !default;

/// corner
/// @namespace size/item
$pagination-item-corner: $shape-radius !default;

/// margin (l, r)
/// @namespace size/ellipsis
$pagination-ellipsis-margin: $S8 !default;

/// current text
/// @namespace size/display
$pagination-current-font-size: $font-size-body !default;

/// total text
/// @namespace size/display
$pagination-total-font-size: $font-size-body !default;

/// text
/// @namespace size/jump
$pagination-jump-font-size: $font-size-body !default;

/// input width
/// @namespace size/jump
$pagination-input-width: 44px !default;

/// input margin (l, r)
/// @namespace size/jump
$pagination-input-margin: $S8 !default;

/// title margin (r)
/// @namespace size/size selector
$pagination-size-selector-title-margin-right: $S4 !default;

/// number padding
/// @namespace size/size selector
$pagination-size-selector-number-padding: $S12 !default;

// Size:Large
// ----------------------------------------

/// margin (l, r)
/// @namespace size/item
$pagination-large-item-split: $S8 !default;

/// border width
/// @namespace size/item
$pagination-large-item-border-width: 1px !default;

/// corner
/// @namespace size/item
$pagination-large-item-corner: $shape-radius !default;

/// icon
/// @namespace size/ellipsis
$pagination-large-ellipsis-size: $icon-s !default;

/// current text
/// @namespace size/display
$pagination-large-current-font-size: $font-size-subhead !default;

/// total text
/// @namespace size/display
$pagination-large-total-font-size: $font-size-subhead !default;

/// input width
/// @namespace size/jump
$pagination-large-input-width: $S40 !default;

/// text
/// @namespace size/jump
$pagination-large-jump-font-size: $font-size-subhead !default;

/// number padding
/// @namespace size/size selector
$pagination-large-size-selector-number-padding: $S16 !default;

// Size:Small
// ----------------------------------------

/// margin (l, r)
/// @namespace size/item
$pagination-small-item-split: $S4 !default;

/// border width
/// @namespace size/item
$pagination-small-item-border-width: 1px !default;

/// corner
/// @namespace size/item
$pagination-small-item-corner: $shape-radius !default;

/// icon
/// @namespace size/ellipsis
$pagination-small-ellipsis-size: $icon-xs !default;

/// current text
/// @namespace size/display
$pagination-small-current-font-size: $font-size-caption !default;

/// total text
/// @namespace size/display
$pagination-small-total-font-size: $font-size-caption !default;

/// input width
/// @namespace size/jump
$pagination-small-input-width: $S28 !default;

/// text
/// @namespace size/jump
$pagination-small-jump-font-size: $font-size-caption !default;

/// number padding
/// @namespace size/size selector
$pagination-small-size-selector-number-padding: $S8 !default;

// Statement:Normal
// ----------------------------------------

/// text
/// @namespace statement/normal/ellipsis
$pagination-ellipsis-color: $gray-06 !default;

/// current text
/// @namespace statement/normal/display
$pagination-current-color: $primary-brand !default;

/// total text
/// @namespace statement/normal/display
$pagination-total-color: $gray-08 !default;

/// text
/// @namespace statement/normal/jump
$pagination-jump-color: $gray-06 !default;

/// title text
/// @namespace statement/normal/size selector
$pagination-size-selector-title-color: $gray-06 !default;

/// filter text
/// @namespace statement/normal/size selector
$pagination-size-selector-filter-color: $gray-07 !default;

/// filter current text
/// @namespace statement/normal/size selector
$pagination-size-selector-filter-current-color: $primary-brand !default;

/// text
/// @namespace statement/normal/item
$pagination-item-color: $gray-06 !default;

/// background
/// @namespace statement/normal/item
$pagination-item-bg: $snow !default;

/// text
/// @namespace statement/normal/current item
$pagination-item-current-color: $gray-08 !default;

/// background
/// @namespace statement/normal/current item
$pagination-item-current-bg: $gray-02 !default;

/// border
/// @namespace statement/hover/item
$pagination-item-hover-border-color: $gray-07 !default;

/// shadow
/// @namespace statement/hover/item
$pagination-item-hover-shadow: none !default;

/// text
/// @namespace statement/hover/item
$pagination-item-hover-color: $gray-08 !default;

/// background
/// @namespace statement/hover/item
$pagination-item-hover-bg: $gray-03 !default;

/// color
/// @namespace statement/normal/arrow
$pagination-arrow-color: $gray-07 !default;

// Statement:Hover
// ----------------------------------------

/// border
/// @namespace statement/hover/current item
$pagination-item-current-hover-border-color: transparent !default;

/// shadow
/// @namespace statement/hover/current item
$pagination-item-current-hover-shadow: none !default;

/// text
/// @namespace statement/hover/current item
$pagination-item-current-hover-color: $snow !default;

/// background
/// @namespace statement/hover/current item
$pagination-item-current-hover-bg: $hover-brand !default;

/// color
/// @namespace statement/hover/arrow
$pagination-arrow-hover-color: $gray-08 !default;

/// color
/// @namespace statement/hover/arrow
$pagination-arrow-hover-color-noboder: $primary-brand !default;
