#{$calendar-prefix} {
    @include box-sizing();

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    td,
    th {
        padding: 0;
    }
}
