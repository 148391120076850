@charset "UTF-8";

@import '../core/index-noreset';
@import 'scss/variable';
@import 'scss/mixin';
@import './rtl.scss';

/* stylelint-disable max-nesting-depth */

#{$checkbox-prefix}-wrapper {
  @include box-sizing;
  display: inline-block;
  font-size: 0;
  #{$checkbox-prefix} {
    display: inline-block;
    position: relative;
    line-height: 1;
    vertical-align: middle;
    padding: 3px;
  }

  &-medium {
    @include bounding(
      $checkbox-m-size,
      $checkbox-m-height,
      $checkbox-font-m-size,
      $checkbox-circle-m-size
    );
  }

  &-large {
    @include bounding(
      $checkbox-l-size,
      $checkbox-l-height,
      $checkbox-font-l-size,
      $checkbox-circle-l-size
    );
  }

  &.disabled {
    input[type='checkbox'] {
      cursor: not-allowed;
    }
    #{$checkbox-prefix}-inner {
      border-color: $checkbox-disabled-border-color;
      background: $checkbox-disabled-bg-color;
    }
    &.checked,
    &.indeterminate {
      #{$checkbox-prefix}-inner {
        &:hover,
        &.hovered {
          border-color: $checkbox-disabled-border-color;
        }

        > .#{$css-prefix}icon {
          color: $checkbox-disabled-circle-color;
          opacity: 1;
        }
      }
    }
    #{$checkbox-prefix}-label {
      color: $checkbox-disabled-label-color;
    }
  }

  &:not(.disabled):hover,
  &.hovered,
  &.focused {
    #{$checkbox-prefix}-inner {
      border-color: $checkbox-hovered-border-color;
      background-color: $checkbox-hovered-bg-color;
    }
    #{$checkbox-prefix}-label {
      cursor: pointer;
    }
  }

  &.indeterminate:not(.disabled):hover,
  &.indeterminate:not(.disabled).hovered,
  &.indeterminate.focused,
  &.checked:not(.disabled):hover,
  &.checked:not(.disabled).hovered,
  &.checked.focused {
    #{$checkbox-prefix}-inner {
      border-color: $checkbox-checked-hovered-border-color;
      background-color: $checkbox-checked-hovered-bg-color;

      > .#{$css-prefix}icon {
        color: $checkbox-checked-hovered-circle-color;
        opacity: 1;
      }
    }
  }
}

#{$checkbox-prefix}-group {
  display: inline-block;
  font-size: 0;
  &-medium {
    @include groupbounding($checkbox-m-height);
  }

  &-large {
    @include groupbounding($checkbox-l-height);
  }
  #{$checkbox-prefix}-wrapper {
    margin-left: $S20;
    &:first-child {
      margin-left: 0;
    }
  }

  &-ver {
    #{$checkbox-prefix}-wrapper {
      display: block;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $S16;
    }
  }
}

#{$checkbox-prefix}-label {
  color: $checkbox-label-color;
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  margin-left: $checkbox-margin-left;

  &-medium {
    @include labelbounding($checkbox-m-height, $checkbox-font-m-size);
  }

  &-large {
    @include labelbounding($checkbox-l-height, $checkbox-font-l-size);
  }
}
