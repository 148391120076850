@include keyframes(expandOutUp) {
    0% {
        opacity: 1;
        @include transform(scaleY(1));
        @include transform-origin(left top 0);
    }
    100% {
        opacity: 0;
        @include transform(scaleY(.6));
        @include transform-origin(left top 0);
    }
}

@mixin expandOutUp($count: $countDefault, $duration: .15s, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
    @include animation-name(expandOutUp);
    @include count($count);
    @include duration($duration);
    @include delay($delay);
    @include function($function);
    @include fill-mode($fill);
    @include visibility($visibility);
}
