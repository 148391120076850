@import '../core/index-noreset.scss';
@import 'scss/variable';
@import 'scss/mixin';
@import './rtl.scss';

#{$pagination-prefix} {
  @include box-sizing;
  @include clearfix;
  user-select: none;
  font-size: 0;

  &-total {
    display: inline-block;
    font-size: $font-size-body;
    line-height: $line-height-body;
    color: $gray-06;
    margin-right: 10px;
  }

  &-pages {
    display: inline-block;
  }

  &-list {
    display: inline-block;
    vertical-align: top;
  }

  &-item {
    display: inline-block;
    background: $background-section;
    color: $pagination-item-color;
    font-size: $font-size-body;
    line-height: $S28;
    width: $S28;
    height: $S28;
    border-radius: $shape-radius;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    &.#{$css-prefix}current {
      background: $pagination-item-current-bg;
      color: $pagination-item-current-color;
    }
    &.#{$css-prefix}next {
      margin-right: 18px;
    }
    &.#{$css-prefix}icon svg {
      height: $S28;
    }
    &-disabled {
      cursor: not-allowed;
      color: $gray-05;
    }
  }

  &-ellipsis {
    display: inline-block;
    color: $pagination-ellipsis-color;
    vertical-align: top;
    margin: 0 $pagination-item-split;
    &.#{$css-prefix}icon svg {
      height: $S28;
    }
  }

  &-display {
    display: inline-block;
    margin: 0 $S16;
    color: $pagination-total-color;
    vertical-align: middle;
    .#{$css-prefix}btn.#{$css-prefix}btn-secondary {
      padding: 0 6px;
      color: $gray-06;
      &:hover {
        color: $hover-brand;
      }
      &:active {
        color: $click-brand;
      }
    }
  }

  &-jump {
    &-text {
      display: inline-block;
      vertical-align: middle;
      color: $pagination-jump-color;
    }

    &-input {
      margin: 0 $pagination-input-margin;
      vertical-align: top;
    }
  }

  &-size-selector {
    display: inline-block;
    position: relative;
    margin-right: $S16;
    &-title {
      margin-right: $pagination-size-selector-title-margin-right;
      color: $pagination-size-selector-title-color;
    }
    &-dropdown {
      vertical-align: top;
      min-width: $S48;
      &-value-text {
        color: $gray-07;
        font-size: $font-size-body;
        line-height: $line-height-body;
      }
    }
    &-popup {
      min-width: $S48;
    }
    &-btn.#{$css-prefix}btn-text {
      height: initial;
      line-height: initial;
      color: $pagination-size-selector-filter-color;
      border-radius: 0;

      &.#{$css-prefix}current {
        color: $pagination-size-selector-filter-current-color;
      }

      & + #{$pagination-prefix}-size-selector-btn {
        border-left: 1px solid $gray-03;
      }
    }
  }

  &-pages + #{$pagination-prefix}-size-selector,
  &-size-selector + #{$pagination-prefix}-pages {
    margin-left: $S40;
  }

  &.#{$css-prefix}hide {
    display: none;
  }

  @include pagination-size(
    'ltr',
    $itemSplit: $pagination-item-split,
    $itemPaddingLeftRight: $S12 - 2px,
    $itemBorderWidth: $pagination-item-border-width,
    $itemCorner: $pagination-item-corner,
    $totalFontSize: $pagination-total-font-size,
    $currentFontSize: $pagination-current-font-size,
    $otherFontSize: $pagination-jump-font-size,
    $pageHeight: $form-element-xs-height,
    $numberPaddingLeftRight: $pagination-size-selector-number-padding,
    $inputWidth: $pagination-input-width
  );

  &.#{$css-prefix}arrow-only {
    @include arrow-only-size($S28);
  }

  &.#{$css-prefix}arrow-prev-only {
    @include arrow-prev-only-size($S28);
  }

  &.#{$css-prefix}no-border {
    @include no-border-size($S12);
  }
}
