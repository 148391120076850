@charset "UTF-8";

////
/// @module upload: 上传
/// @tag upload
/// @category component
/// @family data-entry
/// @varPrefix $upload-
/// @classPrefix {prefix}-upload
/// @order {"statement/disabled":10,"statement/normal":11,"statement/normal/bounding":110,"statement/normal/close":111,"statement/normal/name":112,"statement/normal/extra":113,"statement/normal/thumbnail":114,"statement/normal/picture":115,"statement/normal/add":116,"statement/hover":12,"statement/hover/bounding":120,"statement/hover/close":121,"statement/hover/title":122,"statement/hover/extra":123,"statement/hover/add":124,"statement/error":13,"statement/error/bounding":130,"statement/error/add":131,"statement/uploading":14,"statement/uploading/add":140,"size/bounding":10,"size/close icon":11,"size/picture":12,"size/border":13,"size/add":14,"size/normal":15,"size/normal/thumbnail":150,"size/name":16}
////

$upload-prefix: '.#{$css-prefix}upload';

// upload variables
// --------------------------------------------------

/// text
/// @namespace statement/disabled
$upload-disable-text-color: $gray-05;
/// border
/// @namespace statement/disabled
$upload-disable-border-color: $gray-04;

// Text
// -----------------------------------------------------
/// heigth
/// @namespace size/bounding
$upload-text-list-height: $S40 !default;
/// padding (l, r)
/// @namespace size/bounding
$upload-text-list-padding-left-right: $S8 !default;
/// text
/// @namespace size/bounding
$upload-text-list-font-size: $font-size-body !default;
/// size
/// @namespace size/close icon
$upload-text-list-close-icon-size: $icon-xs !default;
/// margin (r)
/// @namespace size/close icon
$upload-text-list-close-icon-right: $S12 !default;
/// corner
/// @namespace size/normal
$upload-text-list-corner: $R0 !default;
/// background
/// @namespace statement/normal/bounding
$upload-text-list-bg-color: $gray-03 !default;
/// background
/// @namespace statement/hover/bounding
$upload-text-list-bg-color-hover: $gray-03 !default;
/// background
/// @namespace statement/error/bounding
$upload-text-list-bg-color-error: $highlight-danger !default;
/// text
/// @namespace statement/error/bounding
$upload-text-list-error-text-color: $border-danger !default;

/// icon
/// @namespace statement/normal/close
$upload-text-list-close-icon-color: $gray-06 !default;
/// icon
/// @namespace statement/hover/close
$upload-text-list-close-icon-color-hover: $gray-07 !default;

/// text
/// @namespace statement/normal/name
$upload-text-list-name-font-color: $gray-08 !default;
/// text
/// @namespace statement/hover/title
$upload-text-list-name-font-color-hover: $primary-brand !default;
/// text
/// @namespace statement/normal/extra
$upload-text-list-size-font-color: $gray-06 !default;
/// text
/// @namespace statement/hover/extra
$upload-text-list-size-font-color-hover: $primary-brand !default;
/// height
/// @namespace size/progressbar
$upload-text-list-progressbar-height: $S8 !default;

// ============================================================

// Simple with Image
// -----------------------------------
/// padding
/// @namespace size/bounding
$upload-image-list-item-padding: $S8 !default;

/// size
/// @namespace size/picture
/// @type length
$upload-image-list-item-picture-size: $S40 !default;
/// icon
/// @namespace size/picture
$upload-image-list-item-picture-icon-size: $icon-l !default;
/// border
/// @namespace size/picture
$upload-image-list-item-picture-border-width: 1px !default;
/// corner
/// @namespace size/picture
$upload-image-list-item-picture-corner: $R0 !default;

/// font
/// @namespace statement/normal/thumbnail
$upload-image-list-item-thumbnail-font-color: $gray-05 !default;

/// text
/// @namespace size/bounding
$upload-image-list-item-font-size: $font-size-body !default;
/// size
/// @namespace size/close icon
$upload-image-list-close-icon-size: $icon-xs !default;
/// margin
/// @namespace size/close icon
$upload-image-list-close-icon-right: $S4 !default;
/// width
/// @namespace size/border
$upload-image-list-item-border-width: 1px !default;
/// background
/// @namespace statement/normal
$upload-image-list-item-bg-color: $snow !default;
/// background
/// @namespace statement/uploading
$upload-image-list-item-uploading-bg-color: $snow !default;
/// background
/// @namespace statement/error
$upload-image-list-item-error-bg-color: $snow !default;
/// icon
/// @namespace statement/normal/close
$upload-image-list-close-icon-color: $gray-06 !default;
/// icon
/// @namespace statement/hover/close
$upload-image-list-close-icon-color-hover: $gray-07 !default;
/// border
/// @namespace statement/normal/bounding
$upload-image-list-item-border-color: $gray-03 !default;
/// border
/// @namespace statement/error/bounding
$upload-image-list-item-border-color-error: $border-danger !default;
/// border
/// @namespace statement/hover/bounding
$upload-image-list-item-border-color-hover: $primary-brand !default;
/// border
/// @namespace statement/normal/picture
$upload-image-list-item-picture-border-color: $gray-03 !default;
/// background
/// @namespace statement/normal/picture
$upload-image-list-item-picture-background-color: $gray-03 !default;
/// height
/// @namespace size/progressbar
$upload-image-list-progressbar-height: $S8 !default;


// ============================================================

// Card
// -----------------------------------------------------
/// size
/// @namespace size/bounding
/// @type length
$upload-card-size: $S80 !default;
/// margin (b)
/// @namespace size/bounding
$upload-card-margin-bottom: $S16 !default;
/// icon
/// @namespace size/add
$upload-card-add-icon-size: $icon-l !default;
/// text
/// @namespace size/add
$upload-card-add-text-size: $font-size-body !default;
/// text margin (t)
/// @namespace size/add
$upload-card-add-text-margin-top: $S12 !default;
/// background
/// @namespace statement/normal
$upload-card-bg-color: $snow !default;
/// border style
/// @namespace statement/normal
$upload-card-border-style: dashed !default;
/// border
/// @namespace statement/normal
$upload-card-border-color: $gray-04 !default;
/// border
/// @namespace statement/hover/bounding
$upload-card-hover-border-color: $primary-brand !default;
/// text
/// @namespace statement/normal/add
$upload-card-font-color: $gray-07 !default;
/// color
/// @namespace statement/normal/add
$upload-card-icon-color: $gray-04 !default;
/// text
/// @namespace statement/hover/add
$upload-card-hover-font-color: $primary-brand !default;

// Card Item
/// background
/// @namespace statement/normal
$upload-card-list-bg-color: transparent !default;
/// background
/// @namespace statement/uploading/add
$upload-card-list-uploading-bg-color: $gray-01 !default;
/// background
/// @namespace statement/error/add
$upload-card-list-bg-color-error: $gray-01 !default;
/// margin (r)
/// @namespace size/bounding
$upload-card-list-margin-right: $S12 !default;
/// padding
/// @namespace size/bounding
$upload-card-list-padding: $S0 !default;
/// corner
/// @namespace size/normal
$upload-card-list-corner: $R0 !default;
/// border
/// @namespace statement/normal/bounding
$upload-card-list-border-color: $gray-04 !default;
/// border
/// @namespace statement/error/bounding
$upload-card-list-border-color-error: $border-danger !default;
/// font
/// @namespace statement/normal/thumbnail
$upload-card-list-thumbnail-font-color: $gray-05 !default;
/// text
/// @namespace statement/normal/thumbnail
$upload-card-list-thumbnail-font-size: $font-size-caption !default;
/// icon
/// @namespace statement/normal/thumbnail
$upload-card-list-thumbnail-icon-size: $icon-xxl !default;

/// margin (t)
/// @namespace size/name
$upload-card-list-name-margin-top: $S4 !default;
/// text
/// @namespace statement/normal/name
$upload-card-list-name-font-size: $font-size-caption !default;
/// text
/// @namespace statement/normal/name
$upload-card-list-name-font-color: $gray-07 !default;
/// height
/// @namespace size/progressbar
$upload-card-list-progressbar-height: $S8 !default;


// 上传后的 item 文件下载、删除操作区域的样式
// @ignore
$upload-select-card-tool-height: $S28 !default;
// @ignore
$upload-select-card-tool-icon-size: $icon-s !default;
/// color
/// @namespace statement/hover/action
$upload-select-card-tool-bg-color: $shadow-color !default;
/// opacity
/// @type alpha
/// @namespace statement/hover/action
$upload-select-card-tool-bg-opacity: .7 !default;

// ============================================================

// Drag
// -----------------------------------------------------
/// border
/// @namespace statement/normal
$upload-drag-zone-border-color: $gray-04 !default;
/// border
/// @namespace statement/normal
$upload-drag-zone-over-border-color: $primary-brand !default;
/// corner
/// @namespace statement/normal
$upload-drag-zone-corner: $shape-radius !default;
/// font
/// @namespace size/bounding
$upload-drag-zone-font-size: $font-size-body !default;
/// hint-font
/// @namespace size/bounding
$upload-drag-zone-hint-font-size: $font-size-caption !default;
/// background
/// @namespace statement/normal
$upload-drag-zone-bg-color: transparent !default;
/// icon-color
/// @namespace statement/normal
$upload-drag-zone-upload-icon-color: $gray-07 !default;
/// title
/// @namespace statement/normal
$upload-drag-zone-upload-normal-title-color: $gray-07 !default;
/// hint
/// @namespace statement/normal
$upload-drag-zone-upload-normal-hint-color: $gray-06 !default;
