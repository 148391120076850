////
/// @module step: 步骤
/// @tag Step
/// @category component
/// @family navigation
/// @varPrefix $step-
/// @classPrefix {prefix}-step
/// @order {"size/bounding":10,"size/text":11,"size/node":12,"size/divider":13,"size/content":14,"size/dot":15,"statement/awaiting":10,"statement/awaiting/arrow":100,"statement/awaiting/node":101,"statement/awaiting/divider":102,"statement/awaiting/content":103,"statement/processing":11,"statement/processing/arrow":110,"statement/processing/node":111,"statement/processing/divider":112,"statement/processing/content":113,"statement/finished":12,"statement/finished/arrow":120,"statement/finished/node":121,"statement/finished/divider":122,"statement/finished/content":123,"statement/disabled":13,"statement/disabled/arrow":130,"statement/disabled/node":131,"statement/disabled/divider":132,"statement/disabled/content":133,"statement/normal":14,"statement/normal/content":140}
////

$step-prefix: '.' + $css-prefix + 'step';

$step-icon-prefix: '.' + $css-prefix + 'icon';

$step-progress-prefix: '.' + $css-prefix + 'progress';


// 箭头变量
// ----------------------------------------

/// height
/// @namespace size/bounding
$step-arrow-item-height: $S32 !default;
$step-arrow-item-border-width: $step-arrow-item-height/2;

/// font size
/// @namespace size/text
$step-arrow-item-title-size: $font-size-body !default;

/// font weight
/// @namespace size/text
$step-arrow-item-title-weight: $font-weight-medium !default;

/// background
/// @namespace statement/awaiting/arrow
$step-arrow-item-wait-background: $gray-02 !default;

/// text
/// @namespace statement/awaiting/arrow
$step-arrow-item-title-wait-color: $gray-06 !default;

/// background
/// @namespace statement/processing/arrow
$step-arrow-item-process-background: $primary-brand !default;

/// text
/// @namespace statement/processing/arrow
$step-arrow-item-title-process-color: $snow !default;

/// background
/// @namespace statement/finished/arrow
$step-arrow-item-finish-background: $highlight-brand !default;

/// text
/// @namespace statement/finished/arrow
$step-arrow-item-title-finish-color: $primary-brand !default;

/// background
/// @namespace statement/disabled/arrow
$step-arrow-item-disabled-background: $gray-01 !default;

/// text
/// @namespace statement/disabled/arrow
$step-arrow-item-title-disabled-color: $gray-05 !default;

// 圆形变量
// ----------------------------------------

/// margin (l, r)
/// @namespace size/node
$step-circle-item-node-padding: $S8 !default;

/// border width
/// @namespace size/node
$step-circle-item-node-border-width: 1px !default;

/// icon
/// @namespace size/node
$step-circle-item-node-icon-size: $icon-m !default;

/// text
/// @namespace size/node
$step-circle-item-node-font-size: $font-size-body !default;

/// corner
/// @namespace size/node
$step-circle-item-node-corner: $shape-circular !default;

/// width
/// @namespace size/divider
$step-circle-item-tail-size: 1px !default;

/// title margin (t)
/// @namespace size/content
$step-circle-item-title-margin-top: $S8 !default;

/// body margin (t)
/// @namespace size/content
$step-circle-item-content-margin-top: $S4 !default;

/// content size
/// @namespace size/content
$step-circle-item-content-font-size: $font-size-caption !default;

/// body width
/// @namespace size/content
$step-circle-item-body-width: $S80 !default;

/// title size
/// @namespace size/content
$step-circle-item-title-size: $font-size-body !default;
/// title line-height
/// @namespace line-height/content
$step-circle-item-title-line-height: $line-height-body !default;
/// title weight
/// @namespace size/content
$step-circle-item-title-weight: $font-weight-medium !default;

/// node size
/// @namespace statement/awaiting/node
$step-circle-item-node-wait-size: $S24 !default;

/// node size
/// @namespace statement/processing/node
$step-circle-item-node-process-size: $S24 !default;

/// node size
/// @namespace statement/finished/node
$step-circle-item-node-finish-size: $S24 !default;

/// node size
/// @namespace statement/disabled/node
$step-circle-item-node-disabled-size: $S32 !default;

/// line color
/// @namespace statement/awaiting/divider
$step-circle-item-tail-wait-color: $gray-05 !default;

/// line color
/// @namespace statement/processing/divider
$step-circle-item-tail-process-color: $gray-05 !default;

/// line color
/// @namespace statement/finished/divider
$step-circle-item-tail-finish-color: $primary-brand !default;

/// line color
/// @namespace statement/disabled/divider
$step-circle-item-tail-disabled-color: $gray-05 !default;

/// font weight
/// @namespace statement/awaiting/content
$step-circle-item-node-wait-font-weight: $font-weight-regular !default;

/// font weight
/// @namespace statement/processing/content
$step-circle-item-node-process-font-weight: $font-weight-medium !default;

/// font weight
/// @namespace statement/disabled/content
$step-circle-item-node-disabled-font-weight: $font-weight-regular !default;

/// font weight
/// @namespace statement/finished/content
$step-circle-item-node-finish-font-weight: $font-weight-regular !default;

/// title text
/// @namespace statement/awaiting/title
$step-circle-item-title-wait-color: $gray-06 !default;

/// title text
/// @namespace statement/awaiting/title
$step-circle-item-title-wait-hover-color: $primary-brand !default;

/// title text
/// @namespace statement/processing/title
$step-circle-item-title-process-color: $gray-08 !default;

/// title text
/// @namespace statement/processing/title
$step-circle-item-title-process-hover-color: $gray-08 !default;

/// title text
/// @namespace statement/finished/title
$step-circle-item-title-finish-color: $gray-07 !default;

/// title text
/// @namespace statement/finished/title
$step-circle-item-title-finish-hover-color: $primary-brand !default;

/// title text
/// @namespace statement/disabled/title
$step-circle-item-title-disabled-color: $gray-05 !default;
/// title text
/// @namespace statement/disabled/title
$step-circle-item-title-disabled-hover-color: $gray-05 !default;

/// content text
/// @namespace statement/awaiting/content
$step-circle-item-content-wait-color: $gray-06 !default;

/// content text
/// @namespace statement/processing/content
$step-circle-item-content-process-color: $gray-06 !default;

/// content text
/// @namespace statement/finished/content
$step-circle-item-content-finish-color: $gray-06 !default;

/// content text
/// @namespace statement/disabled/content
$step-circle-item-content-disabled-color: $gray-05 !default;

/// border color
/// @namespace statement/awaiting/node
$step-circle-item-node-wait-border-color: $gray-07 !default;

/// background
/// @namespace statement/awaiting/node
$step-circle-item-node-wait-background: $gray-03 !default;

/// text
/// @namespace statement/awaiting/node
$step-circle-item-node-wait-color: $gray-07 !default;

/// border color
/// @namespace statement/processing/node
$step-circle-item-node-process-border-color: $primary-brand !default;

/// background
/// @namespace statement/processing/node
$step-circle-item-node-process-background: $primary-brand !default;

/// text
/// @namespace statement/processing/node
$step-circle-item-node-process-color: $snow !default;

/// percent text
/// @namespace statement/processing/node
$step-circle-item-node-process-percent-color: $primary-brand !default;

/// percent size
/// @namespace size/node
$step-circle-item-node-process-percent-size: $font-size-caption !default;

/// border color
/// @namespace statement/finished/node
$step-circle-item-node-finish-border-color: $primary-brand !default;

/// background
/// @namespace statement/finished/node
$step-circle-item-node-finish-background: $bright-brand !default;

/// text
/// @namespace statement/finished/node
$step-circle-item-node-finish-color: $primary-brand !default;

/// border color
/// @namespace statement/disabled/node
$step-circle-item-node-disabled-border-color: $gray-04 !default;

/// background
/// @namespace statement/disabled/node
$step-circle-item-node-disabled-background: $gray-02 !default;

/// text
/// @namespace statement/disabled/node
$step-circle-item-node-disabled-color: $gray-05 !default;

// 圆形变量 (垂直)
// ----------------------------------------

/// node margin (t, b)
/// @namespace size/node
$step-circle-vertical-item-node-padding: $S8 !default;

/// margin (l)
/// @namespace size/content
$step-circle-vertical-item-body-margin-left: $S16 !default;

/// title margin (t)
/// @namespace size/content
$step-circle-vertical-item-title-margin-top: 2px !default;

/// body margin (t)
/// @namespace size/content
$step-circle-vertical-item-content-margin-top: $S4 !default;

/// body size
/// @namespace size/content
$step-circle-item-content-font-size: $font-size-caption !default;

/// text
/// @namespace statement/normal/content
$step-circle-item-content-color: $gray-07 !default;

/// tail bg color
/// @namespace statement/normal/content
$step-circle-item-tail-bg-color: $gray-04 !default;

/// width
/// @namespace size/divider
$step-circle-vertical-item-tail-size: 1px !default;

// 点型变量
// ----------------------------------------

/// margin (l, r)
/// @namespace size/dot
$step-dot-item-dot-padding: $S8 !default;

/// size
/// @namespace size/dot
// $step-dot-item-dot-size: $S12 !default;

/// icon
/// @namespace size/dot
$step-dot-item-dot-icon-size: $icon-xs !default;

/// border width
/// @namespace size/dot
$step-dot-item-dot-border-width: 1px !default;

/// body width
/// @namespace size/content
$step-dot-item-body-width: $S80 !default;

/// content margin (t)
/// @namespace size/content
$step-dot-item-content-margin-top: $S4 !default;

/// corner
/// @namespace size/dot
$step-dot-item-dot-corner: $shape-circular !default;

/// title size
/// @namespace size/content
$step-dot-item-title-size: $font-size-body !default;

/// title weight
/// @namespace size/content
$step-dot-item-title-weight: $font-weight-medium !default;

/// title margin (t)
/// @namespace size/content
$step-dot-item-title-margin-top: $S8 !default;

/// width
/// @namespace size/divider
$step-dot-item-tail-size: 1px !default;

/// node size
/// @namespace statement/awaiting/node
$step-dot-item-dot-wait-size: $S12 !default;

/// node size
/// @namespace statement/processing/node
$step-dot-item-dot-process-size: $S12 !default;

/// node size
/// @namespace statement/finished/node
$step-dot-item-dot-finish-size: $S12 !default;

/// node size
/// @namespace statement/disabled/node
$step-dot-item-dot-disabled-size: $S12 !default;

/// line color
/// @namespace statement/awaiting/divider
$step-dot-item-tail-wait-color: $gray-04 !default;

/// line color
/// @namespace statement/processing/divider
$step-dot-item-tail-process-color: $gray-04 !default;

/// line color
/// @namespace statement/finished/divider
$step-dot-item-tail-finish-color: $primary-brand !default;

/// line color
/// @namespace statement/disabled/divider
$step-dot-item-tail-disabled-color: $gray-04 !default;

/// title text
/// @namespace statement/awaiting/content
$step-dot-item-title-wait-color: $gray-06 !default;

/// title text
/// @namespace statement/awaiting/content
$step-dot-item-title-wait-hover-color: $primary-brand !default;

/// title text
/// @namespace statement/processing/content
$step-dot-item-title-process-color: $gray-08 !default;

/// title text
/// @namespace statement/processing/content
$step-dot-item-title-process-hover-color: $gray-08 !default;

/// title text
/// @namespace statement/finished/content
$step-dot-item-title-finish-color: $gray-07 !default;

/// title text
/// @namespace statement/finished/content
$step-dot-item-title-finish-hover-color: $primary-brand !default;

/// title text
/// @namespace statement/disabled/content
$step-dot-item-title-disabled-color: $gray-05 !default;

/// title text
/// @namespace statement/disabled/content
$step-dot-item-title-disabled-hover-color: $gray-05 !default;

/// content text
/// @namespace statement/awaiting/content
$step-dot-item-content-wait-color: $gray-06 !default;

/// title text
/// @namespace statement/processing/content
$step-dot-item-content-process-color: $gray-06 !default;

/// title text
/// @namespace statement/finished/content
$step-dot-item-content-finish-color: $gray-06 !default;

/// title text
/// @namespace statement/disabled/content
$step-dot-item-content-disabled-color: $gray-05 !default;

/// border color
/// @namespace statement/awaiting/node
$step-dot-item-node-wait-border-color: $gray-07 !default;

/// background
/// @namespace statement/awaiting/node
$step-dot-item-node-wait-background: $snow !default;

/// text
/// @namespace statement/awaiting/node
$step-dot-item-node-wait-color: $gray-06 !default;

/// border color
/// @namespace statement/processing/node
$step-dot-item-node-process-border-color: $primary-brand !default;

/// background
/// @namespace statement/processing/node
$step-dot-item-node-process-background: $primary-brand !default;

/// text
/// @namespace statement/processing/node
$step-dot-item-node-process-color: $primary-brand !default;

/// border color
/// @namespace statement/finished/node
$step-dot-item-node-finish-border-color: $primary-brand !default;

/// background
/// @namespace statement/finished/node
$step-dot-item-node-finish-background: $snow !default;

/// text
/// @namespace statement/finished/node
$step-dot-item-node-finish-color: $primary-brand !default;

/// border color
/// @namespace statement/disabled/node
$step-dot-item-node-disabled-border-color: $gray-03 !default;

/// background
/// @namespace statement/disabled/node
$step-dot-item-node-disabled-background: $snow !default;

/// text
/// @namespace statement/disabled/node
$step-dot-item-node-disabled-color: $gray-04 !default;

// 点型变量 (垂直)
// ----------------------------------------

/// margin (t, b)
/// @namespace size/node
$step-dot-vertical-item-node-padding: $S8 !default;

/// margin (l)
/// @namespace size/content
$step-dot-vertical-item-body-margin-left: $S16 !default;

/// title margin (t)
/// @namespace size/content
$step-dot-vertical-item-title-margin-top: -$S4 !default;

/// body margin (t)
/// @namespace size/content
$step-dot-vertical-item-content-margin-top: $S8 !default;

/// body size
/// @namespace size/content
$step-dot-item-content-font-size: $font-size-caption !default;

/// text
/// @namespace statement/normal/content
$step-dot-item-content-color: $gray-07 !default;

/// tail bg color
/// @namespace statement/normal/content
$step-dot-item-tail-bg-color: $gray-04 !default;

/// width
/// @namespace size/divider
$step-dot-vertical-item-tail-size: 1px !default;
