@charset "UTF-8";

@import "../core/index-noreset.scss";
@import "../animate/scss/mixin.scss";
@import "scss/mixin";
@import "scss/variable";
@import "./rtl.scss";

/* put your code here */

#{$loading-prefix} {
  position: relative;
  min-height: 48px;

  &.#{$css-prefix}open {
    pointer-events: none;
  }

  /* 遮罩层 */
  #{$loading-prefix}-component {
    opacity: .7;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    filter: "progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false)"; /* IE6~IE9 */
    position: relative;
    pointer-events: none;
  }
  /* for IE9,10 */
  &-masker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    opacity: .2;
    background: #fff;
  }

  &-inline {
    display: inline-block;
  }

  &-tip {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &-tip-fullscreen {
    top: inherit;
    left: inherit;
    transform: inherit;
    box-shadow: none !important;
  }

  &-tip-placeholder {
    display: none;
  }

  /* text on the right side of */
  &-right-tip {
    #{$loading-prefix}-indicator {
      display: inline-block;
    }
    #{$loading-prefix}-tip-content {
      position: absolute;
      display: block;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    #{$loading-prefix}-tip-placeholder {
      display: inline-block;
      visibility: hidden;
      margin-left: 1em;
    }
  }
  /* 动效 */
  &-dot-container {
    display: inline-block;
    position: relative;
    margin: 0;

    #{$loading-prefix}-dot {
      position: absolute;
      margin: auto;
      border-radius: 50%;
      background: $loading-dot-color;

      @include duration($loading-duration-seconds);
      @include count(infinite);
      @include function(ease-in-out);
      @include animation-name(wave);

      &:nth-child(1) {
        top: 0;
        bottom: 0;
        left: 0;
        animation-delay: -320ms;
      }
      &:nth-child(2) {
        left: 0;
        right: 0;
        top: 0;
        animation-delay: -160ms;
      }
      &:nth-child(3) {
        top: 0;
        bottom: 0;
        right: 0;
        animation-delay: 0ms;
      }
    }
  }

  &-dot-container-large {

    width: $loading-large-dot-container-width;
    height: $loading-large-dot-container-height;

    #{$loading-prefix}-dot {
      width: $loading-large-dot-size;
      height: $loading-large-dot-size;
    }
  }
  &-dot-container-medium {

    width: $loading-medium-dot-container-width;
    height: $loading-medium-dot-container-height;

    #{$loading-prefix}-dot {
      width: $loading-medium-dot-size;
      height: $loading-medium-dot-size;
    }
  }
}

