@import '~@alife/at-theme/teambition/scss/index.scss';

/* normalize,reset样式 */
@import '../components/core/base/_normalize.scss';
@import '../components/core/base/_reset.scss';
/* 设置全局背景色及字体 */
body {
  background-color: $background-layout;
  font-family: $font-family;
}

@import '../components/index.scss';
