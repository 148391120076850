@charset "UTF-8";

@import '../core/index-noreset.scss';
@import 'scss/variable';
@import 'scss/mixin';

.#{$css-prefix}balloon {
  @include box-sizing;
  @include balloon-bounding();

  font-size: $balloon-content-font-size;
  font-weight: $balloon-content-font-weight;
  word-wrap: break-word;
  z-index: 0;
  &:focus,
  & *:focus {
    outline: 0;
  }

  &-primary {
    @include balloon-state(
      $balloon-primary-color,
      $balloon-primary-color-bg,
      $balloon-primary-shadow,
    );
    @include balloon-close(
      $balloon-size-close-margin-top,
      $balloon-size-close-margin-right,
      $balloon-size-close,
      $balloon-primary-color-close,
      $balloon-primary-color-close-hover
    );
    @include balloon-arrow(
      $balloon-size-arrow-size,
      $balloon-size-arrow-size,
      $balloon-primary-color-bg,
      $balloon-primary-shadow,
      $balloon-size-arrow-expose-primary
    );
  }

  &-normal {
    @include balloon-state(
      $balloon-normal-color,
      $balloon-normal-color-bg,
      $balloon-normal-shadow
    );
    @include balloon-close(
      $balloon-size-close-margin-top,
      $balloon-size-close-margin-right,
      $balloon-size-close,
      $balloon-normal-color-close,
      $balloon-normal-color-close-hover
    );
    @include balloon-arrow(
      $balloon-size-arrow-size,
      $balloon-size-arrow-size,
      $balloon-normal-color-bg,
      $balloon-normal-shadow,
      $balloon-size-arrow-expose
    );
  }

  @include show-hidden();

  /* size */
  &-medium {
    @include balloon-size(
      $balloon-size-padding-top,
      $balloon-size-padding-right,
      $balloon-size-padding-top,
      $balloon-size-padding-right
    );
  }
  /* 带关闭按钮的右侧padding变大 */
  &-closable {
    @include balloon-size(
      $balloon-size-padding-top,
      $balloon-size-padding-closable-right,
      $balloon-size-padding-top,
      $balloon-size-padding-right
    );
  }
}

.#{$css-prefix}balloon-tooltip {
  @include box-sizing;
  @include balloon-bounding();

  font-size: $balloon-tooltip-content-font-size;
  line-height: $balloon-tooltip-content-line-height;
  font-weight: $balloon-tooltip-content-font-weight;
  z-index: 0;
  @include tooltip-arrow(
    $balloon-size-arrow-size,
    $balloon-size-arrow-size,
    $balloon-tooltip-color-normal-bg
  );
  &-normal {
    @include tooltip-state(
      $balloon-tooltip-color-normal,
      $balloon-tooltip-color-normal-bg,
      $balloon-tooltip-shadow
    );
  }

  &-primary {
    @include tooltip-state(
      $balloon-tooltip-color-primary,
      $balloon-tooltip-color-primary-bg,
      $balloon-tooltip-shadow
    );
    &:after {
      background: $balloon-tooltip-color-primary-bg;
    }
  }

  @include show-hidden();

  /* size */
  &-medium {
    @include balloon-size(
      $balloon-tooltip-size-padding-top,
      $balloon-tooltip-size-padding-right,
      $balloon-tooltip-size-padding-bottom,
      $balloon-tooltip-size-padding-left
    );
  }
}

.#{$css-prefix}balloon.#{$css-prefix}pop-confirm {
  box-sizing: border-box;
  &.#{$css-prefix}pop-confirm-small {
    width: $pop-confirm-width-small;
  }
  &.#{$css-prefix}pop-confirm-medium {
    width: $pop-confirm-width-medium;
  }
  &.#{$css-prefix}pop-confirm-large {
    width: $pop-confirm-width-large;
  }
  padding: $S12;
  .#{$css-prefix}pop-confirm-container {
    position: relative;
    .icon-area {
      position: absolute;
      left: 0;
      top: 0;
      line-height: 20px;
      &-info {
        color: $primary-brand;
      }
      &-success {
        color: $primary-success;
      }
      &-warning {
        color: $primary-warning;
      }
      &-danger {
        color: $primary-danger;
      }
    }
    .title-content-area {
      margin: 0 0 $S20 $S28;
      line-height: 0;
      &.title-content-area-no-footer {
        margin-bottom: 0;
      }
      .title-area {
        font-size: $font-size-subhead;
        line-height: $line-height-subhead;
        color: $gray-08;
        margin-bottom: $S4;
      }
      .content-area {
        font-size: 13px;
        line-height: $line-height-body;
        color: $gray-07;
      }
      .content-area-no-title {
        color: $gray-08;
      }
    }
    .footer-area {
      text-align: right;
      .#{$css-prefix}btn {
        margin-right: $S12;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@import './rtl.scss';
