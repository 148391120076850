#{$time-picker-prefix}-panel {
  @include box-sizing;
  @include clearfix;

  &-header {
    padding: $S8;
  }

  &-input.#{$css-prefix}input {
    width: 100%;
    vertical-align: middle;
  }

  &-col-3 {
    #{$time-picker-prefix}-menu {
      width: calc(100% / 3);
    }
  }

  &-col-2 {
    #{$time-picker-prefix}-menu {
      width: 50%;
    }
  }
}
