// 状态
// ----------------------------------------
@mixin calendar-cell-state($background, $color) {
  background: $background;
  color: $color;
}

// 卡片模式单元尺寸
// ----------------------------------------
@mixin calendar-card-cell-size($width, $height, $corner: 0, $margin-tb: 0) {
  width: $width;
  height: $height;
  line-height: $height;
  margin: $margin-tb auto;
  border-radius: $corner;
}
