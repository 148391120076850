@import '../core/index-noreset.scss';
@import 'scss/variable';
@import 'scss/mixin';
@import './rtl.scss';

.#{$css-prefix}ver {
  #{$menu-prefix}-divider {
    margin: $S4 $S16;
  }
  #{$menu-prefix}-group-label {
    padding: 0 $S16;
  }
  #{$menu-prefix}-footer {
    margin-top: $S4;
  }
}

#{$menu-prefix} {
  @include box-sizing;

  &:focus,
  & *:focus {
    outline: 0;
  }

  position: relative;
  min-width: 150px;
  padding: $menu-padding-vertical 0;
  margin: 0;
  list-style: none;
  border-radius: $shape-radius;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background: $menu-background;
  line-height: $menu-line-height;
  font-size: $menu-font-size;

  &-embeddable,
  &-embeddable &-item.#{$css-prefix}disabled,
  &-embeddable &-item.#{$css-prefix}disabled #{$menu-prefix}-item-text > a {
    background: initial;
    border: none;
  }

  &-embeddable &-item-inner {
    height: 100%;
  }

  &-content {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &-sub-menu {
    padding: 0;
    margin: 0;
    list-style: none;

    &.#{$css-prefix} {
      &expand-enter {
        overflow: hidden;
      }

      &expand-enter-active {
        transition: height 0.3s ease-out;
      }

      &expand-leave {
        overflow: hidden;
      }

      &expand-leave-active {
        transition: height 0.3s ease-out;
      }
    }
  }

  &-dangerous-item:hover {
    color: #fff;
    background: $primary-danger;
  }

  &-dangerous-item:active {
    color: #fff;
    background: $hover-danger;
  }

  &-item {
    position: relative;
    padding: 0 $menu-padding-horizontal;
    transition: background 0.2s ease;
    color: $menu-color;
    cursor: pointer;

    &-helper {
      float: right;
      color: $gray-06;
      font-style: normal;
      font-size: $menu-font-size;
    }

    .#{$css-prefix}checkbox,
    .#{$css-prefix}radio {
      margin-right: $menu-icon-margin;
      vertical-align: sub;
    }

    &.#{$css-prefix}selected {
      @include menu-item-state(
        $menu-color-selected,
        $menu-background-selected,
        $menu-arrow-color,
        $menu-icon-selected-color
      );
    }

    &.#{$css-prefix}disabled,
    &.#{$css-prefix}disabled #{$menu-prefix}-item-text > a {
      @include menu-item-state(
        $menu-color-disabled,
        $menu-background,
        $menu-color-disabled,
        $menu-color-disabled
      );
      cursor: not-allowed;
    }

    &:not(.#{$css-prefix}disabled):not(.#{$css-prefix}menu-dangerous-item):hover,
    &:not(.#{$css-prefix}disabled).#{$css-prefix}selected:hover,
    &:not(.#{$css-prefix}disabled).#{$css-prefix}selected.#{$css-prefix}focused:hover {
      @include menu-item-state(
        $menu-color-hover,
        $menu-background-hover,
        $menu-arrow-color-hover,
        $menu-icon-selected-hover-color
      );
    }

    &:not(.#{$css-prefix}disabled):not(.#{$css-prefix}menu-dangerous-item):active,
    &:not(.#{$css-prefix}disabled).#{$css-prefix}focused,
    &:not(.#{$css-prefix}disabled).#{$css-prefix}selected.#{$css-prefix}focused,
    &:not(.#{$css-prefix}disabled).#{$css-prefix}selected:focus {
      @include menu-item-state(
        $menu-color-focus,
        $menu-background-focus,
        $menu-arrow-color-focus,
        $menu-icon-selected-focus-color
      );
    }

    &.#{$css-prefix}opened {
      @include menu-item-state(
        $menu-color-focus,
        $menu-background-focus,
        $menu-arrow-color-focus,
        $menu-icon-selected-focus-color
      );
    }
  }

  &-item-inner {
    height: $menu-line-height;
    font-size: $menu-font-size;
    padding-right: $S8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }

  &-item-text {
    & > a {
      display: inline-block;
      text-decoration: none;
      color: $menu-color;
      width: 100%;
      // &::before {
      //   position: absolute;
      //   background-color: transparent;
      //   top: 0;
      //   left: 0;
      //   bottom: 0;
      //   right: 0;
      //   content: '';
      // }
    }
  }

  &.#{$css-prefix}hoz {
    padding: 0;

    &#{$menu-prefix}-nowrap {
      overflow: hidden;
      white-space: nowrap;

      #{$menu-prefix}-more {
        padding: 0;
        text-align: center;
      }
    }

    > #{$menu-prefix}-item,
    > #{$menu-prefix}-sub-menu-wrapper,
    #{$menu-prefix}-content > #{$menu-prefix}-item {
      display: inline-block;
      vertical-align: top;
    }

    #{$menu-prefix}-header,
    #{$menu-prefix}-content,
    #{$menu-prefix}-footer {
      display: inline-block;
    }
  }

  #{$menu-prefix}-footer {
    border-top: $menu-divider-width $menu-divider-style $menu-divider-color;
  }

  #{$menu-prefix}-header {
    border-bottom: $menu-divider-width $menu-divider-style $menu-divider-color;
    margin-bottom: $S4;
    padding-bottom: $S4;
  }

  &-hoz-right {
    float: right;
  }

  &-group-label {
    padding: 0 $menu-padding-title-horizontal;
    color: $gray-06;
  }

  &-divider {
    margin: $menu-divider-margin-ver $menu-divider-margin-hoz;
    border-bottom: $menu-divider-width $menu-divider-style $menu-divider-color;
  }

  & &-icon-selected.#{$css-prefix}icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include icon-size($menu-icon-selected-size);

    &#{$menu-prefix}-icon-right {
      right: $menu-icon-selected-right;
    }
  }

  & &-icon-arrow.#{$css-prefix}icon.#{$css-prefix}medium {
    position: absolute;
    right: 10px;
    @include icon-size($menu-icon-size);
    top: 50%;
    transform: translateY(-50%);
    color: $menu-arrow-color;
    transition: color ease 0.3s, transform ease 0.3s;
  }

  & &-icon-arrow-down.#{$css-prefix}open.#{$css-prefix}medium{
    @include icon-size($size: $menu-icon-size, $transform: translateY(-50%) rotate(180deg));
  }

  & &-icon-arrow-right.#{$css-prefix}open.#{$css-prefix}medium {
    @include icon-size($size: $menu-icon-size, $transform: translateY(-50%) rotate(-90deg));
  }

  & &-hoz-icon-arrow.#{$css-prefix}icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    @include icon-size($icon-xs);
    color: $menu-arrow-color;
    transition: transform ease 0.3s;
  }

  & &-hoz-icon-arrow.#{$css-prefix}open.#{$css-prefix}medium {
    @include icon-size($size: $icon-xs, $transform: translateY(-50%) rotate(180deg));
  }

  &.#{$css-prefix}context {
    line-height: $S24;

    #{$menu-prefix}-item-inner {
      height: $S24;
    }
  }

  /* &-popup-content.#{$css-prefix}hide { */
  /*     display: none; */
  /* } */
}
