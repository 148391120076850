////
/// @module checkbox: 复选框
/// @tag checkbox
/// @category component
/// @family data-entry
/// @varPrefix $checkbox-
/// @classPrefix {prefix}-checkbox
/// @order {"size/bounding":10,"size/icon":11,"size/label":12,"statement/normal":10,"statement/normal/bounding":100,"statement/hover":11,"statement/hover/bounding":110,"statement/checked":12,"statement/checked/bounding":120,"statement/checked/icon":121,"statement/disabled":13,"statement/disabled/bounding":130,"statement/disabled/icon":131,"statement/checked hover":14,"statement/checked hover/bounding":140,"statement/checked hover/icon":141}
////

@charset "UTF-8";

$checkbox-prefix: '.#{$css-prefix}checkbox';

// Medium
// ----------------------------------------
/// size
/// @namespace size/bounding
$checkbox-m-size: 14px !default;
/// height
/// @namespace size/handle
$checkbox-m-height: $S20 !default;
/// size
/// @namespace size/label
$checkbox-font-m-size: 14px !default;
/// size
/// @namespace size/icon
$checkbox-circle-m-size: $icon-xs !default;

// Large
// ----------------------------------------
/// size
/// @namespace size/bounding
$checkbox-l-size: 18px !default;
/// height
/// @namespace size/handle
$checkbox-l-height: $S24 !default;
/// size
/// @namespace size/label
$checkbox-font-l-size: $S16 !default;
/// size
/// @namespace size/icon
$checkbox-circle-l-size: $icon-s !default;

/// radius
/// @namespace size/bounding
$checkbox-border-radius: $shape-checkbox !default;
/// border-width
/// @namespace size/bounding
$checkbox-border-width: 1px !default;

/// shadow
/// @namespace size/bounding
$checkbox-shadow: $shadow-01 !default;

/// border
/// @namespace statement/normal/bounding
$checkbox-border-color: $gray-04 !default;
/// border
/// @namespace statement/hover/bounding
$checkbox-hovered-border-color: $primary-brand !default;
/// border
/// @namespace statement/checked/bounding
$checkbox-checked-border-color: transparent !default;
/// border
/// @namespace statement/disabled/bounding
$checkbox-disabled-border-color: $gray-04 !default;
/// border
/// @namespace statement/checked hover/bounding
$checkbox-checked-hovered-border-color: transparent !default;

/// text
/// @namespace statement/checked/icon
$checkbox-checked-circle-color: $snow !default;
/// text
/// @namespace statement/normal/label
$checkbox-label-color: $gray-08 !default;
/// text
/// @namespace statement/disabled/label
$checkbox-disabled-label-color: $gray-05 !default;
/// text
/// @namespace statement/disabled/icon
$checkbox-disabled-circle-color: $gray-05 !default;
/// text
/// @namespace statement/checked hover/icon
$checkbox-checked-hovered-circle-color: $snow !default;

/// background
/// @namespace statement/normal/bounding
$checkbox-bg-color: $snow !default;
/// background
/// @namespace statement/checked/bounding
$checkbox-checked-bg-color: $primary-brand !default;
/// background
/// @namespace statement/hover/bounding
$checkbox-hovered-bg-color: $highlight-brand !default;
/// background
/// @namespace statement/checked hover/bounding
$checkbox-checked-hovered-bg-color: $hover-brand !default;
/// background
/// @namespace statement/disabled/bounding
$checkbox-disabled-bg-color: $gray-01 !default;
/// margin(L)
/// @namespace size/label
$checkbox-margin-left: $S8 !default;
