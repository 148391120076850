#{$pagination-prefix}[dir='rtl'] {
  #{$pagination-prefix} {
    &-total {
      margin-right: 0;
      margin-left: $S16;
    }

    &-jump {
      &-go {
        margin-left: 0;
        margin-right: $S4;
      }
    }

    &-size-selector {
      &-title {
        margin-right: 0;
        margin-left: $pagination-size-selector-title-margin-right;
      }

      &-btn.#{$css-prefix}btn-text {
        & + #{$pagination-prefix}-size-selector-btn {
          border-left: none;
          border-right: 1px solid $gray-03;
        }
      }
    }

    &-pages + #{$pagination-prefix}-size-selector,
    &-size-selector + #{$pagination-prefix}-pages {
      margin-left: 0;
      margin-right: $S40;
    }
  }

  &.#{$css-prefix}start {
    #{$pagination-prefix}-pages {
      float: left;
    }
    #{$pagination-prefix}-size-selector {
      float: right;
    }
  }

  &.#{$css-prefix}end {
    #{$pagination-prefix}-pages {
      float: right;
    }
    #{$pagination-prefix}-size-selector {
      float: left;
    }
  }

  @include pagination-size(
    'rtl',
    $itemSplit: $pagination-item-split,
    $itemPaddingLeftRight: $S12 - 2px,
    $itemBorderWidth: $pagination-item-border-width,
    $itemCorner: $pagination-item-corner,
    $totalFontSize: $pagination-total-font-size,
    $currentFontSize: $pagination-current-font-size,
    $otherFontSize: $pagination-jump-font-size,
    $pageHeight: $form-element-medium-height,
    $numberPaddingLeftRight: $pagination-size-selector-number-padding,
    $inputWidth: $pagination-input-width
  );
}
