////
/// @module timeline: 时间轴
/// @tag Timeline
/// @category component
/// @family data-display
/// @varPrefix $timeline-
/// @classPrefix {prefix}-timeline
/// @order {"size/node":10,"size/divider":11,"size/content":12,"size/expand button":13,"statement/normal":10,"statement/normal/content":100,"statement/normal/divider":101,"statement/normal/expand button":102,"statement/hover":11,"statement/hover/expand button":110,"statement/done":12,"statement/done/node":120,"statement/process":13,"statement/process/node":130,"statement/success":14,"statement/success/node":140,"statement/error":15,"statement/error/node":150}
////

$timeline-prefix: '.' + $css-prefix + 'timeline';

$timeline-icon-prefix: '.' + $css-prefix + 'icon';

/// size
/// @namespace size/node
$timeline-item-node-size: $S16 !default;

/// custom node size
/// @namespace size/node
$timeline-item-custom-node-size: $S40 !default;

/// custom node font size
/// @namespace size/node
$timeline-item-custom-node-font-size: $font-size-caption !default;

/// padding (t, b)
/// @namespace size/node
$timeline-item-node-padding: $S4 !default;

/// dot size
/// @namespace size/node
$timeline-item-dot-size: $S8 !default;

/// icon size
/// @namespace size/node
$timeline-item-icon-size: $icon-s !default;

/// line width
/// @namespace size/divider
$timeline-item-tail-size: 1px !default;

/// left content width
/// @namespace size/content
$timeline-item-left-content-width: $S80 !default;

/// margin (l)
/// @namespace size/content
$timeline-item-content-margin-left: $S12 !default;

/// title text
/// @namespace size/content
$timeline-item-title-font-size: $font-size-body !default;

/// title text
/// @namespace line-height/content
$timeline-item-title-line-height: $line-height-body !default;

/// title weight
/// @namespace size/content
$timeline-item-title-font-weight: $font-weight-medium !default;

/// title margin (t)
/// @namespace size/content
$timeline-item-title-margin-top: 2px !default;

/// time margin (t)
/// @namespace size/content
$timeline-item-time-margin-top: $S4 !default;

/// time margin (b)
/// @namespace size/content
$timeline-item-time-margin-bottom: $S4 !default;

/// body text
/// @namespace size/content
$timeline-item-body-font-size: $font-size-body !default;

/// body text
/// @namespace line-height/content
$timeline-item-body-line-height: $line-height-body !default;

/// left time text
/// @namespace size/content
$timeline-item-left-body-font-size: $font-size-caption !default;

/// time text
/// @namespace size/content
$timeline-item-time-font-size: $font-size-caption !default;
/// time text
/// @namespace line-height/content
$timeline-item-time-line-height: $line-height-caption !default;

/// text
/// @namespace size/expand button
$timeline-item-folder-font-size: $font-size-caption !default;

/// margin (t)
/// @namespace size/expand button
$timeline-item-folder-margin-top: $S4 !default;

/// margin (b)
/// @namespace size/expand button
$timeline-item-folder-margin-bottom: $S4 !default;

/// title text
/// @namespace statement/normal/content
$timeline-item-title-color: $gray-08 !default;

/// body text
/// @namespace statement/normal/content
$timeline-item-body-color: $gray-07 !default;

/// left body text
/// @namespace statement/normal/content
$timeline-item-left-body-color: $gray-06 !default;

/// time text
/// @namespace statement/normal/content
$timeline-item-time-color: $gray-06 !default;

/// color
/// @namespace statement/normal/divider
$timeline-item-tail-color: $gray-03 !default;

/// dot size
/// @namespace statement/done/node
$timeline-item-done-dot-size: $S8 !default;

/// background
/// @namespace statement/done/node
$timeline-item-done-background: $gray-04 !default;

/// color
/// @namespace statement/done/node
$timeline-item-done-color: $snow !default;

/// dot size
/// @namespace statement/process/node
$timeline-item-process-dot-size: $S8 !default;

/// background
/// @namespace statement/process/node
$timeline-item-process-background: $primary-brand !default;

/// color
/// @namespace statement/process/node
$timeline-item-process-color: $snow !default;

/// dot size
/// @namespace statement/success/node
$timeline-item-success-dot-size: $S8 !default;

/// background
/// @namespace statement/success/node
$timeline-item-success-background: $border-success !default;

/// color
/// @namespace statement/success/node
$timeline-item-success-color: $snow !default;

/// dot size
/// @namespace statement/error/node
$timeline-item-error-dot-size: $S8 !default;

/// background
/// @namespace statement/error/node
$timeline-item-error-background: $border-danger !default;

/// color
/// @namespace statement/error/node
$timeline-item-error-color: $snow !default;
