@charset "UTF-8";
// checkbox mixins
// --------------------------------------------------

@mixin bounding($size, $height, $font-size, $icon-size) {
  height: $height;
  line-height: $height;
  vertical-align: middle;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: 3px;
    left: 3px;
    width: $size;
    height: $size;
    margin: 0;
    cursor: pointer;
  }

  #{$checkbox-prefix}-inner {
    display: block;
    width: $size;
    height: $size;
    background: $checkbox-bg-color;
    border-radius: $checkbox-border-radius;
    border: $checkbox-border-width solid $checkbox-border-color;
    transition: ease border-color 0.3s 0s, ease background-color 0.3s 0s;
    text-align: left; /* 防止继承父级 */
    box-shadow: $checkbox-shadow;
    /* box-sizing: content-box; */

    > .#{$css-prefix}icon {
      transform: translate(-50%, -50%);
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      color: $checkbox-checked-circle-color;
      left: 50%;
      top: 50%;
      @include icon-size($icon-size, 0);
    }
  }

  &.checked {
    #{$checkbox-prefix}-inner {
      border-color: $checkbox-checked-border-color;
      background-color: $checkbox-checked-bg-color;

      &:hover,
      &.hovered {
        border-color: $checkbox-checked-border-color;
      }
      > .#{$css-prefix}icon {
        opacity: 1;
        @include icon-size($icon-size, 0); /* font-size < 12px的时候进行覆盖。 */
      }
    }
  }

  &.indeterminate {
    #{$checkbox-prefix}-inner {
      border-color: $checkbox-checked-border-color;
      background-color: $checkbox-checked-bg-color;

      &:hover,
      &.hovered {
        border-color: $checkbox-checked-border-color;
      }
      > .#{$css-prefix}icon {
        opacity: 1;
        @include icon-size($icon-size, 0); /* font-size < 12px的时候进行覆盖。 */
      }
    }
  }
}

@mixin labelbounding($height, $font-size) {
  font-size: $font-size;
  height: $height;
  line-height: $height;
}

@mixin groupbounding($height) {
  height: $height;
  line-height: $height;
}
