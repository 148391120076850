@import "../core/index-noreset";

@import "scss/variable";
@import './scss/menu.scss';
@import './scss/panel.scss';
@import './rtl.scss';
@import '../input/scss/variable.scss';

#{$time-picker-prefix} {
  @include box-sizing;

  display: inline-block;
  width: 200px;
  &:focus {
    outline: none;
    border: 2px solid $border-brand;
  }

  &-trigger .#{$css-prefix}input {
    width: 100%;
  }

  &-body {
    overflow: hidden;
    width: $time-picker-panel-width;
    border-radius: $time-picker-panel-corner;
    background: $time-picker-panel-background;
    box-shadow: $time-picker-panel-shadow;
  }
}

#{$time-range-picker-prefix} {
  @include box-sizing;
  width: $S80 * 3;
  background: $background-section;
  border-radius: $shape-radius;
  border: 1px solid $gray-04;
  &-active {
    border-color: $primary-brand;
  }
  &-hover {
    border-color: $gray-05;
  }
  &:focus {
    outline: none;
    border: 2px solid $border-brand;
  }
  &-disabled {
    border-color: $gray-03;
    background: $gray-01;
  }
  &-trigger-separator {
    color: $gray-05;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: $font-size-body;
    line-height: $line-height-body;
    text-align: center;
  }
  &-trigger-start-input, &-trigger-end-input {
    width: calc((100% - 20px) / 2);
    &.#{$css-prefix}small {
      height: $form-element-small-height - 2 * $input-border-width;
    }
    &.#{$css-prefix}medium {
      height: $form-element-medium-height - 2 * $input-border-width;
    }
  }
}
#{$time-range-picker-prefix}-panel {
  @include box-sizing;
  padding: $S8;
  background: $background-section;
  width: 380px;
  box-shadow: $shadow-02;
  border-radius: $shape-radius;
  &-header {
    margin-bottom: $S8;
  }
  &-start-input, &-end-input {
    width: calc((100% - 20px) / 2);
  }
  #{$time-range-picker-prefix}-start-panel,
  #{$time-range-picker-prefix}-end-panel {
    width: calc((100% - 20px) / 2);
    display: inline-block;
  }
}
