@charset "UTF-8";

.#{$css-prefix}message[dir="rtl"] {
  .#{$css-prefix}message-symbol {
    float: right;
  }

  &.#{$css-prefix}medium {
    .#{$css-prefix}message-title {
      padding: 0 $message-size-m-title-content-padding-left + $message-size-m-icon 0 $message-size-m-title-content-padding-right;
    }
    .#{$css-prefix}message-close {
      left: $message-size-m-close-right;
      right: auto;
    }
  }

  &.#{$css-prefix}large {
    .#{$css-prefix}message-title {
      padding: 0 $message-size-l-title-content-padding-left + $message-size-l-icon 0 $message-size-l-title-content-padding-right;
    }
    .#{$css-prefix}message-close {
      left: $message-size-l-close-right;
      right: auto;
    }
  }
}
