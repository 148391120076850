#{$checkbox-prefix}-wrapper[dir="rtl"] {
  margin-right: 8px;
  margin-left: 0;
  &:first-child {
    margin-right: 0;
  }

  > #{$checkbox-prefix}-label {
    margin-right: $checkbox-margin-left;
    margin-left: 0;
  }
}
