@charset "UTF-8";

////
/// @module input: 输入框
/// @tag input
/// @category component
/// @family data-entry
/// @varPrefix $input-
/// @classPrefix {prefix}-input
/// @order {"statement/normal":10,"statement/normal/addon":100,"statement/normal/feedback":101,"statement/normal/maxlen":102,"statement/normal/clear":103,"statement/hover":11,"statement/hover/clear":110,"statement/focus":12,"statement/disabled":13,"statement/error":14,"size/bounding":10,"size/bounding/addon":100,"size/label":11,"size/maxlen":12}
////

$input-prefix: '.#{$css-prefix}input';

// 公共
// --------------------------------------------------

/// text
/// @namespace statement/normal
$input-text-color: $gray-08 !default;

/// border
/// @namespace statement/normal
$input-border-color: $gray-04 !default;

/// label
/// @namespace statement/normal
$input-label-color: $gray-07 !default;

/// border
/// @namespace statement/hover
$input-hover-border-color: $gray-05 !default;

/// background
/// @namespace statement/hover
$input-hover-bg-color: $snow !default;

/// border
/// @namespace statement/focus
$input-focus-border-color: $primary-brand !default;

/// background
/// @namespace statement/focus
$input-focus-bg-color: $snow !default;

/// border
/// @namespace size/bounding
$input-border-width: 1px !default;

/// background
/// @namespace statement/normal
$input-bg-color: $snow !default;

/// placeholder
/// @namespace statement/normal
$input-placeholder-color: $gray-06 !default;

/// text
/// @namespace statement/disabled
$input-disabled-color: $gray-05 !default;

/// border
/// @namespace statement/disabled
$input-disabled-border-color: $gray-03 !default;

/// background
/// @namespace statement/disabled
$input-disabled-bg-color: $gray-01 !default;

// 多行
// --------------------------------------------------

/// padding(l,r)
/// @namespace size/bounding
$input-multiple-padding-lr: $S8 !default;

/// padding(t,b)
/// @namespace size/bounding
$input-multiple-padding-tb: $S4 !default;

/// text
/// @namespace size/bounding
$input-multiple-font-size: $font-size-body !default;

/// corner
/// @namespace size/bounding
$input-multiple-corner: $shape-radius !default;

// addon
// --------------------------------------------------

/// background
/// @namespace statement/normal/addon
$input-addon-bg-color: $gray-03 !default;

/// text
/// @namespace statement/normal/addon
$input-addon-text-color: $gray-06 !default;

/// padding(l,r)
/// @namespace size/bounding/addon
$input-addon-padding: $S8 !default;

// large
// --------------------------------------------------

/// padding
/// @namespace size/bounding
$input-l-padding: $S8 !default;

/// padding(l)
/// @namespace size/label
$input-l-label-padding-left: $S12 !default;

/// padding(r)
/// @namespace size/label
$input-l-icon-padding-right: $S8 !default;

// medium
// --------------------------------------------------

/// padding
/// @namespace size/bounding
$input-m-padding: $S8 !default;

/// padding(l)
/// @namespace size/label
$input-m-label-padding-left: $S8 !default;

/// padding(r)
/// @namespace size/label
$input-m-icon-padding-right: $S8 !default;

// xs
// --------------------------------------------------

/// padding
/// @namespace size/bounding
$input-xs-padding: $S8 !default;

/// padding(l)
/// @namespace size/label
$input-xs-label-padding-left: $S4 !default;

/// padding(r)
/// @namespace size/label
$input-xs-icon-padding-right: $S4 !default;

// small
// --------------------------------------------------

/// padding
/// @namespace size/bounding
$input-s-padding: $S8 !default;

/// padding(l)
/// @namespace size/label
$input-s-label-padding-left: $S4 !default;

/// padding(r)
/// @namespace size/label
$input-s-icon-padding-right: $S4 !default;

// feedback
// --------------------------------------------------

/// success
/// @namespace statement/normal/feedback
$input-feedback-success-color: $primary-success !default;

/// loading
/// @namespace statement/normal/feedback
$input-feedback-loading-color: $border-brand !default;

/// border
/// @namespace statement/error
$input-feedback-error-border-color: $primary-danger !default;

/// border
/// @namespace statement/success
$input-feedback-success-border-color: $primary-success !default;

/// tip
/// @namespace statement/error
$input-feedback-error-tip-color: $primary-danger !default;

// maxlen
// --------------------------------------------------

/// error
/// @namespace statement/normal/maxlen
$input-maxlen-error-color: $primary-danger !default;

/// text
/// @namespace statement/normal/maxlen
$input-maxlen-color: $gray-06 !default;

/// text
/// @namespace size/maxlen
$input-maxlen-font-size: $font-size-caption !default;

// hint
// --------------------------------------------------

/// background
/// @namespace statement/normal/clear
$input-hint-color: $gray-06 !default;

/// background
/// @namespace statement/hover/clear
$input-hint-hover-color: $gray-07 !default;
