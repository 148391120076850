@import "../core/index-noreset";
@import "../input/scss/variable.scss";
@import "../input/scss/mixin.scss";
@import "../time-picker/scss/variable.scss";

@import "scss/variable";
@import "scss/mixin";

@import "scss/date-picker";
@import "scss/range-picker";
@import "./rtl.scss";

#{$date-picker-prefix}, #{$range-picker-prefix}, #{$month-picker-prefix}, #{$year-picker-prefix} {
  @include box-sizing;

  &-body {
    border-radius: $popup-local-corner;
    box-shadow: $shadow-03;
    background: $date-picker-panel-background;
  }

  &-panel {
    &-header {
      padding: $S12 $S12 $S8;
      text-align: center;
    }

    &-time {
      border-top: $popup-local-border-width $popup-local-border-style $popup-local-border-color;
    }

    &-footer {
      text-align: right;
      padding: $date-picker-panel-footer-padding-tb $date-picker-panel-footer-padding-lr;
      border-top: $popup-local-border-width $popup-local-border-style $popup-local-border-color;
    }

    &-footer > #{$date-picker-btn-prefix}:not(:last-child),
    &-tools > #{$date-picker-btn-prefix}:not(:last-child) {
      margin-right: $S16;
    }

    &-tools {
      float: left;
    }
  }

  .#{$css-prefix}calendar-panel-header {
    margin-left: -$popup-local-border-width;
    margin-right: -$popup-local-border-width;
  }

  #{$date-picker-input-prefix} input {
    vertical-align: baseline;
  }
}

#{$month-picker-prefix}-body, #{$year-picker-prefix}-body {
  .#{$css-prefix}calendar-table {
    margin: 14px 0 22px;
  }
}
