@import './scss/variables.scss';
@import './scss/mixin.scss';

#{$cover-prefix} {
  display: inline-block;
  box-sizing: content-box;
  border-radius: $shape-radius;
  // 长宽比为2：1
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(0);
  box-shadow: none;
  transition: transform 218ms ease 0ms, box-shadow 218ms ease 0ms;
  &:hover {
    transform: translateY(-$S4);
    box-shadow: $shadow-03;
  }
  &-inner {
    #{$cover-prefix}-header {
      padding: $S12 $S16 $S4;
      display: flex;
      color: $snow;
      &-title {
        flex: 1;
        font-size: $font-size-title;
        line-height: $line-height-title;
        font-weight: $font-weight-medium;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    #{$cover-prefix}-description {
      padding: 0 $S16;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: $font-size-caption;
      line-height: $line-height-caption;
      font-weight: $font-weight-regular;
      color: $snow;
      opacity: 0.9;
    }
    #{$cover-prefix}-tag {
      position: absolute;
      right: $S16;
      bottom: $S12;
      border-radius: $shape-checkbox;
      background: rgba($shadow-color, 0.4);
      color: $snow;
      font-size: $font-size-caption;
      line-height: $line-height-caption;
      font-weight: $font-weight-medium;
      padding: 2px $S8;
    }
  }
}

#{$img-prefix} {
  display: inline-block;
  font-size: 0;
  img {
    border-radius: $shape-radius;
  }
  &-xs {
    @include img-size($S24);
  }
  &-small {
    @include img-size($S28);
  }
  &-medium {
    @include img-size($S36);
  }
  &-large {
    @include img-size($S48);
  }
  &-xl {
    @include img-size(64px);
  }
}
