.#{$css-prefix}color-tag {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 14px;
  vertical-align: middle;
  box-sizing: border-box;
  padding: $S4 $S8;
  margin: $S4;
  height: $S28;
  &:link,
  &:hover,
  &:visited,
  &:active {
    text-decoration: none;
  }
  &-children {
    font-size: $font-size-body;
    display: inline-block;
    height: 20px;
    line-height: 20px;
  }
  &.#{$css-prefix}color-tag-blue {
    background: $highlight-brand;
    color: $primary-brand;
  }
  &.#{$css-prefix}color-tag-blue.interactive {
    &:hover {
      color: $hover-brand;
      .#{$css-prefix}color-tag-right-icon {
        color: $primary-brand;
        &:hover {
          color: $hover-brand;
        }
        &:active {
          color: $click-brand;
        }
      }
    }
    &:active {
      color: $click-brand;
    }
  }

  &.#{$css-prefix}color-tag-green {
    background: $highlight-success;
    color: $primary-success;
  }
  &.#{$css-prefix}color-tag-green.interactive {
    &:hover {
      color: $hover-success;
      .#{$css-prefix}color-tag-right-icon {
        color: $hover-success;
        &:hover {
          color: $hover-success;
        }
        &:active {
          color: $click-success;
        }
      }
    }
    &:active {
      color: $click-success;
    }
  }

  &.#{$css-prefix}color-tag-orange {
    background: $highlight-warning;
    color: $primary-warning;
  }
  &.#{$css-prefix}color-tag-orange.interactive {
    &:hover {
      color: $hover-warning;
      .#{$css-prefix}color-tag-right-icon {
        color: $hover-warning;
        &:hover {
          color: $hover-warning;
        }
        &:active {
          color: $click-warning;
        }
      }
    }
    &:active {
      color: $click-warning;
    }
  }

  &.#{$css-prefix}color-tag-red {
    background: $highlight-danger;
    color: $primary-danger;
  }
  &.#{$css-prefix}color-tag-red.interactive {
    &:hover {
      color: $hover-danger;
      .#{$css-prefix}color-tag-right-icon {
        color: $hover-danger;
        &:hover {
          color: $hover-danger;
        }
        &:active {
          color: $click-danger;
        }
      }
    }
    &:active {
      color: $click-danger;
    }
  }

  &.#{$css-prefix}color-tag-gray {
    background: $gray-02;
    color: $gray-07;
  }
  &.#{$css-prefix}color-tag-gray.interactive {
    &:hover {
      color: $gray-08;
      .#{$css-prefix}color-tag-right-icon {
        color: $gray-08;
        &:hover {
          color: $gray-08;
        }
        &:active {
          color: $shadow-color;
        }
      }
    }
    &:active {
      color: $shadow-color;
    }
  }
}

.#{$css-prefix}color-s-tag {
  padding: 2px 6px;
  height: $S20;
  font-size: 0;
  .#{$css-prefix}color-tag-children {
    font-size: $S12;
    line-height: $line-height-caption;
    height: $S16;
  }
  .#{$css-prefix}icon{
    font-size: $S16;
  }
}

.#{$css-prefix}color-tag-left-icon {
  margin-right: 4px;
  margin-left: -4px;
}
.#{$css-prefix}color-s-tag-left-icon {
  margin-right: 4px;
  margin-left: -2px;
}
.#{$css-prefix}color-tag-right-icon {
  margin-left: 4px;
  margin-right: -4px;
}
.#{$css-prefix}color-s-tag-right-icon {
  margin-left: 4px;
  margin-right: -2px;
}
.#{$css-prefix}color-tag-right-link-icon {
  margin-left: 2px;
  margin-right: -6px;
}
.#{$css-prefix}color-s-tag-right-link-icon {
  margin-left: 2px;
  margin-right: -6px;
}
.pointer {
  cursor: pointer;
}
