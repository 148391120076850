@charset "UTF-8";

////
/// @module balloon: 气泡
/// @tag Balloon
/// @category component
/// @family feedback
/// @varPrefix $balloon-
/// @classPrefix {prefix}-balloon
/// @order {"size/bounding":10,"size/content":11,"size/close icon":12,"size/arrow":13,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/content":101,"statement/normal/close icon":102,"statement/hover":11,"statement/hover/close icon":110}
////


// balloon variables
// --------------------------------------------------

// Size
/// max width
/// @type length
/// @namespace size/bounding
$balloon-size-max-width: 300px !default;

$pop-confirm-width-small: 220px !default;
$pop-confirm-width-medium: 280px !default;
$pop-confirm-width-large: 338px !default;

/// padding (t, b)
/// @namespace size/content
$balloon-size-padding-top: $S16 !default;

/// padding (r)
/// @namespace size/content
$balloon-size-padding-right: $S16 !default;

/// padding (l)
/// @namespace size/content
$balloon-size-padding-closable-right: $S40 !default;

/// text
/// @namespace size/content
$balloon-content-font-size: $font-size-body !default;

/// text weight
/// @namespace size/content
$balloon-content-font-weight: $font-weight-regular !default;

/// text
/// @namespace size/content
$balloon-tooltip-content-font-size: $font-size-caption !default;

/// text
/// @namespace line-height/content
$balloon-tooltip-content-line-height: $line-height-caption !default;

/// text weight
/// @namespace size/content
$balloon-tooltip-content-font-weight: $font-weight-regular !default;

/// size
/// @namespace size/close icon
$balloon-size-close: $icon-xs !default;

/// margin (t)
/// @namespace size/close icon
$balloon-size-close-margin-top: $S12 !default;

/// margin (r)
/// @namespace size/close icon
$balloon-size-close-margin-right: $S12 !default;

/// size
/// @namespace size/arrow
$balloon-size-arrow-size: $S8 !default;

/// margin
/// @namespace size/arrow
$balloon-size-arrow-margin: $S8 !default;

/// padding (t)
/// @namespace size/bounding
$balloon-tooltip-size-padding-top: 6px !default;

/// padding (r)
/// @namespace size/bounding
$balloon-tooltip-size-padding-right: $S12 !default;

/// padding (b)
/// @namespace size/bounding
$balloon-tooltip-size-padding-bottom: 6px !default;

/// padding (l)
/// @namespace size/bounding
$balloon-tooltip-size-padding-left: $S12 !default;

$balloon-size-padding: $balloon-size-padding-top $balloon-size-padding-right;
$balloon-size-closable-padding: $balloon-size-padding-top $balloon-size-padding-closable-right $balloon-size-padding-top $balloon-size-padding-right;
$balloon-size-arrow-expose: -$balloon-size-arrow-size / 2;
$balloon-size-arrow-expose-primary: -$balloon-size-arrow-size / 2;
/// corner
/// @namespace statement/normal/bounding
$balloon-corner: $shape-radius !default;

// Normal
/// background
/// @namespace statement/normal/bounding
$balloon-normal-color-bg: $snow !default;

/// shadow
/// @namespace statement/normal/bounding
$balloon-normal-shadow: $shadow-02 !default;

/// text
/// @namespace statement/normal/content
$balloon-normal-color: $gray-08 !default;

/// color
/// @namespace statement/normal/close icon
$balloon-normal-color-close: $gray-06 !default;

/// color
/// @namespace statement/hover/close icon
$balloon-normal-color-close-hover: $gray-07 !default;

// Primary
/// background
/// @namespace statement/normal/bounding
$balloon-primary-color-bg: $gray-08 !default;

/// shadow
/// @namespace statement/normal/bounding
$balloon-primary-shadow: $shadow-03 !default;

/// text
/// @namespace statement/normal/content
$balloon-primary-color: $snow !default;

/// color
/// @namespace statement/normal/close icon
$balloon-primary-color-close: $snow !default;

/// color
/// @namespace statement/hover/close icon
$balloon-primary-color-close-hover: $snow !default;

// Tooltip
/// background
/// @namespace statement/normal/bounding
$balloon-tooltip-color-normal-bg: $background-overlay !default;
// Tooltip
/// background
/// @namespace statement/primary/bounding
$balloon-tooltip-color-primary-bg: $gray-08 !default;

/// shadow
/// @namespace statement/normal/bounding
$balloon-tooltip-shadow: $shadow-03 !default;

/// text
/// @namespace statement/normal/content
$balloon-tooltip-color-normal: $gray-08 !default;
/// text
/// @namespace statement/primary/content
$balloon-tooltip-color-primary: $snow !default;

$balloon-shadow: $shadow-04;
