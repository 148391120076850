#{$calendar-prefix}-fullscreen {
  #{$calendar-prefix} {
    &-table {
      margin: 0;
      border: none;
    }

    &-th {
      border: none;
      text-align: right;
      color: $calendar-fullscreen-table-head-color;
      font-size: $calendar-fullscreen-table-head-font-size;
      font-weight: $calendar-fullscreen-table-head-font-weight;
      padding-right: $calendar-fullscreen-table-head-padding-r;
      padding-bottom: $calendar-fullscreen-table-head-padding-b;
    }

    &-cell {
      font-size: $calendar-fullscreen-table-cell-font-size;
      border: none;
      &.#{$css-prefix}selected {
        #{$calendar-prefix}-date,
        #{$calendar-prefix}-month {
          font-weight: $calendar-fullscreen-table-cell-select-font-weight;

          @include calendar-cell-state(
            $calendar-fullscreen-table-cell-select-background,
            $calendar-fullscreen-table-cell-select-color
          );
        }
      }

      &.#{$css-prefix}disabled {
        #{$calendar-prefix}-date,
        #{$calendar-prefix}-month {
          cursor: not-allowed;
          @include calendar-cell-state(
            $calendar-fullscreen-table-cell-disabled-background,
            $calendar-fullscreen-table-cell-disabled-color
          );
        }
      }
    }

    &-date,
    &-month,
    &-year {
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: $calendar-fullscreen-table-cell-margin-tb $calendar-fullscreen-table-cell-margin-lr;
      padding: $calendar-fullscreen-table-cell-padding-tb $calendar-fullscreen-table-cell-padding-lr;
      min-height: $calendar-fullscreen-table-cell-min-height;
      border-top: $calendar-fullscreen-table-cell-boder-top-width solid;
      transition: background 0.3s ease;

      @include calendar-cell-state(
        $calendar-fullscreen-table-cell-normal-background,
        $calendar-fullscreen-table-cell-normal-color
      );

      &:hover {
        @include calendar-cell-state(
          $calendar-fullscreen-table-cell-hover-background,
          $calendar-fullscreen-table-cell-hover-color
        );
      }
    }

    &-cell-prev-month,
    &-cell-next-month {
      #{$calendar-prefix}-date {
        @include calendar-cell-state(
          $calendar-fullscreen-table-cell-other-background,
          $calendar-fullscreen-table-cell-other-color
        );
      }
    }

    &-cell-current {
      #{$calendar-prefix}-date {
        font-weight: $calendar-fullscreen-table-cell-current-font-weight;
        @include calendar-cell-state(
          $calendar-fullscreen-table-cell-current-background,
          $calendar-fullscreen-table-cell-current-color
        );
      }
      #{$calendar-prefix}-date {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 16px;
          height: 2px;
          background: $primary-brand;
          left: 4px;
          bottom: 0;
        }
      }
    }
  }
}

#{$calendar-prefix}-card,
#{$calendar-prefix}-panel,
#{$calendar-prefix}-range {
  #{$calendar-prefix} {
    &-th {
      border: none;
      text-align: center;
      color: $calendar-card-table-head-color;
      font-size: $calendar-card-table-head-font-size;
      line-height: $line-height-body;
      font-weight: $calendar-card-table-head-font-weight;
      background: $background-overlay;
      &-day {
        margin-bottom: $S4;
      }
    }

    &-cell {
      text-align: center;
      font-size: $calendar-card-table-cell-font-size;
      border: none;
      &.#{$css-prefix}selected {
        #{$calendar-prefix}-date {
          font-weight: $calendar-card-table-cell-select-font-weight;

          @include calendar-cell-state(
            $calendar-card-table-cell-select-background,
            $calendar-card-table-cell-select-color
          );
        }
        #{$calendar-prefix}-month,
        #{$calendar-prefix}-year {
          font-weight: $calendar-card-table-cell-select-font-weight;
          @include calendar-cell-state(
            $primary-brand,
            $snow
          );
        }
      }

      &.#{$css-prefix}disabled {
        #{$calendar-prefix}-date,
        #{$calendar-prefix}-month,
        #{$calendar-prefix}-year {
          cursor: not-allowed;

          @include calendar-cell-state(
            $calendar-card-table-cell-disabled-background,
            $calendar-card-table-cell-disabled-color
          );
        }
      }

      &.#{$css-prefix}inrange {
        #{$calendar-prefix}-date {
          @include calendar-cell-state(
            $calendar-card-table-cell-inrange-background,
            $calendar-card-table-cell-inrange-color
          );
        }
      }
    }

    &-date,
    &-month,
    &-year {
      text-align: center;

      &:hover {
        cursor: pointer;
        @include calendar-cell-state(
          $calendar-card-table-cell-hover-background,
          $calendar-card-table-cell-hover-color
        );
      }

      @include calendar-cell-state(
        $calendar-card-table-cell-normal-background,
        $calendar-card-table-cell-normal-color
      );
    }

    &-date {
      @include calendar-card-cell-size(
        $S24,
        $S24,
        $calendar-card-table-cell-date-border-radius,
        3px
      );
    }

    &-month {
      @include calendar-card-cell-size(
        $S48,
        $S28,
        $calendar-card-table-cell-month-border-radius,
        10px
      );
    }

    &-year {
      @include calendar-card-cell-size(
        $S48,
        $S28,
        $calendar-card-table-cell-year-border-radius,
        10px
      );
    }

    &-cell-prev-month {
      #{$calendar-prefix}-date {
        @include calendar-cell-state(
          $calendar-card-table-cell-other-background,
          $calendar-card-table-cell-other-color
        );
      }
    }

    &-cell-next-month {
      #{$calendar-prefix}-date {
        @include calendar-cell-state(
          $calendar-card-table-cell-other-background,
          $calendar-card-table-cell-other-color
        );
      }
    }

    &-cell-current {
      #{$calendar-prefix}-date {
        font-weight: $calendar-card-table-cell-current-font-weight;

        @include calendar-cell-state(
          $calendar-card-table-cell-current-background,
          $calendar-card-table-cell-current-color
        );
      }
      #{$calendar-prefix}-date {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 16px;
          height: 2px;
          background: $primary-brand;
          left: 4px;
          bottom: 0;
        }
      }
    }
  }
}
