@charset "UTF-8";

// loading mixins
// --------------------------------------------------
@keyframes wave {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
