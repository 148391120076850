@charset "UTF-8";

////
/// @module loading: 加载
/// @tag loading
/// @category component
/// @family feedback
/// @varPrefix $loading-
/// @classPrefix {prefix}-loading
/// @order {"size/bounding":10,"size/icon":11,"statement/normal":10}
////

$loading-prefix: '.#{$css-prefix}loading';

// loading variables
// --------------------------------------------------

/// icon
/// @namespace size/bounding
$loading-icon-size: $icon-xl !default;
/// icon
/// @namespace statement/normal
$loading-dot-color: $primary-brand !default;

$loading-duration-seconds: 1.4s;

/// width
/// @namespace size/bounding
$loading-large-dot-size: $S24 !default;

/// width
/// @namespace size/bounding
$loading-medium-dot-size: 14px !default;

$loading-large-dot-container-width: 82px !default;
$loading-large-dot-container-height: $S24 !default;

$loading-medium-dot-container-width: $S48 !default;
$loading-medium-dot-container-height: 14px !default;

