
%input-separator {
    cursor: default;
    display: inline-block;
    text-align: center;
    color: $gray-05;
    width: $date-picker-input-separator-width;
    font-size: $font-size-caption;
    height: $date-picker-input-separator-height;
    line-height: $date-picker-input-separator-height;
    vertical-align: middle;
}

@mixin range-picker-input-corner($corner) {
    #{$range-picker-prefix} {
        &-trigger, &-panel-input {
            border-radius: $corner;
        }
    }
}
