//
// date-picker 样式
//
#{$date-picker-prefix},
#{$month-picker-prefix},
#{$year-picker-prefix} {
  display: inline-block;
  width: 220px;

  &-input {
    width: 100%;
  }

  &-body {
    width: 280px;
  }

  &-panel-input#{$date-picker-input-prefix} {
    width: 100%;
    background: transparent;
  }
}

#{$date-picker-prefix}-body#{$date-picker-prefix}-body-show-time {
  #{$date-picker-prefix}-panel-input#{$date-picker-input-prefix} {
    width: 49%;

    &:first-child {
      margin-right: 2%;
    }
  }
}
