@mixin img-size(
  $size: $S36
) {
  width: $size;
  height: $size;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
