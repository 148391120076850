@include keyframes(zoomIn) {
    0% {
        opacity: 0;
        @include transform(scale3d(.3, .3, .3));
    }
    50% {
        opacity: 1;
    }
}

@mixin zoomIn($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $ease-out-quint, $fill: $fillDefault, $visibility: $visibilityDefault) {
    @include animation-name(zoomIn);
    @include count($count);
    @include duration($duration);
    @include delay($delay);
    @include function($function);
    @include fill-mode($fill);
    @include visibility($visibility);
}
