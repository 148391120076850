////
/// @group popup
/// @module popup: 弹层通用
/// @type String
/// @tag popup
/// @category utility
/// @family popup
/// @varPrefix $popup-
/// @classPrefix {prefix}-popup
////


/// border width
/// @semantic 边框宽度
$popup-local-border-width: 1px !default;

/// border style
/// @semantic 边框样式
$popup-local-border-style: solid !default;

/// border color
/// @semantic 边框颜色
$popup-local-border-color: $gray-03 !default;

/// corner
/// @semantic 圆角
$popup-local-corner: $shape-radius !default;

/// shadow
/// @semantic 阴影
$popup-local-shadow: none !default;

/// border width
/// @semantic 边框宽度
$popup-global-border-width: 0 !default;

/// border style
/// @semantic 边框样式
$popup-global-border-style: solid !default;

/// border color
/// @semantic 边框颜色
$popup-global-border-color: $gray-03 !default;

/// corner
/// @semantic 圆角
$popup-global-corner: $shape-radius !default;

/// shadow
/// @semantic 阴影
$popup-global-shadow: $shadow-02 !default;
