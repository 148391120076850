@import "../core/index-noreset.scss";
@import "scss/variable";

#{$tab-prefix}[dir='rtl'] {
  &.#{$css-prefix}medium {
    & #{$tab-prefix}-nav-container-scrolling {
      padding-left: $tab-nav-scroll-padding-right-m;
      padding-right: 0;
    }
    .#{$css-prefix}tabs-tab-close {
      padding-right: $tab-nav-close-icon-padding-l-size-m;
      padding-left: 0;
    }
  }
  &.#{$css-prefix}small {
    & #{$tab-prefix}-nav-container-scrolling {
      padding-left: $tab-nav-scroll-padding-right-s;
      padding-right: 0;
    }
    .#{$css-prefix}tabs-tab-close {
      padding-right: $tab-nav-close-icon-padding-l-size-s;
      padding-left: 0;
    }
  }
  &#{$tab-prefix}-wrapped#{$tab-prefix}-top > #{$tab-prefix}-bar {
    #{$tab-prefix}-nav-extra {
      right: auto;
      left: 0;
    }
  }

  &#{$tab-prefix}-wrapped#{$tab-prefix}-bottom > #{$tab-prefix}-bar {
    #{$tab-prefix}-nav-extra {
      right: auto;
      left: 0;
    }
  }

  & > #{$tab-prefix}-bar {
    #{$tab-prefix}-nav-extra {
      right: auto;
      left: 0;
    }
  }

  &#{$tab-prefix}-capsule > #{$tab-prefix}-bar {
    #{$tab-prefix}-tab {
      border-top: $tab-capsule-tab-border;
      border-bottom: $tab-capsule-tab-border;
      border-right: $tab-capsule-tab-border;
      border-left: 0;
      &:first-child {
        border-left: 0;
        border-radius: 0 $tab-capsule-corner-radius $tab-capsule-corner-radius 0;
      }

      &:last-child {
        border-radius: $tab-capsule-corner-radius 0 0 $tab-capsule-corner-radius;
        border-left: $tab-capsule-tab-border;
      }

      &.active {
        margin-left: -$tab-capsule-tab-border-line-width;
        margin-right: auto;
        border-left: $tab-capsule-tab-border;
        border-color: $tab-capsule-tab-border-line-color-active;
      }
    }
  }

  #{$tab-prefix}-btn-next {
    left: $tab-nav-arrow-right-positon-right;
    right: auto;
  }
  #{$tab-prefix}-btn-prev {
    left: $tab-nav-arrow-left-positon-right;
    right: auto;
  }
  #{$tab-prefix}-btn-down {
    left: $tab-nav-arrow-down-positon-right;
    right: auto;
  }
}
#{$tab-prefix}-text[dir='rtl'] > #{$tab-prefix}-bar {
  #{$tab-prefix}-tab {
    &:not(:last-child):after {
      content: '';
      position: absolute;
      left: 0;
      right: auto;
    }
  }
}

