@import '../core/index-noreset.scss';
@import 'scss/variable';

#{$split-btn-prefix} {
  display: inline-block;
  position: relative;

  &-trigger {
    &.#{$css-prefix}small,
    &.#{$css-prefix}medium,
    &.#{$css-prefix}large {
      padding: 0 $S8;
    }

    .#{$css-prefix}icon {
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.#{$css-prefix}expand .#{$css-prefix}icon {
      transform: rotate(180deg);
    }
  }
}
