////
/// @module transfer: 穿梭框
/// @tag Transfer
/// @category component
/// @family data-entry
/// @varPrefix $transfer-
/// @classPrefix {prefix}-transfer
/// @order {"size/move":10,"size/panel":11,"size/header":12,"size/search":13,"size/footer":14,"statement/normal":10,"statement/normal/move":100,"statement/normal/panel":101,"statement/normal/header":102,"statement/normal/item":103,"statement/normal/footer":104,"statement/hover":11,"statement/hover/item":110}
////

// transfer variables
// --------------------------------------------------

// unconfigurable
$transfer-prefix: '.#{$css-prefix}transfer';

// Size
// ----------------------------------------

/// margin (l, r)
/// @namespace size/move
$transfer-operation-margin-left-right: $S20 !default;

/// spacing
/// @namespace size/move
$transfer-operation-margin-gutter: $S8 !default;

/// border width
/// @namespace size/panel
$transfer-panel-border-width: 1px !default;

/// padding (t, b)
/// @namespace size/header
$transfer-panel-header-padding-top-bottom: $S8 !default;

/// padding (l, r)
/// @namespace size/header
$transfer-panel-header-padding-left-right: $S20 !default;

/// text
/// @namespace size/header
$transfer-panel-header-text-size: $font-size-body !default;

/// margin (t)
/// @namespace size/search
$transfer-panel-search-margin-top: $S8 !default;

/// margin (l, r)
/// @namespace size/search
$transfer-panel-search-margin-left-right: $S4 !default;

/// margin (b)
/// @namespace size/search
$transfer-panel-search-margin-bottom: $S0 !default;

/// padding (t, b)
/// @namespace size/footer
$transfer-panel-footer-padding-top-bottom: $S8 !default;

/// padding (l, r)
/// @namespace size/footer
$transfer-panel-footer-padding-left-right: $S20 !default;

// Statement
// ----------------------------------------

/// icon
/// @namespace statement/normal/move
$transfer-simple-move-icon-color: $gray-04 !default;

/// border color
/// @namespace statement/normal/panel
$transfer-panel-border-color: $gray-03 !default;

/// corner
/// @namespace statement/normal/panel
$transfer-panel-border-corner: $shape-radius !default;

/// background
/// @namespace statement/normal/panel
$transfer-panel-background-color: $snow !default;

/// text
/// @namespace statement/normal/header
$transfer-panel-header-text-color: $gray-08 !default;

/// background
/// @namespace statement/normal/header
$transfer-panel-header-background-color: $gray-01 !default;

/// icon
/// @namespace statement/normal/item
$transfer-panel-search-icon-color: $gray-06 !default;

/// text
/// @namespace statement/normal/footer
$transfer-panel-footer-text-color: $gray-08 !default;

/// background
/// @namespace statement/normal/footer
$transfer-panel-footer-background-color: $snow !default;

/// text
/// @namespace statement/normal/footer
$transfer-simple-panel-footer-text-color: $primary-brand !default;

/// text
/// @namespace statement/hover/item
$transfer-simple-panel-item-hover-text-color: $primary-brand !default;

/// shadow
/// @namespace statement/normal/footer
$transfer-panel-footer-shadow: none !default;
