@import '../core/index-noreset';
@import './scss/variable';
@import './scss/normalize';
@import './scss/mixin';

#{$btn-prefix} {
  min-width: 42px;
  position: relative;
  display: inline-block;
  box-shadow: $btn-shadow;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
  cursor: pointer;
  &-space {
    display: inline-block;
    width: $S4;
  }

  &:after {
    text-align: center;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease;
  }

  &.hover,
  &:hover {
    box-shadow: $btn-shadow-hover;
  }

  /* 尺寸维度 */
  /* ---------------------------------------- */
  &.#{$css-prefix} {
    &xs {
      @include next-button-size(
        $btn-size-xs-padding,
        $btn-size-xs-height,
        $btn-size-xs-font,
        $btn-size-xs-border-width,
        $btn-size-xs-corner,
        $btn-size-xs-icon-margin,
        $btn-size-xs-icon-size,
        $btn-size-xs-icon-split-size
      );
    }
    &small {
      @include next-button-size(
        $btn-size-s-padding,
        $btn-size-s-height,
        $btn-size-s-font,
        $btn-size-s-border-width,
        $btn-size-s-corner,
        $btn-size-s-icon-margin,
        $btn-size-s-icon-size,
        $btn-size-s-icon-split-size
      );
    }
    &medium {
      @include next-button-size(
        $btn-size-m-padding,
        $btn-size-m-height,
        $btn-size-m-font,
        $btn-size-m-border-width,
        $btn-size-m-corner,
        $btn-size-m-icon-margin,
        $btn-size-m-icon-size,
        $btn-size-m-icon-split-size
      );
    }
  }

  /* 普通按钮 */
  &#{$btn-prefix} {
    &-normal {
      border-style: $btn-pure-normal-border-style;

      @include button-color(
        $btn-pure-normal-color,
        $btn-pure-normal-color-hover,
        $btn-pure-normal-color-active,
        $btn-pure-normal-bg,
        $btn-pure-normal-bg-hover,
        $btn-pure-normal-bg-active,
        $btn-pure-normal-border-color,
        $btn-pure-normal-border-color-hover,
        $btn-pure-normal-border-color-active
      );
      &.disabled,
      &[disabled] {
        cursor: not-allowed;

        @include button-color(
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled,
          transparent,
          transparent,
          transparent,
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled
        );
      }
    }

    &-primary {
      border-style: $btn-pure-primary-border-style;

      @include button-color(
        $btn-pure-primary-color,
        $btn-pure-primary-color-hover,
        $btn-pure-primary-color-active,
        $btn-pure-primary-bg,
        $btn-pure-primary-bg-hover,
        $btn-pure-primary-bg-active,
        $btn-pure-primary-border-color,
        $btn-pure-primary-border-color-hover,
        $btn-pure-primary-border-color-active
      );
      &.disabled,
      &[disabled] {
        cursor: not-allowed;

        @include button-color(
          $btn-pure-color-disabled,
          $btn-pure-color-disabled,
          $btn-pure-color-disabled,
          $btn-pure-bg-disabled,
          $btn-pure-bg-disabled,
          $btn-pure-bg-disabled,
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled,
          $btn-pure-border-color-disabled
        );
      }
    }

    &-secondary {
      border-style: $btn-pure-secondary-border-style;

      @include button-color(
        $btn-pure-secondary-color,
        $btn-pure-secondary-color-hover,
        $btn-pure-secondary-color-active,
        $btn-pure-secondary-bg,
        $btn-pure-secondary-bg-hover,
        $btn-pure-secondary-bg-active,
        $btn-pure-secondary-border-color,
        $btn-pure-secondary-border-color-hover,
        $btn-pure-secondary-border-color-active
      );
      &.disabled,
      &[disabled] {
        cursor: not-allowed;

        @include button-color($btn-pure-border-color-disabled, $btn-pure-border-color-disabled);
      }
    }
  }

  /* 警告按钮 */
  &-warning {
    border-style: $btn-warning-border-style;

    &#{$btn-prefix}-primary {
      @include button-color(
        $btn-warning-primary-color,
        $btn-warning-primary-color-hover,
        $btn-warning-primary-color-active,
        $btn-warning-primary-bg,
        $btn-warning-primary-bg-hover,
        $btn-warning-primary-bg-active,
        $btn-warning-primary-border-color,
        $btn-warning-primary-border-color-hover,
        $btn-warning-primary-border-color-active
      );
      &.disabled,
      &[disabled] {
        @include button-color(
          $btn-warning-primary-color-disabled,
          $btn-warning-primary-color-disabled,
          $btn-warning-primary-color-disabled,
          $btn-warning-primary-bg-disabled,
          $btn-warning-primary-bg-disabled,
          $btn-warning-primary-bg-disabled,
          $btn-warning-primary-border-color-disabled,
          $btn-warning-primary-border-color-disabled,
          $btn-warning-primary-border-color-disabled
        );
      }
    }

    &#{$btn-prefix}-normal {
      @include button-color(
        $btn-warning-normal-color,
        $btn-warning-normal-color-hover,
        $btn-warning-normal-color-active,
        $btn-warning-normal-bg,
        $btn-warning-normal-bg-hover,
        $btn-warning-normal-bg-active,
        $btn-warning-normal-border-color,
        $btn-warning-normal-border-color-hover,
        $btn-warning-normal-border-color-active,
      );

      &.disabled,
      &[disabled] {
        @include button-color(
          $btn-warning-normal-color-disabled,
          $btn-warning-normal-color-disabled,
          $btn-warning-normal-color-disabled,
          $btn-warning-normal-bg-disabled,
          $btn-warning-normal-bg-disabled,
          $btn-warning-normal-bg-disabled,
          $btn-warning-normal-border-color-disabled,
          $btn-warning-normal-border-color-disabled,
          $btn-warning-normal-border-color-disabled
        );
      }
    }
  }

  /* 文本按钮 */
  &-text {
    box-shadow: none;
    border-radius: 0;

    &.hover,
    &:hover {
      box-shadow: none;
    }

    &#{$btn-prefix}-primary {
      @include button-color(
        $btn-text-primary-color,
        $btn-text-primary-color-hover,
        $btn-text-primary-color-active
      );
      &.disabled,
      &[disabled] {
        @include button-color(
          $btn-text-disabled-color,
          $btn-text-disabled-color
        );
      }
    }
    &#{$btn-prefix}-secondary {
      @include button-color(
        $btn-text-secondary-color,
        $btn-text-secondary-color-hover,
        $btn-text-secondary-color-active,
      );
    }
    &#{$btn-prefix}-normal {
      @include button-color(
        $btn-text-normal-color,
        $btn-text-normal-color-hover,
        $btn-text-normal-color-active,
      );
    }
    &.#{$css-prefix}medium {
      @include next-button-size(
        0,
        $btn-text-size-m-height,
        $btn-text-size-m-font,
        0,
        0,
        $btn-text-icon-m-margin,
        $btn-size-m-icon-size,
        $btn-size-m-icon-split-size
      );
    }
    &.#{$css-prefix}small {
      @include next-button-size(
        0,
        $btn-text-size-s-height,
        $btn-text-size-s-font,
        0,
        0,
        $btn-text-icon-s-margin,
        $btn-size-s-icon-size,
        $btn-size-s-icon-split-size
      );
    }
    &.#{$css-prefix}xs {
      @include next-button-size(
        0,
        $btn-text-size-xs-height,
        $btn-text-size-xs-font,
        0,
        0,
        $btn-text-icon-xs-margin,
        $btn-size-xs-icon-size,
        $btn-size-xs-icon-split-size
      );
    }
    &.disabled,
    &[disabled] {
      @include button-color($btn-text-disabled-color, $btn-text-disabled-color);
    }
    &#{$btn-prefix}-loading {
      @include button-color($btn-text-loading-color, $btn-text-loading-color);
    }
  }

  /* loading */
  /* ---------------------------------------- */
  &-loading {
    pointer-events: none;
    animation: loadingCircle 2s infinite linear;
  }

  /* 幽灵按钮 */
  &-ghost {
    box-shadow: none;
    border-style: $btn-ghost-border-style;
    &#{$btn-prefix}-dark {
      @include button-color(
        $btn-ghost-dark-color,
        $btn-ghost-dark-color-hover,
        $btn-ghost-dark-color-active,
        $btn-ghost-dark-bg-normal,
        $btn-ghost-dark-bg-hover,
        $btn-ghost-dark-bg-active,
        $btn-ghost-dark-border-color,
        $btn-ghost-dark-border-color-hover,
        $btn-ghost-dark-border-color-active
      );
      &.disabled,
      &[disabled] {
        @include button-color(
          $btn-ghost-dark-color-disabled,
          $btn-ghost-dark-color-disabled,
          $btn-ghost-dark-color-disabled,
          $btn-ghost-dark-bg-disabled,
          $btn-ghost-dark-bg-disabled,
          $btn-ghost-dark-bg-disabled,
          $btn-ghost-dark-border-color-disabled,
          $btn-ghost-dark-border-color-disabled,
          $btn-ghost-dark-border-color-disabled
        );
      }
    }
    &#{$btn-prefix}-light {
      @include button-color(
        $btn-ghost-light-color,
        $btn-ghost-light-color-hover,
        $btn-ghost-light-color-active,
        $btn-ghost-light-bg-normal,
        $btn-ghost-light-bg-hover,
        $btn-ghost-light-bg-active,
        $btn-ghost-light-border-color,
        $btn-ghost-light-border-color-hover,
        $btn-ghost-light-border-color-active
      );
      &.disabled,
      &[disabled] {
        @include button-color(
          $btn-ghost-light-color-disabled,
          $btn-ghost-light-color-disabled,
          $btn-ghost-light-color-disabled,
          $btn-ghost-light-bg-disabled,
          $btn-ghost-light-bg-disabled,
          $btn-ghost-light-bg-disabled,
          $btn-ghost-light-border-color-disabled,
          $btn-ghost-light-border-color-disabled,
          $btn-ghost-light-border-color-disabled,
        );
      }
    }
  }

  /* 组合 */
  /* ---------------------------------------- */
  &-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    > #{$btn-prefix} {
      position: relative;
      float: left;
      box-shadow: none;

      &:hover,
      &:focus,
      &:active,
      &.active {
        z-index: 1;
      }
      &.disabled,
      &[disabled] {
        z-index: 0;
      }
    }

    #{$btn-prefix}#{$btn-prefix} {
      margin: 0 0 0 -1px;
    }

    #{$btn-prefix}:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    > #{$btn-prefix}:first-child {
      margin: 0;
    }

    > #{$btn-prefix}:first-child:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    > #{$btn-prefix}:last-child:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    > #{$btn-prefix}-primary:not(:first-child) {
      border-left-color: transparentize($snow, 0.8);

      &:hover {
        border-left-color: transparent;
      }
      &.disabled,
      &[disabled] {
        border-left-color: $gray-04;
      }
    }
  }
}

/* 仅图标的按钮 */
/* ---------------------------------------- */
#{$iconbtn-prefix} {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-shadow: none;
  text-decoration: none;
  user-select: none;
  outline: none;
  border: none;
  padding: 0;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
  cursor: pointer;
  &-primary,
  &-secondary {
    border-radius: $shape-radius;
    padding: 2px;
  }

  &-primary:not([disabled]) {
    /* 颜色维度 */
    /* ---------------------------------------- */
    &#{$iconbtn-prefix}-default {
      @include next-iconbutton-color(
        $iconbtn-default-color,
        $iconbtn-default-color-hover,
        $iconbtn-default-color-active,
        $iconbtn-default-bg,
        $iconbtn-default-bg-hover,
        $iconbtn-default-bg-active
      );
    }
    &#{$iconbtn-prefix}-gray {
      @include next-iconbutton-color(
        $iconbtn-gray-color,
        $iconbtn-gray-color-hover,
        $iconbtn-gray-color-active,
        $iconbtn-gray-bg,
        $iconbtn-gray-bg-hover,
        $iconbtn-gray-bg-active
      );
    }
  }
  &-secondary:not([disabled]) {
    /* 颜色维度 */
    /* ---------------------------------------- */
    &#{$iconbtn-prefix}-default {
      @include next-iconbutton-color(
        $iconbtn-default-color,
        $iconbtn-default-color-hover,
        $iconbtn-default-color-active
      );
    }
    &#{$iconbtn-prefix}-gray {
      @include next-iconbutton-color(
        $iconbtn-gray-color,
        $iconbtn-gray-color-hover,
        $iconbtn-gray-color-active
      );
    }
  }

  /* 普通态禁用样式 */
  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    @include next-iconbutton-color(
      $iconbtn-disabled-color,
      $iconbtn-disabled-color,
      $iconbtn-disabled-color
    );
  }
}

@import './rtl.scss';
