@import '../core/index-noreset.scss';

.#{$css-prefix}overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .#{$css-prefix}overlay-inner {
    z-index: 1001;
    border-radius: $shape-radius;
    box-shadow: $shadow-04;
    transform: translateZ(0);
  }

  .#{$css-prefix}overlay-backdrop {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $mask-background;
    transition: opacity 0.3s;
    opacity: 0;
  }

  &.opened .#{$css-prefix}overlay-backdrop {
    opacity: $mask-opacity;
  }
}
