////
/// @module radio: 单选框
/// @tag radio
/// @category component
/// @family data-entry
/// @varPrefix $radio-
/// @classPrefix {prefix}-radio
/// @order {"size/bounding":10,"size/icon":11,"size/label":12,"statement/normal":10,"statement/normal/bounding":100,"statement/normal/label":101,"statement/hover":11,"statement/hover/bounding":110,"statement/hover/label":111,"statement/checked":12,"statement/checked/bounding":120,"statement/checked/icon":121,"statement/checked/label":122,"statement/disabled":13,"statement/disabled/bounding":130,"statement/disabled/icon":131,"statement/disabled/label":132,"statement/checked hover":14,"statement/checked hover/bounding":140,"statement/checked hover/icon":141,"statement/checked disabled":15,"statement/checked disabled/bounding":150,"statement/checked disabled/label":151}
////

@charset "UTF-8";

// radio variables
// --------------------------------------------------

$radio-prefix : '.#{$css-prefix}radio';

// Radio:Size
// --------------------------------------------------

/// width
/// @namespace size/bounding
$radio-width: $S16 !default;
/// border
/// @namespace size/bounding
$radio-circle-border-width: 1px !default;
/// text
/// @namespace size/icon
$radio-circle-size: $S8 !default;
/// margin (l)
/// @namespace size/label
$radio-font-margin-left: $S8 !default;
/// text
/// @namespace size/label
$radio-font-size: $font-size-body !default;

// Radio:Statement
// --------------------------------------------------
/// corner
/// @namespace statement/normal/bounding
$radio-radius-size: $shape-circular !default;

/// background
/// @namespace statement/normal/bounding
$radio-bg-color: $snow !default;
/// background
/// @namespace statement/hover/bounding
$radio-hovered-bg-color: $highlight-brand !default;
/// background
/// @namespace statement/checked/bounding
$radio-checked-bg-color: $primary-brand !default;
/// background
/// @namespace statement/disabled/bounding
$radio-disabled-bg-color: $gray-01 !default;
/// background
/// @namespace statement/checked hover/bounding
$radio-checked-hovered-bg-color: $hover-brand !default;

/// border
/// @namespace statement/normal/bounding
$radio-border-color: $gray-04 !default;
/// border
/// @namespace statement/hover/bounding
$radio-hovered-border-color: $primary-brand !default;
/// border
/// @namespace statement/hover/bounding
$radio-focused-border-color: $click-brand !default;
/// border
/// @namespace statement/checked/bounding
$radio-checked-border-color: $primary-brand !default;
/// border
/// @namespace statement/disabled/bounding
$radio-disabled-border-color: $gray-04 !default;
/// border
/// @namespace statement/checked hover/bounding
$radio-checked-hovered-border-color: transparent !default;

/// text
/// @namespace statement/checked/icon
$radio-checked-circle-color: $snow !default;
/// text
/// @namespace statement/disabled/icon
$radio-disabled-circle-color: $gray-04 !default;
/// text
/// @namespace statement/checked hover/icon
$radio-checked-hovered-circle-color: $snow !default;
/// border
/// @namespace statement/checked disabled/bounding
$radio-checked-disabled-border-color: $gray-04 !default;

/// text
/// @namespace statement/normal/label
$radio-normal-font-color: $gray-08 !default;
/// text
/// @namespace statement/disabled/label
$radio-normal-font-color-disabled: $gray-05 !default;

// RadioButton:Size
// --------------------------------------------------

/// height
/// @namespace size/bounding
$radio-button-height-large: $S40 !default;
/// height
/// @namespace size/bounding
$radio-button-height-medium: $S28 !default;
/// height
/// @namespace size/bounding
$radio-button-height-small: $S20 !default;

/// padding (l, r)
/// @namespace size/bounding
$radio-button-padding-large: $S8 !default;
/// padding (l, r)
/// @namespace size/bounding
$radio-button-padding-medium: $S8 !default;
/// padding (l, r)
/// @namespace size/bounding
$radio-button-padding-small: $S8 !default;

/// corner
/// @namespace size/bounding
$radio-button-corner-large: $shape-radius !default;
/// corner
/// @namespace size/bounding
$radio-button-corner-medium: $shape-radius !default;
/// corner
/// @namespace size/bounding
$radio-button-corner-small: $shape-radius !default;

/// text
/// @namespace size/bounding
$radio-button-font-size-large: $font-size-subhead !default;
/// text
/// @namespace size/bounding
$radio-button-font-size-medium: $font-size-body !default;
/// text
/// @namespace size/bounding
$radio-button-font-size-small: $font-size-caption !default;

// RadioButton:Statement
// --------------------------------------------------

/// background
/// @namespace statement/normal/bounding
$radio-button-bg-color: $snow !default;
/// background
/// @namespace statement/hover/bounding
$radio-button-bg-color-hovered: $gray-03 !default;
/// background
/// @namespace statement/checked/bounding
$radio-button-bg-color-checked: $snow !default;
/// background
/// @namespace statement/disabled/bounding
$radio-button-bg-color-disabled: $gray-01 !default;
/// background
/// @namespace statement/checked disabled/bounding
$radio-button-bg-color-checked-disabled: $gray-03 !default;

/// border
/// @namespace statement/normal/bounding
$radio-button-border-color: $gray-04 !default;
/// border
/// @namespace statement/hover/bounding
$radio-button-border-color-hovered: $gray-07 !default;
/// border
/// @namespace statement/checked/bounding
$radio-button-border-color-checked: $primary-brand !default;
/// border
/// @namespace statement/disabled/bounding
$radio-button-border-color-disabled: $gray-04 !default;
/// border
/// @namespace statement/checked disabled/bounding
$radio-button-border-color-checked-disabled: $gray-04 !default;

/// text
/// @namespace statement/normal/label
$radio-button-font-color: $gray-08 !default;
/// text
/// @namespace statement/hover/label
$radio-button-font-color-hovered: $gray-08 !default;
/// text
/// @namespace statement/checked/label
$radio-button-font-color-checked: $primary-brand !default;
/// text
/// @namespace statement/disabled/label
$radio-button-font-color-disabled: $gray-05 !default;
/// text
/// @namespace statement/checked disabled/label
$radio-button-font-color-checked-disabled: $gray-05 !default;
