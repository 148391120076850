@charset "UTF-8";
@import '../core/index-noreset.scss';
@import '../input/scss/mixin.scss';
@import 'scss/mixin';
@import 'scss/variable';
@import './rtl.scss';

#{$search-prefix} {
  @include box-sizing;
  @keyframes moveToLeft {
    to {
      left: $search-nav-search-mask-left;
      transform: translateX(0);
    }
  }

  @keyframes moveToRight {
    from {
      left: $search-nav-search-mask-left;
      transform: translateX(0);
    }
    to {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @keyframes hiddenText {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      max-height: 0;
      visibility: hidden;
      position: absolute;
    }
  }
  &-focus#{$search-prefix}-simple.#{$css-prefix}normal#{$search-prefix} #{$search-prefix}-left{
    border-color: $primary-brand;
  }
  width: 100%;
  display: inline-block;

  .#{$css-prefix}input,
  .#{$css-prefix}select {
    border: none;
  }
  .#{$css-prefix}select {
    .#{$css-prefix}input {
      height: auto;
      .#{$css-prefix}input-text-field {
        height: auto;
      }
    }
  }

  #{$search-prefix}-left {
    border-style: solid;
    transition: border-color 0.3s ease-out;
  }

  #{$search-prefix}-left-addon {
    .#{$css-prefix}input,
    .#{$css-prefix}select-trigger-search {
      min-height: 100%;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .#{$css-prefix}select-values {
      line-height: 1;
    }
  }

  #{$search-prefix}-left-addon + #{$search-prefix}-input .#{$css-prefix}input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  #{$search-prefix}-input {
    width: 100%;
  }

  .#{$css-prefix}input-control .#{$css-prefix}icon {
    @include icon-size($search-remove-icon-font-size);
  }

  #{$search-prefix}-btn {
    box-shadow: none;
  }
  &-normal {
    width: 600px;

    #{$search-prefix}-left {
      // border-right: none; 该属性一旦添加 无法被 rtl.scss覆盖 故在后面用 :not选择器进行

      border-top-left-radius: $search-normal-corner;
      border-bottom-left-radius: $search-normal-corner;
    }

    .#{$css-prefix}input {
      border-radius: $search-normal-corner 0 0 $search-normal-corner;
    }

    .#{$css-prefix}btn {
      border-radius: 0 $search-normal-corner $search-normal-corner 0;
    }

    &.#{$css-prefix}primary {
      @include search-type-color(
        $search-normal-primary-color,
        $search-normal-primary-hover-color,
        $search-normal-primary-color,
        $search-normal-primary-btn-text-color,
        $search-normal-primary-color,
        $search-normal-primary-btn-hover-bg-color,
        $search-normal-primary-btn-hover-text-color,
        $search-normal-primary-split-color
      );

      &.#{$css-prefix}medium {
        @include search-type(
          $search-normal-primary-m-height,
          $search-normal-primary-m-btn-text-size,
          $search-normal-primary-m-icon-size,
          $search-normal-primary-border-width,
          $search-normal-primary-btn-text-padding-left,
          $search-normal-primary-shadow
        );
      }

      .#{$css-prefix}input {
        border-top-left-radius: $search-normal-corner - $search-normal-primary-border-width;
        border-bottom-left-radius: $search-normal-corner - $search-normal-primary-border-width;
      }
    }

    &.#{$css-prefix}secondary {
      @include search-type-color(
        $search-normal-secondary-color,
        $search-normal-secondary-hover-color,
        $search-normal-secondary-btn-bg-color,
        $search-normal-secondary-btn-text-color,
        $search-normal-secondary-btn-bg-color,
        $search-normal-secondary-btn-hover-bg-color,
        $search-normal-secondary-btn-hover-text-color,
        $search-normal-secondary-split-color
      );

      &.#{$css-prefix}medium {
        @include search-type(
          $search-normal-secondary-m-height,
          $search-normal-secondary-m-btn-text-size,
          $search-normal-secondary-m-icon-size,
          $search-normal-secondary-border-width,
          $search-normal-secondary-btn-text-padding-left,
          $search-normal-secondary-shadow
        );
      }

      .#{$css-prefix}input {
        border-top-left-radius: $search-normal-corner - $search-normal-secondary-border-width;
        border-bottom-left-radius: $search-normal-corner - $search-normal-secondary-border-width;
      }
    }

    &.#{$css-prefix}normal {
      @include search-type-color(
        $search-normal-normal-color,
        $search-normal-normal-hover-color,
        $search-normal-normal-btn-bg-color,
        $search-normal-normal-btn-text-color,
        $search-normal-normal-color,
        $search-normal-normal-btn-hover-bg-color,
        $search-normal-normal-btn-hover-text-color,
        $search-normal-normal-split-color
      );

      &.#{$css-prefix}medium {
        @include search-type(
          $search-normal-normal-m-height,
          $search-normal-normal-m-btn-text-size,
          $search-normal-normal-m-icon-size,
          $search-normal-normal-border-width,
          $search-normal-normal-btn-text-padding-left,
          $search-normal-normal-shadow
        );
      }

      .#{$css-prefix}input {
        border-top-left-radius: $search-normal-corner - $search-normal-normal-border-width;
        border-bottom-left-radius: $search-normal-corner - $search-normal-normal-border-width;
      }
    }

    &.#{$css-prefix}dark {
      @include search-type-color(
        $search-normal-dark-color,
        $search-normal-dark-hover-color,
        $search-normal-dark-color,
        $search-normal-dark-btn-text-color,
        $search-normal-dark-color,
        $search-normal-dark-btn-hover-bg-color,
        $search-normal-dark-btn-hover-text-color,
        $search-normal-dark-color
      );

      .#{$css-prefix}select-inner,
      input {
        color: $search-normal-dark-text-color;
      }

      .#{$css-prefix}input {
        background: transparentize(
          $search-normal-dark-bg-color,
          1 - $search-normal-dark-bg-opacity
        );
      }
      .#{$css-prefix}select {
        background: transparentize(
          $search-normal-dark-bg-color,
          1 - $search-normal-dark-bg-opacity
        );
      }

      &.#{$css-prefix}medium {
        @include search-type(
          $search-normal-dark-m-height,
          $search-normal-dark-m-btn-text-size,
          $search-normal-dark-m-icon-size,
          $search-normal-dark-border-width,
          $search-normal-dark-btn-text-padding-left,
          $search-normal-dark-shadow
        );
      }
    }
  }
  &-normal:not([dir='rtl']) {
    #{$search-prefix}-left {
      border-right: none;
    }
  }
  &-simple {
    width: 280px;

    box-shadow: $search-simple-normal-shadow;
    #{$search-prefix}-icon {
      margin-left: $search-simple-m-icon-margin-left;
      cursor: pointer;
      transition: color 0.3s ease-out;
    }

    #{$search-prefix}-left,
    .#{$css-prefix}input {
      border-radius: $search-simple-corner;

      input {
        font-size: $search-nav-search-font-size;
      }
    }
    border-radius: $search-simple-corner;

    &.#{$css-prefix}medium {
      @include search-type(
        $search-simple-m-height,
        $search-normal-normal-m-btn-text-size,
        $search-normal-normal-m-icon-size,
        $search-normal-normal-border-width,
        $search-simple-btn-text-padding-left,
        $search-normal-normal-shadow
      );
    }

    &#{$search-prefix}-disabled.#{$css-prefix}normal {
      @include search-simple-color(
        $search-simple-normal-disabled-color,
        $search-simple-normal-disabled-color,
        $search-simple-normal-disabled-color,
        $search-simple-normal-icon-text-color,
        $search-simple-normal-icon-text-color,
        $search-simple-normal-split-color
      );

      #{$search-prefix}-icon {
        cursor: not-allowed;
        &:focus {
          outline: none;
        }
      }
    }

    &.#{$css-prefix}normal {
      @include search-simple-color(
        $search-simple-normal-color,
        $search-simple-normal-hover-color,
        $search-simple-active-color,
        $search-simple-normal-icon-text-color,
        $search-simple-normal-icon-hover-text-color,
        $search-simple-normal-split-color
      );

      #{$search-prefix}-left {
        border-width: $search-simple-normal-border-width;
      }

      &.#{$css-prefix}medium {
        @include search-simple-type($search-simple-normal-m-icon-size);

        #{$search-prefix}-icon {
          margin-left: $search-simple-m-icon-margin-left;
          line-height: $search-simple-normal-m-icon-line-height;
        }
      }
    }

    &.#{$css-prefix}dark {
      @include search-simple-color(
        $search-simple-dark-color,
        $search-simple-dark-hover-color,
        $search-simple-active-color,
        $search-simple-dark-icon-text-color,
        $search-simple-dark-icon-hover-text-color,
        $search-simple-dark-split-color
      );

      .#{$css-prefix}select-inner,
      input {
        color: $search-simple-dark-text-color;
      }
      .#{$css-prefix}input {
        background: transparentize(
          $search-simple-dark-bg-color,
          1 - $search-simple-dark-bg-opacity
        );
      }
      .#{$css-prefix}select {
        background: transparentize(
          $search-simple-dark-bg-color,
          1 - $search-simple-dark-bg-opacity
        );
      }

      #{$search-prefix}-left {
        border-width: $search-simple-dark-border-width;
      }

      &.#{$css-prefix}medium {
        @include search-simple-type($search-simple-dark-m-icon-size);
      }
    }
  }
}

.#{$css-prefix}search-simple {

  .#{$css-prefix}select.#{$css-prefix}medium {
    height: 100%;
  }
}

.#{$css-prefix}nav-search {
  position: relative;
  display: inline-flex;

  width: 280px;
  height: $search-nav-search-height;
  border-radius: $search-nav-search-corner;

  overflow: hidden;

  @include search-nav-search-mask-color(
    $search-nav-search-mask-color,
    $search-nav-search-mask-hover-color
  );

  .#{$css-prefix}input {
    width: 100%;
    border-radius: $search-nav-search-corner;

    background-color: $gray-01;

    input {
      height: $search-nav-search-height;
      padding-left: $search-nav-search-input-left;

      font-size: $search-nav-search-font-size;
      background-color: $gray-01;

      &::placeholder {
        color: $gray-05;
        font-size: $search-nav-search-font-size;
      }
    }

    .#{$css-prefix}input-control .#{$css-prefix}icon {
      line-height: 1;
    }
  }

  &#{$search-prefix}-disabled {
    @include search-nav-search-mask-color(
      $search-nav-search-mask-color,
      $search-nav-search-mask-color
    );

    #{$search-prefix}-mask {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  #{$search-prefix}-mask {
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    left: $search-nav-search-mask-left;

    height: 100%;

    cursor: text;
    transition: color 0.3s ease-out;

    .#{$css-prefix}icon {
      @include icon-size($search-simple-normal-m-icon-size);
      line-height: $search-simple-normal-m-icon-line-height;
      margin-right: $search-simple-input-padding-left;
    }

    &-text {
      padding-left: $search-simple-input-padding-left;
      font-size: $search-nav-search-font-size;
    }
    animation: moveToRight 168ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  &#{$search-prefix}-init {
    #{$search-prefix}-mask {
      animation: none;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &#{$search-prefix}-focus {
    #{$search-prefix}-mask {
      animation: moveToLeft 218ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease;

      &-text {
        animation: hiddenText 218ms;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
      }
    }
  }

  &#{$search-prefix}-has-query,
  &#{$search-prefix}-has-query#{$search-prefix}-init {
    #{$search-prefix}-mask {
      left: $search-nav-search-mask-left;
      transform: translateX(0);
      animation: none;

      &-text {
        position: absolute;
        visibility: hidden;
        height: 0;
      }
    }
  }
}
